import React from "react";

interface HeadingButtonProps {
  title: string;
}
const HeadingButton: React.FC<HeadingButtonProps> = (props) => {
  return (
    <button className=" flex justify-center items-center px-5 text-sm font-semibold py-2 bg-[#FFF3E1] text-[#E2992B] rounded-full">
      {props.title}
    </button>
  );
};

export default HeadingButton;
