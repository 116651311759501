import { FC, useContext, useEffect, useRef, useState } from "react";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  EditorState,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EventContext } from "../EditEvent/EditEvent";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { timeZoneOptions } from "../../../helper/TimeZone";
import { setNestedValue } from "../../../helper/ValidateForm";

interface EditEventFormProps {}

const EditForm: FC<EditEventFormProps> = () => {
  const { register, reset } = useForm();
  const { event, setEvent, errors, setErrors } = useContext(EventContext);
  const [eventType, setEventType] = useState(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const handleEditorChange = (state) => {
    console.log(state);
    setEditorState(state);
    setEvent((prevEvent) => ({
      ...prevEvent,
      description: state,
    }));
  };

  const mandatoryFields = [
    "eventName",
    "eventStartDate",
    "eventEndDate",
    "timeZone",
    "eventDetails.eventType",
    "eventDetails.eventLocation",
    "eventDetails.venue",
  ];

  const isEditorStateInitialized = useRef(false);

  useEffect(() => {
    if (event?.description && !isEditorStateInitialized.current) {
      setEditorState(event.description);
      isEditorStateInitialized.current = true;
    }
  }, [event]);

  useEffect(() => {
    reset({
      ...event,
      eventDetails: {
        ...event?.eventDetails,
        eventType: event?.eventDetails?.eventType.toString(),
      },
    });
    setEventType(event?.eventDetails?.eventType);
  }, [event]);

  const handleEventChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    const eventDetails = event.eventDetails || {};

    if (value == 1) {
      const updatedEventDetails = {
        ...eventDetails,
        eventType: Number.parseInt(value),
        eventLocation : null,
        venue : null
      };
      setEvent((prevEvent) => ({
        ...prevEvent,
        eventDetails: updatedEventDetails,
      }));

      setErrors((prevErrors) => {
        const {
          ["eventDetails.eventLocation"]: _,
          ["eventDetails.venue"]: __,
          ...rest
        } = prevErrors;
        return rest;
      });
    }

    if(value==0){
      const updatedEventDetails = {
        ...eventDetails,
        eventType: Number.parseInt(value),
      };
      setEvent((prevEvent) => ({
        ...prevEvent,
        eventDetails: updatedEventDetails,
      }));
      console.log(event);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEvent((prevEvent) => {
      const updatedEvent = setNestedValue(prevEvent, name, value);
      console.log(updatedEvent);
      return updatedEvent;
    });
    if (mandatoryFields.includes(name)) {
      if (value === null || value === undefined || value === "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Field is required",
        }));
      } else {
        setErrors((prevErrors) => {
          const { [name]: _, ...rest } = prevErrors;
          return rest;
        });
      }
    }
  };

  return (
    <div className="row">
      <div className="forms row">
        <form>
          <div className="flex flex-row justify-between">
            <div className="w-1/2 p-2">
              <div className="flex flex-col gap-2 my-2 p-2">
                <label className="text-sm font-semibold">
                  Event Name<span className="text-red-500"> *</span>
                </label>
                <input
                  {...register("eventName", { required: true })}
                  type="text"
                  name="eventName"
                  onChange={handleChange}
                  className="text-gray-700 border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-blue-700"
                  placeholder="eg. ABC Conference 2024"
                />
                {errors.eventName && (
                  <small className="text-red-500">Event Name is required</small>
                )}
              </div>

              <div className="flex flex-row justify-between gap-2 my-2 p-2">
                <div className="w-1/2">
                  <div className="flex flex-col flex-grow">
                    <label className="text-sm font-semibold">
                      Event From<span className="text-red-500"> *</span>
                    </label>
                    <input
                      {...register("eventStartDate", { required: true })}
                      name="eventStartDate"
                      onChange={handleChange}
                      type="datetime-local"
                      className="text-gray-700 border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-blue-700"
                      placeholder=""
                    />
                  </div>
                  {errors.eventStartDate && (
                    <small className="text-red-500">From date required</small>
                  )}
                </div>
                <div className="w-1/2">
                  <div className="flex flex-col flex-grow">
                    <label className="text-sm font-semibold">
                      Event To<span className="text-red-500"> *</span>
                    </label>
                    <input
                      {...register("eventEndDate", { required: true })}
                      type="datetime-local"
                      name="eventEndDate"
                      onChange={handleChange}
                      className="text-gray-700 border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-blue-700"
                      placeholder=""
                    />
                  </div>
                  {errors.eventEndDate && (
                    <small className="text-red-500">To date required</small>
                  )}
                </div>
              </div>

              <div className="flex flex-col gap-2 my-2 p-2">
                <label className="text-sm font-semibold">
                  Event Time Zone<span className="text-red-500"> *</span>
                </label>
                <select
                  {...register("timeZone", { required: true })}
                  name="timeZone"
                  onChange={handleChange}
                  value={event?.timeZone}
                  className="text-gray-700 border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-blue-700"
                >
                  <option disabled>Select</option>
                  {Object.entries(timeZoneOptions).map(([value, label]) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
                {errors.timeZone && (
                  <small className="text-red-500 ml-5 my-2">
                    Event Time Zone is required
                  </small>
                )}
              </div>

              <div className="flex flex-col gap-2 my-2 p-2">
                <label className="text-sm font-semibold">Description</label>
                <div className="flex flex-col gap-2 border border-gray-300">
                  <ReactQuill theme="snow" style={{ height: '300px' }} value={event?.description} onChange={handleEditorChange}/>
                </div>
              </div>
            </div>
            <div className="w-1/2 p-2">
              <div className="flex justify-center flex-col gap-2 my-2 p-2">
                <label className="text-sm font-semibold">
                  Event Type<span className="text-red-500"> *</span>
                </label>
                <div className="flex gap-10 my-2 font-semibold">
                  <div className="flex gap-2">
                    <input
                      type="radio"
                      id="option1"
                      value={0}
                      checked={eventType === 0}
                      {...register("eventDetails.eventType", {
                        required: true,
                      })}
                      onClick={() => setEventType(0)}
                      name="eventDetails.eventType"
                      onChange={handleEventChange}
                    />

                    <label className="text-sm font-semibold" htmlFor="option1">
                      In Person Event
                    </label>
                  </div>
                  <div className="flex gap-2">
                    <input
                      type="radio"
                      id="option2"
                      value={1}
                      checked={eventType === 1}
                      {...register("eventDetails.eventType", {
                        required: true,
                      })}
                      onClick={() => setEventType(1)}
                      name="eventDetails.eventType"
                      onChange={handleEventChange}
                    />
                    <label htmlFor="option2" className="text-sm font-semibold">
                      Online Event
                    </label>
                  </div>
                </div>
                {errors.eventtype && (
                  <small className="text-red-600 ml-3">
                    Please select a Event type
                  </small>
                )}
              </div>

              {event?.eventDetails?.eventType === 0 && (
                <div className="flex flex-col gap-2 my-2 p-2">
                  <label className="text-sm font-semibold">
                    Event Location<span className="text-red-500"> *</span>
                  </label>
                  <input
                    {...register("eventDetails.eventLocation", {
                      required: true,
                    })}
                    type="text"
                    name="eventDetails.eventLocation"
                    onChange={handleChange}
                    value={event?.eventDetails?.eventLocation}
                    className="text-gray-700 border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-blue-700"
                    placeholder="Start typing city name"
                  />
                  {errors["eventDetails.eventLocation"] &&
                    event?.eventDetails?.eventType === 0 && (
                      <small className="text-red-500">
                        Event Location is required
                      </small>
                    )}
                </div>
              )}

              {event?.eventDetails?.eventType === 0 && (
                <div className="flex flex-col gap-2 my-2 p-2">
                  <label className="text-sm font-semibold">
                    Event Venue<span className="text-red-500"> *</span>
                  </label>
                  <input
                    {...register("eventDetails.venue", { required: true })}
                    type="text"
                    name="eventDetails.venue"
                    onChange={handleChange}
                    value={event?.eventDetails?.venue}
                    className="text-gray-700 border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-blue-700"
                    placeholder="Event venue"
                  />
                  {errors["eventDetails.venue"] &&
                    event?.eventDetails?.eventType === 0 && (
                      <small className="text-red-500">
                        Event Venue is required
                      </small>
                    )}
                </div>
              )}

              <div className="flex flex-col gap-2 my-2 p-2">
                <label className="text-sm font-semibold">Event Website</label>
                <input
                  {...register("eventDetails.eventWebsite", { required: true })}
                  type="text"
                  onChange={handleChange}
                  value={event?.eventDetails?.eventWebsite}
                  className="text-gray-700 border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-blue-700"
                  placeholder="http://oratorengage.com"
                />
              </div>

              <div className="flex flex-col gap-2 my-2 p-2">
                <label className="text-sm font-semibold">Twitter</label>
                <input
                  {...register("eventDetails.twitter", { required: true })}
                  type="text"
                  onChange={handleChange}
                  value={event?.eventDetails?.twitter}
                  className="text-gray-700 border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-blue-700"
                  placeholder="twitter url"
                />
              </div>

              <div className="flex flex-col gap-2 my-2 p-2">
                <label className="text-sm font-semibold">LinkedIn</label>
                <input
                  {...register("eventDetails.linkedIn", { required: true })}
                  type="text"
                  onChange={handleChange}
                  value={event?.eventDetails?.linkedIn}
                  className="text-gray-700 border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-blue-700"
                  placeholder="linkedin url"
                />
              </div>

              <div className="flex flex-col gap-2 my-2 p-2">
                <label className="text-sm font-semibold">Facebook</label>
                <input
                  {...register("eventDetails.facebook", { required: true })}
                  type="text"
                  onChange={handleChange}
                  value={event?.eventDetails?.facebook}
                  className="text-gray-700 border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-blue-700"
                  placeholder="facebook url"
                />
              </div>

              <div className="flex flex-col gap-2 my-2 p-2">
                <label className="text-sm font-semibold">Instagram</label>
                <input
                  {...register("eventDetails.instagram", { required: true })}
                  type="text"
                  onChange={handleChange}
                  value={event?.eventDetails?.instagram}
                  className="text-gray-700 border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-blue-700"
                  placeholder="instagram url"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditForm;
