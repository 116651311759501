import React, { FC, useState } from "react";
import "./DashBoardLayout.scss";
import SideBar from "../../components/Admin/SideBar/SideBar";
import Header from "../../components/Admin/Header/Header";
import AuthService from "../../services/authService";
import { useLocation } from "react-router-dom";

interface DashBoardLayoutProps {
  children: React.ReactNode;
}

const DashBoardLayout: FC<DashBoardLayoutProps> = (props) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const isAuthenticated = AuthService.isAuthenticated();
  const location = useLocation();
  const isEventCreation = location.pathname === "/organizer/event-creation";
  const listEvents = location.pathname === "/list-events";
 const editEvent =
   location.pathname.includes("/organizer/edit-event/") &&
   location.pathname.substring("/organizer/edit-event/".length).length > 0;




  return (
    <div className="DashBoardLayout " data-testid="DashBoardLayout">
      {isAuthenticated && (isEventCreation || listEvents || editEvent) ? (
        <div className={`flex bg-cover`}>
          {/* <SideBar onCloseSidebar={toggleSidebar}/> */}
          <div className="flex flex-col w-screen overflow-x-hidden ">
            {/* <Header /> */}
            <main className="">{props.children}</main>
          </div>
        </div>
      ) : (
        <main>{props.children}</main>
      )}
    </div>
  );
};

export default DashBoardLayout;
