import { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { UUIDGenerator } from "../../../helper/Guid";
import { UUID } from "crypto";
import { convertToSessionModel } from "./SubmitSessionService";
import BlobService from "../../../services/blobService";
import SessionsService from "../../../services/sessionService";
import HashLoader from "react-spinners/HashLoader";
import { setNestedValue } from "../../../helper/ValidateForm";
import { error } from "console";

const mandatoryFields = [
  "title",
  "description",
  "category",
  "sessionType",
  "sessionLevel",
  "languages",
  "techStack",
];

const userMandatoryFields = [
  "tagLine",
  "biography",
  "photoPath",
  "companyName",
  "companyLogo",
  "previousTalks",
  "speakersType",
  "tShirtSizes",
];

export const SubmitSession = ({
  event,
  users,
  loading,
  setLoading,
  speakers,
}) => {
  interface SessionData {
    id?: string;
    eventId?: string;
    title?: string;
    description?: string;
    category?: string;
    sessionType?: string;
    trackType?: string;
    sessionLevel?: string;
    language?: string;
    coSpeakers?: string[];
    createdBy?: string;
    createdOn?: string;
  }
  const [session, setSession] = useState<SessionData>({
    coSpeakers: [],
    title: "",
    description: "",
    category: "",
    sessionType: "",
    trackType: "",
    sessionLevel: "",
    language: "",
  });
  const [errors, setErrors] = useState<any>({});
  const [user, setUserProfile] = useState<any>();
  const [speakerData, setSpeakerData] = useState<any>();
  const fileInputRef = useRef(null);
  const companyPhotoRef = useRef(null);
  const [termandCondtion, setTermsandCondition] = useState(false);

  useEffect(() => {
    const convertToSpeakerData = (user, speakers) => {
      var speakerData = {
        tagLine: speakers?.tagLine ?? user?.userProfile?.tagLine,
        biography: speakers?.biography ?? user?.userProfile?.biography,
        photoPath: speakers?.photoPath ?? user?.userProfile?.photoUrl,
        tShirtSizes: speakers?.tShirtSize,
        speakersType: speakers?.speakersType,
        previousTalks: speakers?.previousTalks,
        companyLogo: speakers?.companyLogo,
        companyName: speakers?.companyName,
      };
      setSpeakerData(speakerData);
    };
    setUserProfile(users);
    convertToSpeakerData(users, speakers);
  }, [users, speakers]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (!value || value === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "Field is mandatory!",
      }));
    } else {
      setErrors((prevErrors) => {
        const { [name]: _, ...rest } = prevErrors as any;
        return rest;
      });
    }
    setSession((prevSession) => ({
      ...prevSession,
      [name]: value,
    }));
  };

  const handleCoSpeakerMailChange = (e) => {
    const { name, value } = e.target;
    const index = parseInt(name.split("_")[1], 10);
    setSession((prevSession) => {
      const updatedMails = [...(prevSession.coSpeakers || [])];
      updatedMails[index] = value;

      return {
        ...prevSession,
        coSpeakers: updatedMails,
      };
    });
  };

  // const validateForm = () => {
  //   return new Promise<void>((resolve) => {
  //     mandatoryFields.forEach((key) => {
  //       if (
  //         session[key] === undefined ||
  //         session[key] === null ||
  //         session[key] === ""
  //       ) {
  //         setErrors((prevErrors) => ({
  //           ...prevErrors,
  //           [key]: "Field is mandatory!",
  //         }));
  //       } else {
  //         setErrors((prevErrors) => {
  //           const { [key]: _, ...rest } = prevErrors as any;
  //           return rest;
  //         });
  //       }
  //     });
  //     userMandatoryFields.forEach((key) => {
  //       const value = getNestedValue(speakerData, key);
  //       if (value === undefined || value === null || value === "") {
  //         setErrors((prevErrors) => ({
  //           ...prevErrors,
  //           [key]: "Field is mandatory!",
  //         }));
  //       } else {
  //         setErrors((prevErrors) => {
  //           const { [key]: _, ...rest } = prevErrors as any;
  //           return rest;
  //         });
  //       }
  //     });
  //     resolve();
  //   });
  // };

  // const handleSubmit = async () => {
  //   setLoading(true);
  //   await validateForm();
  //   console.log(errors);
  //   if (Object.keys(errors).length === 0 && termandCondtion) {
  //     var sessionModel = convertToSessionModel(
  //       event,
  //       session,
  //       user,
  //       speakerData
  //     );
  //     var isSaved = await SessionsService.postNewSession(sessionModel);
  //     if (isSaved === true) {
  //       setLoading(false);
  //       toast.success("Submitted successfully", { autoClose: 1000 });
  //       window.location.reload();
  //     } else {
  //       setLoading(false);
  //       toast.error("Something went wrong");
  //     }
  //   } else if (Object.keys(errors).length !== 0) {
  //     setLoading(false);
  //     console.log(errors);
  //     toast.error("Please fill all mandotory fields");
  //   } else if (!termandCondtion) {
  //     setLoading(false);
  //     toast.error("Please accept Terms and Condition", { autoClose: 1000 });
  //   } else {
  //     setLoading(false);
  //     toast.error("Form has Errors. Please check");
  //   }
  // };

  const validateForm = () => {
    let hasErrors = false;

    mandatoryFields.forEach((key) => {
      if (
        session[key] === undefined ||
        session[key] === null ||
        session[key] === ""
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [key]: "Field is mandatory!",
        }));
        hasErrors = true;
      } else {
        setErrors((prevErrors) => {
          const { [key]: _, ...rest } = prevErrors as any;
          return rest;
        });
      }
    });

    userMandatoryFields.forEach((key) => {
      const value = getNestedValue(speakerData, key);
      if (value === undefined || value === null || value === "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [key]: "Field is mandatory!",
        }));
        hasErrors = true;
      } else {
        setErrors((prevErrors) => {
          const { [key]: _, ...rest } = prevErrors as any;
          return rest;
        });
      }
    });

    return hasErrors;
  };

  const handleSubmit = async () => {
    setLoading(true);

    const hasErrors = validateForm();
    if (hasErrors) {
      setLoading(false);
      toast.error("Please fill all mandatory fields");
      return;
    }

    if (!termandCondtion) {
      setLoading(false);
      toast.error("Please accept Terms and Condition", { autoClose: 1000 });
      return;
    }

    const sessionModel = convertToSessionModel(
      event,
      session,
      user,
      speakerData
    );
    const isSaved = await SessionsService.postNewSession(sessionModel);
    if (isSaved) {
      setLoading(false);
      toast.success("Submitted successfully", { autoClose: 1000 });
      window.location.reload();
    } else {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const handleUserProfile = (e) => {
    const { name, value } = e.target;
    if (!value || value === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "Field is mandatory!",
      }));
    } else {
      setErrors((prevErrors) => {
        const { [name]: _, ...rest } = prevErrors as any;
        return rest;
      });
    }
    setUserProfile((prevSession) => {
      const updatedEvent = setNestedValue(prevSession, name, value);
      return updatedEvent;
    });
  };

  const handleSpeakerDataChanges = (e) => {
    const { name, value } = e.target;
    if (!value || value === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "Field is mandatory!",
      }));
    } else {
      setErrors((prevErrors) => {
        const { [name]: _, ...rest } = prevErrors as any;
        return rest;
      });
    }
    console.log("Errors :", errors);
    setSpeakerData((prevSession) => {
      const updatedEvent = setNestedValue(prevSession, name, value);
      return updatedEvent;
    });
  };

  const handleImageChanges = async (e, folder) => {
    if (
      e.target.name != null &&
      e.target.files[0] != null &&
      e.target.files[0] != undefined
    ) {
      var name = e.target.name;
      var result = await BlobService.uploadFile(e.target.files[0], event?.eventCallForSpeakers?.slugURL,folder);
      // setUserProfile((prevEvent) => ({
      //   ...prevEvent,
      //   userProfile: {
      //     ...user?.userProfile,
      //     photoUrl : result
      //   },
      // }));

      setSpeakerData((prevSession) => {
        const updatedEvent = setNestedValue(prevSession, name, result);
        return updatedEvent;
      });
      setErrors((prevErrors) => {
        const { [name]: _, ...rest } = prevErrors as any;
        return rest;
      });
    }
  };

  const getNestedValue = (obj: any, path: string) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  return (
    <div>
      <div className="p-5 text-xl font-medium">Submit your session</div>
      <hr className="h-px mx-5 bg-gray-200 border-0 dark:bg-gray-400"></hr>
      <div className="p-5">
        <div className="flex flex-row justify-center items-center  flex-grow my-5">
          <label className="text-sm font-semibold text-center w-1/3">
            Session Title
            <span className="text-red-500 font-bold">*</span>
          </label>
          <div className="flex flex-col w-full">
            <input
              type="text"
              name="title"
              onChange={handleChange}
              className="border text-base w-full rounded-md p-1 focus:outline-none"
              placeholder="Session Title"
            />
            {errors.title && (
              <div className="text-red-500 text-xs">{errors.title}</div>
            )}
          </div>
        </div>
        <div className="flex flex-row    flex-grow my-6">
          <label className="text-sm font-semibold text-center w-1/3">
            Description
            <span className="text-red-500 font-bold">*</span>
          </label>
          <div className="flex flex-col w-full">
            <textarea
              name="description"
              onChange={handleChange}
              className="border text-base w-full rounded-md p-1 focus:outline-none"
              placeholder="Description"
            />
            {errors.description && (
              <div className="text-red-500 text-xs">{errors.description}</div>
            )}
          </div>
        </div>

        <div className="flex flex-row    flex-grow my-6">
          <label className="text-sm font-semibold text-center w-1/3">
            Session Category<span className="text-red-500"> *</span>
          </label>
          <div className="flex flex-col w-full">
            <select
              name="category"
              onChange={handleChange}
              className="border w-full text-base placeholder:text-sm border-gray-300 rounded-md p-1 focus:outline-none focus:border-gray-400"
            >
              <option selected disabled>
                Select
              </option>
              {JSON.parse(event?.eventDetails?.sessionCategory || "[]").map(
                (level) => (
                  <option key={level} value={level}>
                    {level}
                  </option>
                )
              )}
            </select>
            {errors.category && (
              <div className="text-red-500 text-xs">{errors.category}</div>
            )}
          </div>
        </div>

        <div className="flex flex-row    flex-grow my-6">
          <label className="text-sm font-semibold text-center w-1/3">
            Session type<span className="text-red-500"> *</span>
          </label>
          <div className="flex flex-col w-full">
            <select
              name="sessionType"
              onChange={handleChange}
              className="border w-full text-base placeholder:text-sm border-gray-300 rounded-md p-1 focus:outline-none focus:border-gray-400"
            >
              <option selected disabled>
                Select
              </option>
              {JSON.parse(event?.eventDetails?.sessionType || "[]").map(
                (level) => (
                  <option key={level} value={level}>
                    {level}
                  </option>
                )
              )}
            </select>
            {errors.sessionType && (
              <div className="text-red-500 text-xs">{errors.sessionType}</div>
            )}
          </div>
        </div>

        {/* <div className="flex flex-row    flex-grow my-6">
          <label className="text-sm font-semibold text-center w-1/3">
            Session track<span className="text-red-500"> *</span>
          </label>
          <div className="flex flex-col w-full">
            <select
              name="trackType"
              onChange={handleChange}
              className="border w-full text-base placeholder:text-sm border-gray-300 rounded-md p-1 focus:outline-none focus:border-gray-400"
            >
              <option selected disabled>
                Select
              </option>
              {JSON.parse(event?.eventDetails?.trackType || "[]").map(
                (level) => (
                  <option key={level} value={level}>
                    {level}
                  </option>
                )
              )}
            </select>
            {errors.trackType && (
              <div className="text-red-500 text-xs">{errors.trackType}</div>
            )}
          </div>
        </div> */}

        <div className="flex flex-row    flex-grow my-6">
          <label className="text-sm font-semibold text-center w-1/3">
            Session level<span className="text-red-500"> *</span>
          </label>
          <div className="flex flex-col w-full">
            <select
              name="sessionLevel"
              onChange={handleChange}
              className="border w-full text-base placeholder:text-sm border-gray-300 rounded-md p-1 focus:outline-none focus:border-gray-400"
            >
              <option selected disabled>
                Select
              </option>
              {JSON.parse(event?.eventDetails?.sessionLevel || "[]").map(
                (level) => (
                  <option key={level} value={level}>
                    {level}
                  </option>
                )
              )}
            </select>
            {errors.sessionLevel && (
              <div className="text-red-500 text-xs">{errors.sessionLevel}</div>
            )}
          </div>
        </div>

        <div className="flex flex-row    flex-grow my-6">
          <label className="text-sm font-semibold text-center w-1/3">
            Languages<span className="text-red-500"> *</span>
          </label>
          <div className="flex flex-col w-full">
            <select
              name="languages"
              onChange={handleChange}
              className="border w-full text-base placeholder:text-sm border-gray-300 rounded-md p-1 focus:outline-none focus:border-gray-400"
            >
              <option selected disabled>
                Select
              </option>
              {JSON.parse(event?.eventDetails?.languages || "[]").map(
                (level) => (
                  <option key={level} value={level}>
                    {level}
                  </option>
                )
              )}
            </select>
            {errors.languages && (
              <div className="text-red-500 text-xs">{errors.languages}</div>
            )}
          </div>
        </div>

        <div className="flex flex-row    flex-grow my-6">
          <label className="text-sm font-semibold text-center w-1/3">
            Tech Stack<span className="text-red-500"> *</span>
          </label>
          <div className="flex flex-col w-full">
            <select
              name="techStack"
              onChange={handleChange}
              className="border w-full text-base placeholder:text-sm border-gray-300 rounded-md p-1 focus:outline-none focus:border-gray-400"
            >
              <option selected disabled>
                Select
              </option>
              {JSON.parse(event?.eventDetails?.techStack || "[]").map(
                (level) => (
                  <option key={level} value={level}>
                    {level}
                  </option>
                )
              )}
            </select>
            {errors.techStack && (
              <div className="text-red-500 text-xs">{errors.techStack}</div>
            )}
          </div>
        </div>

        <div className="flex flex-row    flex-grow my-6">
          <label className="text-sm font-semibold text-center w-1/3">
            Additional Notes
          </label>
          <div className="flex flex-col w-full">
            <textarea
              name="additionalNotes"
              onChange={handleChange}
              className="border text-base w-full rounded-md p-1 focus:outline-none"
              placeholder="Additional notes"
            />
          </div>
        </div>

        {/* <div className="flex flex-row    flex-grow my-6">
          <label className="text-sm font-semibold text-center w-1/3">
            Additional notes
          </label>
          <textarea
            className="border text-base w-full rounded-md p-1 focus:outline-none"
            placeholder="Additional notes"
          />
        </div> */}
        <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-400"></hr>

        <div className="flex flex-row flex-grow my-6">
          <label className="text-sm font-semibold text-center w-1/3"></label>
          <div className="w-full flex flex-row justify-between items-center">
            <p className="font-semibold text-xl">Speaker</p>
            {/* <button
              type="button"
              className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-700 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              <svg
                className="w-3 h-3 text-white me-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 16"
              >
                <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
                <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
              </svg>
              Change
            </button> */}
          </div>
        </div>

        <div className="flex flex-row justify-center items-center  flex-grow my-5">
          <label className="text-sm font-semibold text-center w-1/3">
            Speaker name
            <span className="text-red-500 font-bold">*</span>
          </label>
          <div className="flex flex-row w-full justify-between">
            <div className="flex flex-col">
              <input
                type="text"
                name="firstName"
                value={user?.firstName}
                readOnly
                onChange={(e) => handleSpeakerDataChanges(e)}
                className="border text-base rounded-md p-1 focus:outline-none bg-gray-100"
                placeholder="Firstname"
              />
              {errors.firstName && (
                <div className="text-red-500 text-xs">{errors.firstName}</div>
              )}
            </div>
            <div className="flex flex-col">
              <input
                type="text"
                name="lastName"
                value={user?.lastName}
                readOnly
                onChange={(e) => handleSpeakerDataChanges(e)}
                className="border text-base rounded-md p-1 focus:outline-none bg-gray-100"
                placeholder="Lastname"
              />
              {errors.lastName && (
                <div className="text-red-500 text-xs">{errors.lastName}</div>
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-center items-center  flex-grow my-5">
          <label className="text-sm font-semibold text-center w-1/3">
            Email
          </label>
          <input
            value={user?.email}
            name="email"
            type="text"
            readOnly
            className="border text-base w-full rounded-md p-1 focus:outline-none bg-gray-100"
            placeholder="Email"
          />
        </div>

        <div className="flex flex-row    flex-grow my-6">
          <label className="text-sm font-semibold text-center w-1/3">
            Speaker biography
            <span className="text-red-500 font-bold">*</span>
          </label>
          <div className="flex-col w-full">
            <textarea
              name="biography"
              value={speakerData?.biography}
              onChange={(e) => handleSpeakerDataChanges(e)}
              className="border text-base w-full rounded-md p-1 focus:outline-none"
              placeholder="Speaker biography"
            />
            {errors["biography"] && (
              <div className="text-red-500 text-xs">{errors["biography"]}</div>
            )}
          </div>
        </div>

        <div className="flex flex-row justify-center items-center  flex-grow my-5">
          <label className="text-sm font-semibold text-center w-1/3">
            Speaker tagline
            <span className="text-red-500 font-bold">*</span>
          </label>
          <div className="flex flex-col w-full">
            <input
              type="text"
              name="tagLine"
              value={speakerData?.tagLine}
              // value={user?.userProfile?.tagLine}
              onChange={(e) => handleSpeakerDataChanges(e)}
              className="border text-base w-full rounded-md p-1 focus:outline-none"
              placeholder="Speaker tagline"
            />
            {errors["tagLine"] && (
              <div className="text-red-500 text-xs">{errors["tagLine"]}</div>
            )}
          </div>
        </div>

        {/* <div className="flex flex-row justify-center items-center  flex-grow my-5">
          <label className="text-sm font-semibold text-center w-1/3">
            Designation
            <span className="text-red-500 font-bold">*</span>
          </label>
          <div className="flex flex-col w-full">
          <input
            type="text"
            name="speakerData.designation"
            value={speakerData?.designation}
            onChange={handleUserProfile}
            className="border text-base w-full rounded-md p-1 focus:outline-none "
            placeholder="Designation"
          />
          {errors["userProfile.designation"] && (
              <div className="text-red-500 text-xs">{errors["userProfile.designation"]}</div>
            )}
          </div>
        </div> */}

        <div className="flex flex-row    flex-grow my-6">
          <label className="text-sm font-semibold text-center w-1/3">
            Speaker Type<span className="text-red-500"> *</span>
          </label>
          <div className="flex flex-col w-full">
            <select
              name="speakersType"
              value={speakerData?.speakersType}
              onChange={(e) => handleSpeakerDataChanges(e)}
              className="border w-full text-base placeholder:text-sm border-gray-300 rounded-md p-1 focus:outline-none focus:border-gray-400"
            >
              <option selected disabled>
                Select
              </option>
              {JSON.parse(event?.eventDetails?.speakerType || "[]").map(
                (level) => (
                  <option key={level} value={level}>
                    {level}
                  </option>
                )
              )}
            </select>
            {errors.speakersType && (
              <div className="text-red-500 text-xs">{errors.speakersType}</div>
            )}
          </div>
        </div>

        <div className="flex flex-row    flex-grow my-6">
          <label className="text-sm font-semibold text-center w-1/3">
            Speaker photo <span className="text-red-500"> *</span>
          </label>
          <div className="p-2 bg-[#f3f3f4] w-full">
            {speakerData?.photoPath != null && (
              <div className="flex flex-row justify-between items-start">
                <div className="p-2 mb-2">
                  <img
                    src={speakerData?.photoPath}
                    alt="Preview"
                    // onClick={handleImageChanges}
                    style={{ maxWidth: "100%", maxHeight: "200px" }}
                  />
                </div>
                <button
                  type="button"
                  onClick={() => {
                    setSpeakerData((prevSession) => {
                      const updatedEvent = setNestedValue(
                        prevSession,
                        "photoPath",
                        null
                      );
                      return updatedEvent;
                    });
                  }}
                  className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                >
                  <span className="sr-only">Close menu</span>
                  <svg
                    className="h-3 w-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="red"
                    viewBox="0 0 24 24"
                    stroke="black"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            )}
            <div>
              <input
                type="file"
                name="photoPath"
                ref={fileInputRef}
                onChange={(e) => handleImageChanges(e, "SpeakerPhotos")}
                style={{ display: "none" }}
              />
              <div
                className="my-4 mx-2 bg-white p-2 text-center cursor-pointer"
                onClick={() => {
                  fileInputRef.current.click();
                }}
              >
                Choose file or Click here to Upload
              </div>
            </div>
            {errors["photoPath"] && (
              <div className="text-red-500 text-xs">{errors["photoPath"]}</div>
            )}
          </div>
        </div>

        <div className="flex flex-row justify-center items-center  flex-grow my-5">
          <label className="text-sm font-semibold text-center w-1/3">
            Previous Talks <span className="text-red-500"> *</span>
          </label>
          <div className="flex flex-col w-full">
            <input
              type="text"
              name="previousTalks"
              value={speakerData?.previousTalks}
              onChange={(e) => handleSpeakerDataChanges(e)}
              className="border text-base w-full rounded-md p-1 focus:outline-none "
              placeholder="Previous Talks"
            />
            {errors["previousTalks"] && (
              <div className="text-red-500 text-xs">
                {errors["previousTalks"]}
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-row justify-center items-center  flex-grow my-5">
          <label className="text-sm font-semibold text-center w-1/3">
            Company name <span className="text-red-500"> *</span>
          </label>
          <div className="flex flex-col w-full">
            <input
              type="text"
              name="companyName"
              value={speakerData?.companyName}
              onChange={(e) => handleSpeakerDataChanges(e)}
              className="border text-base w-full rounded-md p-1 focus:outline-none "
              placeholder="Company name"
            />
            {errors["companyName"] && (
              <div className="text-red-500 text-xs">
                {errors["companyName"]}
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-row    flex-grow my-6">
          <label className="text-sm font-semibold text-center w-1/3">
            Company logo <span className="text-red-500"> *</span>
          </label>
          <div className="p-2 bg-[#f3f3f4] w-full">
            {speakerData?.companyLogo != null && (
              <div className="flex flex-row justify-between items-start">
                <div className="p-2 mb-2">
                  <img
                    src={speakerData?.companyLogo}
                    alt="Preview"
                    // onClick={handleImageChanges}
                    style={{ maxWidth: "100%", maxHeight: "200px" }}
                  />
                </div>
                <button
                  type="button"
                  onClick={() => {
                    setSpeakerData((prevSession) => {
                      const updatedEvent = setNestedValue(
                        prevSession,
                        "companyLogo",
                        null
                      );
                      return updatedEvent;
                    });
                  }}
                  className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                >
                  <span className="sr-only">Close menu</span>
                  <svg
                    className="h-3 w-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="red"
                    viewBox="0 0 24 24"
                    stroke="black"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            )}
            <div>
              <input
                type="file"
                name="companyLogo"
                ref={companyPhotoRef}
                onChange={(e) => handleImageChanges(e, "CompanyPhotos")}
                style={{ display: "none" }}
              />
              <div
                className="my-4 mx-2 bg-white p-2 text-center cursor-pointer"
                onClick={() => {
                  companyPhotoRef.current.click();
                }}
              >
                Choose file or Click here to Upload
              </div>
            </div>
            {errors["companyLogo"] && (
              <div className="text-red-500 text-xs">
                {errors["companyLogo"]}
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-row    flex-grow my-6">
          <label className="text-sm font-semibold text-center w-1/3">
            Shirt Size<span className="text-red-500"> *</span>
          </label>
          <div className="flex flex-col w-full">
            <select
              name="tShirtSizes"
              value={speakerData?.tShirtSizes}
              onChange={(e) => handleSpeakerDataChanges(e)}
              className="border w-full text-base placeholder:text-sm border-gray-300 rounded-md p-1 focus:outline-none focus:border-gray-400"
            >
              <option selected disabled>
                Select
              </option>
              {JSON.parse(event?.eventDetails?.tShirtSizes || "[]").map(
                (level) => (
                  <option key={level} value={level}>
                    {level}
                  </option>
                )
              )}
            </select>
            {errors.tShirtSizes && (
              <div className="text-red-500 text-xs">{errors.tShirtSizes}</div>
            )}
          </div>
        </div>

        <hr className="h-[0.5px] my-4 bg-gray-200 border-0 dark:bg-gray-400"></hr>

        <div className="flex flex-row justify-center flex-grow my-5">
          <label className="text-sm font-semibold text-center w-1/3">
            Co-speakers
          </label>
          <div className="flex flex-col w-full">
            {Array.from({ length: event?.eventDetails?.coSpeakerCount }).map(
              (email, index) => (
                <input
                  key={index}
                  type="text"
                  name={`cospeakermail_${index}`}
                  onChange={(e) => handleCoSpeakerMailChange(e)}
                  className="border text-base w-full rounded-md p-1 focus:outline-none mb-2"
                  placeholder="someone@somewhere.com"
                />
              )
            )}
          </div>
          {/* <div className="flex flex-col w-full">
            <input
              type="text"
              name="cospeakermail_0"
              onChange={handleCoSpeakerMailChange}
              className="border text-base w-full rounded-md p-1 focus:outline-none"
              placeholder="soomeone@somwhere.com"
            />
            <input
              type="text"
              name="cospeakermail_1"
              onChange={handleCoSpeakerMailChange}
              className="border text-base w-full rounded-md p-1 focus:outline-none"
              placeholder="soomeone@somwhere.com"
            />
            <input
              type="text"
              name="cospeakermail_2"
              onChange={handleCoSpeakerMailChange}
              className="border text-base w-full rounded-md p-1 focus:outline-none"
              placeholder="soomeone@somwhere.com"
            />
          </div> */}
        </div>

        <div className="flex flex-row justify-center  flex-grow my-5">
          <label className="text-sm font-semibold text-center w-1/3"></label>
          <div className="flex w-full">
            <input
              type="checkbox"
              checked={termandCondtion}
              onChange={() => setTermsandCondition(!termandCondtion)}
              className="w-8 h-8 text-blue-600 bg-gray-100 dark:bg-gray-700 "
            />
            <label className="ms-2 text-sm font-medium text-[#5f5353] dark:text-[#5f5353]">
              I agree that personal data shown on this page is shared with the
              organizer of {event?.eventName}. Organizer may share your session
              and personal data publicly as (but not limited to) a part of an
              event schedule. Please read our{" "}
              <a
                href="#"
                className="text-blue-600 dark:text-blue-500 hover:underline"
              >
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="#"
                className="text-blue-600 dark:text-blue-500 hover:underline"
              >
                Privacy Policy
              </a>
              .
            </label>
          </div>
        </div>

        <div className="flex flex-row justify-center flex-grow my-10">
          <label className="text-sm font-semibold text-center w-1/3"></label>
          <button
            type="button"
            onClick={handleSubmit}
            className="px-6 py-3.5 w-full justify-center text-base font-medium text-white inline-flex items-center bg-[#625ffb] hover:bg-[#625ffb] focus:outline-none focus:ring-blue-300 text-center dark:bg-[#625ffb] dark:hover:bg-[#625ffb] "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
            Submit session to event
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
