import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import SessionsService from "../../../services/sessionService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HashLoader } from "react-spinners";
import { AuthContext } from "../../../context/AuthContext";
import { Role } from "../../../enums/Roles";
import SideBar from "../../Admin/SideBar/SideBar";
import DashboardHeader from "../../Admin/DashboardHeader/DashboardHeader";
import WithdrawalSession from "./WithdrawalSession";
const SessionDetailComponent = () => {
  const { auth } = useContext(AuthContext);
  const [showSidebar, setShowSidebar] = useState(true);
  const { sessionId } = useParams();
  const [sessionData, setSessionData] = useState<any>(null);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [isWithdrawlOpen, setIsWithdrawlOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const {
    register: registerUpdate,
    handleSubmit: handleSubmitUpdate,
    formState: { errors: errorsUpdate },
    setValue: setValueUpdate,
  } = useForm();

  useEffect(() => {
    fetchSessionData();
  }, [sessionId]);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const fetchSessionData = async () => {
    setLoading(true);
    try {
      const data = await SessionsService.getSessionById(sessionId);
      setSessionData(data);
      console.log(data);
      setFormValues(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching session data:", error);
    }
  };

  const setFormValues = (data: any) => {
    setValue("status", data.sessionStatus);
    setValueUpdate("title", data.title);
    setValueUpdate("description", data.description);
    setValueUpdate("room", data.room);
    setValueUpdate("startsAt", data.startsAt);
    setValueUpdate("endsAt", data.endsAt);
    setValueUpdate("sessionType", sessionTypeMapping[data.sessionType]);
  };

  const roomOptions: any[] = [
    "Track 1",
    "Track 2",
    "Track 3",
    "Track 4",
    "Track 5",
    "Track 6 (Workshop)",
  ];
  const statusMapping = {
    0: "Nominated",
    1: "Accepted",
    2: "Accept Queue",
    3: "Decline Queue",
    4: "Declined",
  };

  const sessionTypeMapping = {
    1: "Lightning",
    2: "Session",
    3: "Workshop",
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const options: any = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    return date.toLocaleString("en-GB", options);
  };

  return (
    <div className="flex">
      {(auth?.role === Role.Admin || auth?.role === Role.User) && (
        <div
          className={` ${
            showSidebar &&
            (auth?.role === Role.Admin || auth?.role === Role.User)
              ? "md:w-[15%] w-[100%]"
              : "w-[0%]"
          }`}
        >
          {showSidebar &&
            (auth?.role === Role.Admin || auth?.role === Role.User) && (
              <div className="sidebar">
                <SideBar onCloseSidebar={toggleSidebar} />
              </div>
            )}
        </div>
      )}
      {loading && (
        <div className="fixed inset-0 bg-black bg-opacity-80 z-10">
          <div
            role="status"
            className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
          >
            <HashLoader color={"white"} loading={loading} size={50} />
          </div>
        </div>
      )}
      <div
        className={`bg-[#f3f3f4] ${
          showSidebar && (auth?.role === Role.Admin || auth?.role === Role.User)
            ? "w-[0%] md:w-[85%]"
            : "md:w-[100%]"
        }`}
      >
        <div className="w-full">
          <DashboardHeader onCloseSidebar={toggleSidebar} />
        </div>
        <div className="card boxing  rounded-md">
          <div className="md:flex gap-5">
            <div className="session-details md:w-3/5 bg-white ">
              <div className="border-b pb-3">
                <div className="session px-5 pt-3">
                  <div className="font-bold">{sessionData?.title || "N/A"}</div>
                  <div>
                    {/* <button type="button" className="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    Withdraw Session</button> */}

                    {/* <button
                      className="flex justify-center items-center gap-2 bg-[#625ffb] p-2 text-white rounded-lg font-semibold"
                      onClick={()=>{}}
                    >
                      <img className="" src="/session/edit.svg" alt="edit" />
                      Withdraw Session
                    </button> */}
                  </div>
                </div>
              </div>
              <div className="overall-container p-5">
                <div className="container-feild">
                  <h4 className="text-[#797c7d] font-semibold">Description</h4>
                  <p className="title text-[#686b6d]">
                    {sessionData?.description || "N/A"}
                  </p>
                </div>
                <div className="container-feild">
                  <h4 className="text-[#797c7d] font-semibold">
                    Additional notes
                  </h4>
                  <p className="title text-[#686b6d]">
                    {sessionData?.additionalNotes || "N/A"}
                  </p>
                </div>
                <div className="text-xs flex justify-between border-b pb-2">
                  <div className="font-normal">Category</div>
                  <div>
                    <div className=" text-[#5e5e5e] rounded-sm p-1 mr-10  bg-[#d1dade]">
                      {sessionData?.category || "N/A"}
                    </div>
                  </div>
                </div>
                <div className="text-xs flex justify-between border-b py-2">
                  <div className="font-normal">Session Type</div>
                  <div>
                    <div className=" text-[#5e5e5e] rounded-sm  p-1 mr-10  bg-[#d1dade]">
                      {sessionData?.sessionType || "N/A"}
                    </div>
                  </div>
                </div>
                <div className="text-xs flex justify-between border-b py-2">
                  <div className="font-normal">Session Level</div>
                  <div>
                    <div className=" text-[#5e5e5e] rounded-sm  p-1 mr-10  bg-[#d1dade]">
                      {sessionData?.sessionLevel || "N/A"}
                    </div>
                  </div>
                </div>
                <div className="text-xs flex justify-between border-b py-2">
                  <div className="font-normal">Event name</div>
                  <div>
                    <div className=" text-[#5e5e5e] rounded-sm  p-1 mr-10  bg-[#d1dade]">
                      {sessionData?.event?.eventName || "N/A"}
                    </div>
                  </div>
                </div>
                <div className="container-feild">
                  <h4 className="text-[#797c7d] font-semibold">Submitted </h4>
                  <p className="title text-[#686b6d]">
                    {formatDate(sessionData?.createdOn) || "N/A"}
                  </p>
                </div>
                {!sessionData?.isSessionWithdrawn &&
                  !(sessionData?.sessionStatus === 4) && (
                    <button
                      onClick={() => setIsWithdrawlOpen(true)}
                      className="bg-[#fb5f69] rounded-md px-4 py-2 text-white flex justify-center items-start"
                    >
                      <div>
                        <img src="/session/warning.svg" />
                      </div>
                      <div className="mt-[2px]">Cancel engagement</div>
                    </button>
                  )}

                {sessionData?.isSessionWithdrawn && (
                  <div className=" bg-red-100 text-red-500 px-3 py-1 rounded-md">
                    This withdrawal request will be sent to the organizer so
                    they can cancel your participation.
                  </div>
                )}
              </div>
            </div>
            {isWithdrawlOpen && (
              <WithdrawalSession
                open={isWithdrawlOpen}
                sessionId={sessionData?.id}
                onClose={() => setIsWithdrawlOpen(!isWithdrawlOpen)}
                sessionTitle={sessionData?.title}
                fetchSessions={fetchSessionData}
              />
            )}

            <div className="md:w-2/5 bg-white">
              {/* <form className="" onSubmit={handleSubmit(onUpdateStatus)}>
                <div>
                  <div className="">
                    <div className="border-b p-5 font-bold">Session Status</div>
                    <div className="w-[90%] m-auto ">
                      <select
                        className="text-white border-2 my-6 w-full py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm bg-[#625ffb] focus:outline-none m-auto ease-linear transition-all duration-150"
                        {...register("status")}
                        onChange={handleChange}
                      >
                        {Object.entries(statusMapping).map(([key, value]) => (
                          <option className="p-2" key={key} value={key}>
                            {value}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="text-sm mx-4">
                      {` This session has been ${
                        statusMapping[sessionData?.sessionStatus]
                      }`}
                    </div>
                  </div>
                </div>
              </form> */}
              {sessionData?.speakers ? (
                <div className="bg-white w-full p-5 my-5">
                  {sessionData.speakers.map((speaker: any) => (
                    <div
                      key={speaker.id}
                      className="speaker overall-container "
                    >
                      <div className="flex gap-5 justify-start items-start">
                        <div className="flex flex-col justify-center items-center w-1/5 -mt-3">
                          <img
                            src={
                              speaker?.photoPath ||
                              "/session/profile-placeholder-2.png"
                            }
                            className="rounded-circle"
                            alt="Profile Avatar"
                          />
                          <a
                            href={speaker?.photoPath}
                            download="user_profolio.png"
                          >
                            <button className="border-2 my-3 border-[#625ffb] text-[#625ffb] rounded text-xs p-1">
                              Download
                            </button>
                          </a>
                        </div>
                        <div className="w-4/5">
                          <div className="font-bold text-[1.3em] text-[#625ffb]">
                            {speaker?.users?.firstName +
                              " " +
                              speaker?.users?.lastName || "N/A"}
                          </div>
                          <div className="font-bold text-base my-2">
                            {speaker?.tagLine || "N/A"}
                          </div>
                          <div className="text-sm my-3">
                            {speaker?.biography || "N/A"}
                          </div>
                          <div className="text-xs flex justify-between border-b py-2 mt-4">
                            <div className="font-bold">Speaker Type</div>
                            <div>
                              <div className=" text-[#5e5e5e] rounded-sm  p-1 mr-10  bg-[#d1dade]">
                                {speaker?.speakersType || "N/A"}
                              </div>
                            </div>
                          </div>
                          <div className="text-xs flex justify-between border-b py-2">
                            <div className="font-bold">
                              Speaker's Company Logo
                            </div>
                            <div>
                              <div className="text-[#5e5e5e] rounded-sm  p-1 mr-10  bg-[#d1dade]">
                                <a
                                  href={speaker?.companyLogo}
                                  download="organization_logo.png"
                                >
                                  <button className="btn btn-primary">
                                    Download
                                  </button>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="text-xs flex justify-between border-b py-2">
                            <div className="font-bold">Company Name</div>
                            <div>
                              <div className=" text-[#5e5e5e] rounded-md p-1 mr-10  ">
                                {speaker?.companyName || "N/A"}
                              </div>
                            </div>
                          </div>
                          <div className="text-xs flex justify-between border-b py-2">
                            <div className="font-bold">Email</div>
                            <div>
                              <div className=" text-[#5e5e5e] rounded-md p-1 mr-10  ">
                                {speaker?.users?.email || "N/A"}
                              </div>
                            </div>
                          </div>

                          <div className="card-profile-stats flex gap-3 justify-content-center">
                            <div>
                              <span className="description cursor-pointer">
                                <a
                                  target="_blank"
                                  href={
                                    speaker?.users?.userSocialMedia?.linkedIn
                                  }
                                >
                                  <img src="/session/linkedIn.svg" />
                                </a>
                              </span>
                            </div>
                            <div>
                              <span className="description cursor-pointer">
                                <a
                                  target="_blank"
                                  href={
                                    speaker?.users?.userSocialMedia?.instagram
                                  }
                                >
                                  <img src="/session/instagram.svg" />
                                </a>
                              </span>
                            </div>
                            <div>
                              <span className="description cursor-pointer">
                                <a
                                  target="_blank"
                                  href={
                                    speaker?.users?.userSocialMedia?.twitter
                                  }
                                >
                                  <img src="/session/twitter.svg" />
                                </a>
                              </span>
                            </div>
                            <div>
                              <span className="description cursor-pointer">
                                <a
                                  target="_blank"
                                  href={
                                    speaker?.users?.userSocialMedia?.facebook
                                  }
                                >
                                  <img src="/session/facebook.svg" />
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div>No Speakers Added</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionDetailComponent;
