import { useContext, useEffect, useRef, useState } from "react";
import { HashLoader } from "react-spinners";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import SpeakerInviteService from "../../../services/speakerInviteService";
import { convertToAcceptSpeakerInviteModel, convertToSpeakerData, formatEventDates } from "./SpeakerInviteService";
import UserService from "../../../services/userService";
import { AuthContext } from "../../../context/AuthContext";
import { setNestedValue } from "../../../helper/ValidateForm";
import BlobService from "../../../services/blobService";

export const SpeakerInvite = () => {
  const { inviteLink } = useParams<{ inviteLink: string }>();
  const [loading, setLoading] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const [termandCondtion, setTermsandCondition] = useState(false);
  const [event, setEvent] = useState<any>();
  const [speakerData, setSpeakerData] = useState(null);
  const [user, setUser] = useState<any>();
  const [session, setSession] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  const { auth } = useContext(AuthContext);
  const [isInviteLinkExpired, setIsInviteLinkExpired]= useState(false);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const companyPhotoRef = useRef(null);
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  useEffect(() => {
    setLoading(true);
    const fetchSpeakerInvite = async () => {
      try {
        const responseData = await SpeakerInviteService.getSpeakerInvite(
          inviteLink,
          auth?.userId
        );
        // const event = (await EventService.getEventbyId(session?.eventId)).data;
        const userData = await UserService.getUserbyUserId(auth?.userId);
        console.log(responseData);
        if (responseData === null || responseData === undefined || responseData=='') {
          setLoading(false);
          setIsInviteLinkExpired(true);
          // navigate("/not-found");
        } else {
          setSession(responseData?.session);
          setEvent(responseData?.session?.event);
          setSpeakerData(convertToSpeakerData(userData, responseData?.speaker));
          setUser(userData);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        toast.error("Error fetching events");
        navigate("/not-found");
      }
      setLoading(false);
    };
    fetchSpeakerInvite();
  }, []);

  console.log(event);

  const eventDates = formatEventDates(
    event?.eventStartDate,
    event?.eventEndDate
  );

  const handleSpeakerDataChanges = (e) => {
    const { name, value } = e.target;
    console.log(name,value);
    if (!value || value === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "Field is mandatory!",
      }));
    } else {
      setErrors((prevErrors) => {
        const { [name]: _, ...rest } = prevErrors as any;
        return rest;
      });
    }
    setSpeakerData((prevSession) => {
      const updatedEvent = setNestedValue(prevSession, name, value);
      return updatedEvent;
    });
  };

  const handleImageChanges = async (e, folder) => {
    var name = e.target.name;
    var result = await BlobService.uploadFile(e.target.files[0], event?.eventCallForSpeakers?.slugURL,folder);
    setSpeakerData((prevSession) => {
      const updatedEvent = setNestedValue(prevSession, name, result);
      return updatedEvent;
    });
    setErrors((prevErrors) => {
      const { [name]: _, ...rest } = prevErrors as any;
      return rest;
    });
  };

  const userMandatoryFields = [
    "tagLine",
  "biography",
  "photoPath",
  "companyName",
  "companyLogo",
  "previousTalks",
  "speakersType",
  "tShirtSize"
  ];

  const getNestedValue = (obj: any, path: string) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  const validateForm = () => {
    userMandatoryFields.forEach((key) => {
      const value = getNestedValue(speakerData, key);
      console.log(key, value);
      if (value === undefined || value === null || value === "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [key]: "Field is mandatory!",
        }));
      } else {
        setErrors((prevErrors) => {
          const { [key]: _, ...rest } = prevErrors as any;
          return rest;
        });
      }
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    validateForm();
    if (Object.keys(errors).length === 0 && termandCondtion) {
        var acceptInviteModel = convertToAcceptSpeakerInviteModel(event, session, user, speakerData, inviteLink);
        var isSaved = await SpeakerInviteService.postSpeakerInvite(acceptInviteModel);
        if (isSaved === true) {
          setLoading(false);
          toast.success("Submitted successfully", { autoClose: 1000 });
          navigate('/speaker/user-profile');
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
    } else if (Object.keys(errors).length !== 0) {
      setLoading(false);
      toast.error("Please fill all mandotory fields");
    } else if (!termandCondtion) {
      setLoading(false);
      toast.error("Please accept Terms and Condition", { autoClose: 1000 });
    } else {
      setLoading(false);
      toast.error("Form has Errors. Please check");
    }
  };

  return (
    <div className="flex">
      <div className="column w-full">
        <div className={`row`}>
          <div className="flex flex-row justify-between my-5 p-4 bg-white w-full">
            <div className="p-2 text-2xl font">
              {(user?.firstName ?? "") + " " + (user?.lastName ?? "")}
            </div>
          </div>
          <div className="flex flex-row w-full">
            <div className="h-full mx-3 my-2 p-5 bg-white w-1/2">
              <div className="flex flex-col">
                <p className="text-xl">{event?.eventName}</p>
                <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-400"></hr>
                <div className="flex flex-col mb-6">
                  <p className="text-sm">Event Dates</p>
                  <p className="text-base">{eventDates}</p>
                </div>
                <p className="text-sm">
                  This event is in <strong>{event?.timeZone}</strong> time zone
                </p>
                <hr className="h-px my-4 bg-gray-200  dark:bg-gray-400 border:none"></hr>
                <div className="flex flex-col">
                  <p className="text-2xl">{session?.title}</p>
                  <p className="text-base mt-2">{session?.description}</p>
                </div>
                <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-400 border:none"></hr>
                <div className="flex flex-row">
                  <div className="flex items-center p-2">
                    <img
                      className="rounded-full w-10 h-10"
                      src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt="image description"
                    ></img>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-lg">Invited Speaker Name</p>
                    <p className="text-sm ">Invited Speaker tagline</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mx-3 my-2 p-5 bg-white w-1/2">
              <div className="flex flex-col">
                <p className="text-xl">
                  Update your profile and accept invitation
                </p>
                <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-400"></hr>
                <div className="flex flex-row w-full px-5">
                  <div className="w-[25%]">
                    <label className="text-sm font-semibold text-center w-1/3">
                      Speaker name
                      <span className="text-red-500 font-bold">*</span>
                    </label>
                  </div>
                  <div className="flex flex-row w-[75%] justify-between">
                    <input
                      type="text"
                      name="firstname"
                      readOnly
                      value={user?.firstName}
                      className="border text-base  rounded-md p-1 focus:outline-none bg-gray-100"
                      placeholder="firstname"
                    />
                    <input
                      type="text"
                      name="lastname"
                      readOnly
                      value={user?.lastName}
                      className="border text-base  rounded-md p-1 focus:outline-none bg-gray-100"
                      placeholder="lastname"
                    />
                  </div>
                </div>
                <div className="flex flex-row w-full px-5 mt-5">
                  <div className="w-[25%]">
                    <label className="text-sm font-semibold text-center w-1/3">
                      Speaker email
                      <span className="text-red-500 font-bold">*</span>
                    </label>
                  </div>
                  <div className="flex flex-row w-[75%] justify-between">
                    <input
                      type="text"
                      name="email"
                      readOnly
                      value={user?.email}
                      className="border text-base w-full rounded-md p-1 focus:outline-none bg-gray-100"
                      placeholder="email"
                    />
                  </div>
                </div>
                <div className="flex flex-row w-full px-5 mt-5">
                  <div className="w-[25%]">
                    <label className="text-sm font-semibold text-center w-1/3">
                      Speaker tagline
                      <span className="text-red-500 font-bold">*</span>
                    </label>
                  </div>
                  <div className="flex flex-col w-[75%] justify-between">
                    <input
                      type="text"
                      name="tagLine"
                      value={speakerData?.tagLine}
                      onChange={(e) => handleSpeakerDataChanges(e)}
                      className="border text-base w-full rounded-md p-1 focus:outline-none"
                      placeholder="tagline"
                    />
                    {errors["tagLine"] && (
                      <div className="text-red-500 text-xs">
                        {errors["tagLine"]}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row w-full px-5 mt-5">
                  <div className="w-[25%]">
                    <label className="text-sm font-semibold w-1/3">
                      Speaker biography
                      <span className="text-red-500 font-bold">*</span>
                    </label>
                  </div>
                  <div className="flex flex-col w-[75%] justify-between">
                    <input
                      type="text"
                      name="biography"
                      value={speakerData?.biography}
                      onChange={(e) => handleSpeakerDataChanges(e)}
                      className="border text-base w-full rounded-md p-1 focus:outline-none"
                      placeholder="biography"
                    />
                    {errors["biography"] && (
                      <div className="text-red-500 text-xs">
                        {errors["biography"]}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row w-full px-5 mt-5">
                  <label className="text-sm font-semibold w-1/3">
                    Speaker Type<span className="text-red-500"> *</span>
                  </label>
                  <div className="flex flex-col w-full">
                    <select
                      name="speakersType"
                      value={speakerData?.speakersType}
                      onChange={(e) => handleSpeakerDataChanges(e)}
                      className="border w-full text-base placeholder:text-sm border-gray-300 rounded-md p-1 focus:outline-none focus:border-gray-400"
                    >
                      <option selected disabled>
                        Select
                      </option>
                      {JSON.parse(event?.eventDetails?.speakerType || "[]").map(
                        (level) => (
                          <option key={level} value={level}>
                            {level}
                          </option>
                        )
                      )}
                    </select>
                    {errors.speakersType && (
                      <div className="text-red-500 text-xs">
                        {errors.speakersType}
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex flex-row w-full px-5 mt-5">
                  <label className="text-sm font-semibold w-1/3">
                    Shirt Size<span className="text-red-500"> *</span>
                  </label>
                  <div className="flex flex-col w-full">
                    <select
                      name="tShirtSize"
                      value={speakerData?.tShirtSize}
                      onChange={(e) => handleSpeakerDataChanges(e)}
                      className="border w-full text-base placeholder:text-sm border-gray-300 rounded-md p-1 focus:outline-none focus:border-gray-400"
                    >
                      <option selected disabled>
                        Select
                      </option>
                      {JSON.parse(event?.eventDetails?.tShirtSizes || "[]").map(
                        (level) => (
                          <option key={level} value={level}>
                            {level}
                          </option>
                        )
                      )}
                    </select>
                    {errors.tShirtSize && (
                      <div className="text-red-500 text-xs">
                        {errors.tShirtSize}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row w-full px-5 mt-5">
                  <label className="text-sm font-semibold w-1/3">
                    Previous Talks<span className="text-red-500"> *</span>
                  </label>
                  <div className="flex flex-col w-full">
                    <input
                      type="text"
                      name="previousTalks"
                      value={speakerData?.previousTalks}
                      onChange={(e) => handleSpeakerDataChanges(e)}
                      className="border text-base w-full rounded-md p-1 focus:outline-none "
                      placeholder="Previous Talks"
                    />
                    {errors.previousTalks && (
                      <div className="text-red-500 text-xs">
                        {errors.previousTalks}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row w-full px-5 mt-5">
                  <label className="text-sm font-semibold w-1/3">
                    Speaker photo<span className="text-red-500"> *</span>
                  </label>
                  <div className="p-2 bg-[#f3f3f4] w-full">
                    {speakerData?.photoPath !== null && (
                      <div className="flex flex-row justify-between items-start">
                        <div className="p-2 mb-2">
                          <img
                            src={speakerData?.photoPath}
                            alt="Preview"
                            // onClick={handleImageChanges}
                            style={{ maxWidth: "100%", maxHeight: "200px" }}
                          />
                        </div>
                        <button
                          type="button"
                          onClick={() => {
                            setSpeakerData((prevSession) => {
                              const updatedEvent = setNestedValue(
                                prevSession,
                                "photoPath",
                                null
                              );
                              return updatedEvent;
                            });
                          }}
                          className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        >
                          <span className="sr-only">Close menu</span>
                          <svg
                            className="h-3 w-3"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="red"
                            viewBox="0 0 24 24"
                            stroke="black"
                            aria-hidden="true"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    )}
                    <div>
                      <input
                        type="file"
                        name="photoPath"
                        ref={fileInputRef}
                        onChange={(e) => handleImageChanges(e, "SpeakerPhotos")}
                        style={{ display: "none" }}
                      />
                      <div
                        className="my-4 mx-2 bg-white p-2 cursor-pointer"
                        onClick={() => {
                          fileInputRef.current.click();
                        }}
                      >
                        Choose file or Click here to Upload
                      </div>
                    </div>
                    {errors.photoPath && (
                      <div className="text-red-500 text-xs">
                        {errors.photoPath}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row w-full px-5 mt-5">
                  <label className="text-sm font-semibold w-1/3">
                    Company name
                    <span className="text-red-500 font-bold">*</span>
                  </label>
                  <div className="flex flex-col w-full">
                    <input
                      type="text"
                      name="companyName"
                      value={speakerData?.companyName}
                      onChange={(e) => handleSpeakerDataChanges(e)}
                      className="border text-base w-full rounded-md p-1 focus:outline-none "
                      placeholder="Company name"
                    />
                    {errors["companyName"] && (
                      <div className="text-red-500 text-xs">
                        {errors["companyName"]}
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex flex-row w-full px-5 mt-5">
                  <label className="text-sm font-semibold w-1/3">
                    Company logo<span className="text-red-500"> *</span>
                  </label>
                  <div className="p-2 bg-[#f3f3f4] w-full">
                    {speakerData?.companyLogo !== null && (
                      <div className="flex flex-row justify-between items-start">
                        <div className="p-2 mb-2">
                          <img
                            src={speakerData?.companyLogo}
                            alt="Preview"
                            // onChange={(e) => handleImageChanges(e, "CompanyPhotos")}
                            style={{ maxWidth: "100%", maxHeight: "200px" }}
                          />
                        </div>
                        <button
                          type="button"
                          onClick={() => {
                            setSpeakerData((prevSession) => {
                              const updatedEvent = setNestedValue(
                                prevSession,
                                "companyLogo",
                                null
                              );
                              return updatedEvent;
                            });
                          }}
                          className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        >
                          <span className="sr-only">Close menu</span>
                          <svg
                            className="h-3 w-3"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="red"
                            viewBox="0 0 24 24"
                            stroke="black"
                            aria-hidden="true"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    )}
                    <div>
                      <input
                        type="file"
                        name="companyLogo"
                        ref={companyPhotoRef}
                        onChange={(e) => handleImageChanges(e, "CompanyPhotos")}
                        style={{ display: "none" }}
                      />
                      <div
                        className="my-4 mx-2 bg-white p-2 text-center cursor-pointer"
                        onClick={() => {
                          companyPhotoRef.current.click();
                        }}
                      >
                        Choose file or Click here to Upload
                      </div>
                    </div>
                    {errors.companyLogo && (
                      <div className="text-red-500 text-xs">
                        {errors.companyLogo}
                      </div>
                    )}
                  </div>
                </div>
                <hr className="h-px my-4 bg-gray-200 dark:bg-gray-400"></hr>
                <div className="flex flex-row justify-center  flex-grow my-5">
                  <div className="flex w-full">
                    <input
                      type="checkbox"
                      checked={termandCondtion}
                      onChange={() => setTermsandCondition(!termandCondtion)}
                      className="w-8 h-8 text-blue-600 bg-gray-100 dark:bg-gray-700 "
                    />
                    <label className="ms-2 text-sm font-medium text-[#5f5353] dark:text-[#5f5353]">
                      I agree that personal data shown on this page is shared
                      with the organizer of {event?.eventName}. Organizer may
                      share your session and personal data publicly as (but not
                      limited to) a part of an event schedule. <br></br>Please
                      read our{" "}
                      <a
                        href="#"
                        className="text-blue-600 dark:text-blue-500 hover:underline"
                      >
                        Terms of Service
                      </a>{" "}
                      and{" "}
                      <a
                        href="#"
                        className="text-blue-600 dark:text-blue-500 hover:underline"
                      >
                        Privacy Policy
                      </a>
                      .
                    </label>
                  </div>
                </div>
                <div className="flex flex-row justify-center flex-grow my-10">
                  <button
                    type="button"
                    onClick={handleSubmit}
                    className="px-6 py-3.5 w-full justify-center text-base font-medium text-white inline-flex items-center bg-[#198ae3] hover:bg-[#198ae3] focus:outline-none focus:ring-blue-300 text-center dark:bg-[#198ae3] dark:hover:bg-[#176aab] dark:focus:ring-blue-800"
                  >
                    Accept Invitation
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isInviteLinkExpired && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-10">
          <div
            role="status"
            className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
          >
            <div className="flex flex-col bg-white p-4 align-center my-4 p-4">
              <p className="text-center">Invite Link Expired</p>
              <button
                onClick={() => {
                  window.open("/speaker/user-profile");
                }}
                className="flex flex-row items-center gap-5 justify-center p-2 m-4 text-sm font-medium focus:outline-none bg-[#1ab394] hover:bg-gray-100 hover:text-white dark:text-white dark:hover:text-white dark:hover:bg-[#08745f]"
                type="button"
              >
                Go to Home page
              </button>
            </div>
            {/* <HashLoader color={'white'} loading={loading} size={50}/> */}
          </div>
        </div>
      )}
      {user?.isProfileComplete === false && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-10">
          <div
            role="status"
            className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
          >
            <div className="flex flex-col bg-white p-4 align-center my-4 p-4">
              <p className="text-center">Please complete your User Profile</p>
              <button
                onClick={() => {
                  window.open("/speaker/user-profile", "_blank");
                }}
                className="flex flex-row items-center gap-5 justify-center p-2 m-4 text-sm font-medium focus:outline-none bg-[#1ab394] hover:bg-gray-100 hover:text-white dark:text-white dark:hover:text-white dark:hover:bg-[#08745f]"
                type="button"
              >
                Go to User Profile
              </button>
              <p>
                If you already updated{" "}
                <button
                  className="text-blue-800"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  click here
                </button>{" "}
                to refresh
              </p>
            </div>
            {/* <HashLoader color={'white'} loading={loading} size={50}/> */}
          </div>
        </div>
      )}
      {loading && (
        <div className="fixed inset-0 bg-black bg-opacity-80 z-10">
          <div
            role="status"
            className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
          >
            <HashLoader color={"white"} loading={loading} size={50} />
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};
