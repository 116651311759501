import { FC, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import SessionsService from "../../../services/sessionService";
import { HashLoader } from "react-spinners";

interface WithdrawalSessionProps {
  onClose: () => void;
  sessionId: string;
  open: boolean;
  sessionTitle: string;
  fetchSessions: any;
}

interface FormValues {
  reason: string;
}

const WithdrawalSession: FC<WithdrawalSessionProps> = (props) => {
  const dialogRef = useRef<HTMLDivElement | null>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        handleSubmit(onSubmit)();
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (
        dialogRef.current &&
        !dialogRef.current.contains(event.target as Node)
      ) {
        props.onClose();
      }
    };

    if (props.open) {
      document.addEventListener("keydown", handleKeyPress);
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("keydown", handleKeyPress);
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props.open, props.onClose]);

  const onSubmit = async (formvalue: FormValues) => {
    setLoading(true);
    try {
      const data = await SessionsService.withdrawalSession(
        props.sessionId,
        true,
        formvalue.reason
      );
      setLoading(false);
      showSuccessToast();
      setTimeout(() => {
        props.onClose();
      }, 800);
      props.fetchSessions();
      return data;
    } catch (error) {
      setLoading(false);
      showErrorToast();
    }
  };

  if (!props.open) return null;

  const showSuccessToast = () =>
    toast.success("Withdrawal completed successfully", { autoClose: 1000 });
  const showErrorToast = () =>
    toast.error("Withdrawal failed. Please try again later", {
      autoClose: 10000,
    });

  return (
    <div>
      <div
        className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50"
        style={{ zIndex: 2000 }}
      >
        <div
          ref={dialogRef}
          className="bg-white p-6 rounded shadow-lg w-3/4 md:w-2/4"
        >
          <h2 className="text-xl font-semibold mb-4 text-center border-b pb-2">
            Cancel engagement
          </h2>
          <div className="py-2 font-semibold">{props?.sessionTitle}</div>
          <p className="mb-6 bg-red-100 text-red-500 px-3 py-1 rounded-md">
            Call for Speakers period is over and organizer is evaluating
            submissions. They may already be counting on you and your session,
            so please do not cancel your participation without a really good
            reason.
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <label htmlFor="reason" className="block mb-2 font-semibold">
                Message to organizer (please include cancellation reason)
              </label>
              <textarea
                id="reason"
                rows={5}
                {...register("reason", { required: "Reason is required" })}
                className="w-full focus:outline-slate-300 p-2 border border-gray-300 rounded"
              />
              {errors.reason && (
                <small className="text-red-600">{errors.reason.message}</small>
              )}
            </div>
            <p>
              This withdrawal request will be sent to the organizer so they can
              cancel your participation.
            </p>
            <div className="flex justify-center mt-3 space-x-4">
              <button
                type="button"
                onClick={props.onClose}
                className="px-4 py-2 bg-[#625FFB] text-white rounded"
              >
                Close
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-[#fb5f69] text-white rounded"
              >
                Request withdrawal
              </button>
            </div>
          </form>
        </div>
      </div>
      {loading && (
        <div className="absolute inset-0 bg-black bg-opacity-80 z-10">
          <div
            role="status"
            className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
          >
            <HashLoader color={"white"} loading={loading} size={50} />
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default WithdrawalSession;
