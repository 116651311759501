import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./EditEvent.scss";
import Cfs from "../CFS/CFS";
import CfsIntergrate from "../CFSIntergrate/CFSIntergrate";
import { Navigate, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import EventService from "../../../services/eventService";
import toast from "react-hot-toast";
import EditForm from "../EditForm/EditForm";
import { HashLoader } from "react-spinners";
import { convertToEventModel, validateForm } from "./EditEventService";
import "./EditEvent.scss";
import { AuthContext } from "../../../context/AuthContext";

interface EditEventProps {}

interface Field {
  id: number;
  title: string;
  type: string;
  inputType: string;
  value: string[];
  required: boolean;
}

export const EventContext = React.createContext(null);

const EditEvent: FC<EditEventProps> = () => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [activeTab, setActiveTab] = useState<number>(1);
  const navigate = useNavigate();
  const { eventId } = useParams<{ eventId: string }>();
  const [event, setEvent] = useState<any>();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isEventChecked, setIsEventChecked] = useState(false);
  const [isCfsChecked, setIsCfsChecked] = useState(false);
  const [isSubmissionChecked, setIsSubmissionChecked] = useState(false);
  const contentRef = useRef(null);
  const cfsContentRef = useRef(null);
  const submissionContentRef = useRef(null);
  const {auth} = useContext(AuthContext);
  const setEventDetails = (data) => {
    setEvent(data);
  };

  
  useEffect(() => {
    setLoading(true);
    const fetchEvents = async () => {
      try {
        const response = await EventService.getEventbyId(eventId);
        if (response.data == null) {
          setLoading(false);
          navigate("/not-found");
        } else {
          setEvent(response.data);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        toast.error("Error fetching events");
        navigate("/not-found");
      }
      setLoading(false);
    };
    fetchEvents();
  }, [eventId]);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const handleTabClick = (tabNumber: number) => {
    setActiveTab(tabNumber);
  };
  const [fields, setFields] = useState<Field[]>([]);

  const handleFieldsChange = (updatedFields: Field[]) => {
    setFields(updatedFields);
  };

  const handleSaveChanges = async () => {
    setLoading(true);
    const formErrors = validateForm(event, errors);
    var eventModel = convertToEventModel(event, auth);
    console.log(formErrors);
    setErrors(formErrors);
    if (Object.keys(formErrors).length === 0) {
      var result = await EventService.updateEvent(eventModel);
      setLoading(false);
      toast.success("Changes Saved Successfully");
    } else {
      setLoading(false);
      toast.error("Form has errors !");
    }
  };

  interface TabProps {
    id: string;
    label: string;
    switchTab: number;
    isActive: boolean;
  }

  const Tab: React.FC<TabProps> = ({ id, label, switchTab, isActive }) => {
    return (
      <li className={`me-2 ${activeTab}`} role="presentation">
        <button
          className={`inline-block p-4 ${isActive && "bg-white"}`}
          id={`${id}-tab`}
          data-tabs-target={`#${id}`}
          type="button"
          role="tab"
          aria-controls={id}
          aria-selected="false"
          onClick={() => handleTabClick(switchTab)}
        >
          {label}
        </button>
      </li>
    );
  };

  return (
    <EventContext.Provider value={{ event, setEvent, errors, setErrors }}>
      <div className="flex">
        <div className="column" style={{ width: "100%" }}>
          <div className={`row`}>
            {/* <DashboardHeader onCloseSidebar={toggleSidebar} /> */}

            <div className="flex flex-row justify-between mt-4 p-3 bg-white">
              <div className="p-2 text-2xl font">
                {event && event?.eventName}
              </div>
              <div className="flex">
                <button
                  className="flex flex-row items-center justify-between  px-5 me-2 mb-2 text-sm font-medium focus:outline-none bg-white border  hover:bg-gray-100 hover:text-[#625ffb] focus:z-10 dark:text-[#625ffb] dark:border-[#625ffb] dark:hover:text-white dark:hover:bg-[#625ffb]"
                  type="button"
                  role="tab"
                  aria-selected="false"
                  onClick={() =>
                    navigate(`/cfs/${event?.eventCallForSpeakers?.slugURL}`)
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                    />
                  </svg>

                  <span>CFS Page</span>
                </button>
                <button
                  className=" flex flex-row items-center justify-between py-2.5 px-5 me-2 mb-2 text-sm font-medium focus:outline-none bg-white border  hover:bg-gray-100 hover:text-blue-700 focus:z-10 dark:text-gray-500 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-500"
                  type="button"
                  role="tab"
                  aria-selected="false"
                  onClick={() => navigate("/organizer/event-list")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                    />
                  </svg>

                  <span>All Events</span>
                </button>
              </div>
            </div>

            <div className="flex flex-row justify-end p-3 gap-3">
              <button
                className={`px-7 py-2  rounded-full bg-[#625ffb] text-white`}
                type="button"
                role="tab"
                onClick={() => handleSaveChanges()}
                aria-selected="false"
              >
                <span>Save Changes</span>
              </button>
              {/* <button
                className="flex flex-row items-center justify-between px-5 me-2 mb-2 text-sm font-medium focus:outline-none bg-[#1ab394] hover:bg-gray-100 hover:text-white dark:text-white dark:hover:text-white dark:hover:bg-[#08745f]"
                type="button"
                role="tab"
                aria-selected="false"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Zm3.75 11.625a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
                  />
                </svg>

                <span>Save + Preview</span>
              </button> */}
              <button
                className={`px-7 py-2  rounded-full border border-[#625ffb] text-[#625ffb] `}
                type="button"
                role="tab"
                aria-selected="false"
              >
                <span>Delete</span>
              </button>
            </div>

            <div className="px-5">
              <div className="EditEvent" data-testid="EditEvent">
                <div className="p-2">
                  <input
                    type="checkbox"
                    checked={isEventChecked}
                    onChange={() => {
                      setIsEventChecked(!isEventChecked);
                    }}
                    className="hidden"
                  />
                  <div
                    className="text-xl font-medium cursor-pointer border border-[#DDDDDD]  p-4  bg-[#d6d6d6] text-black"
                    onClick={() => {
                      setIsEventChecked(!isEventChecked);
                    }}
                  >
                    <div className="flex flex-row justify-between">
                      <div className="flex flex-row items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6 me-2 align"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                          />
                        </svg>
                        <div>Event</div>
                      </div>
                      {isEventChecked ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m4.5 15.75 7.5-7.5 7.5 7.5"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m19.5 8.25-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                  <div
                    ref={contentRef}
                    className={`transition-max-height duration-500 ease-in-out overflow-hidden ${
                      isEventChecked
                        ? " border border-2 border-gray-300 bg-white"
                        : "max-h-0"
                    }`}
                    style={{
                      maxHeight: isEventChecked
                        ? `${contentRef?.current?.scrollHeight || 0}px`
                        : "0px",
                    }}
                  >
                    <EditForm />
                  </div>
                </div>

                <div className="p-2">
                  <input
                    type="checkbox"
                    checked={isCfsChecked}
                    onChange={() => {
                      setIsCfsChecked(!isCfsChecked);
                    }}
                    className="hidden"
                  />
                  <div
                    className="text-xl font-medium cursor-pointer border border-[#DDDDDD] p-4  bg-[#d6d6d6] text-black"
                    onClick={() => {
                      setIsCfsChecked(!isCfsChecked);
                    }}
                  >
                    <div className="flex flex-row justify-between">
                      <div className="flex flex-row items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className={`w-5 h-5 me-2`}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 1 1 0-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 0 1-1.44-4.282m3.102.069a18.03 18.03 0 0 1-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 0 1 8.835 2.535M10.34 6.66a23.847 23.847 0 0 0 8.835-2.535m0 0A23.74 23.74 0 0 0 18.795 3m.38 1.125a23.91 23.91 0 0 1 1.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 0 0 1.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 0 1 0 3.46"
                          />
                        </svg>
                        <div>CFS</div>
                      </div>
                      {isCfsChecked ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m4.5 15.75 7.5-7.5 7.5 7.5"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m19.5 8.25-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                  <div
                    ref={cfsContentRef}
                    className={`transition-max-height duration-500 ease-in-out overflow-hidden ${
                      isCfsChecked
                        ? "max-h-screen border border-2 border-gray-300 bg-white"
                        : "max-h-0"
                    }`}
                    style={{
                      maxHeight: isCfsChecked
                        ? `${cfsContentRef?.current?.scrollHeight || 0}px`
                        : "0px",
                    }}
                  >
                    <Cfs />
                  </div>
                </div>

                <div className="p-2">
                  <input
                    type="checkbox"
                    checked={isSubmissionChecked}
                    onChange={() => {
                      setIsSubmissionChecked(!isSubmissionChecked);
                    }}
                    className="hidden"
                  />
                  <div
                    className="text-xl font-medium cursor-pointer border border-[#DDDDDD]  p-4  bg-[#d6d6d6] text-black"
                    onClick={() => {
                      setIsSubmissionChecked(!isSubmissionChecked);
                    }}
                  >
                    <div className="flex flex-row justify-between">
                      <div className="flex flex-row items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6 me-2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                          />
                        </svg>
                        <div>Submission Fields</div>
                      </div>
                      {isSubmissionChecked ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m4.5 15.75 7.5-7.5 7.5 7.5"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m19.5 8.25-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                  <div
                    ref={submissionContentRef}
                    className={`transition-max-height duration-500 ease-in-out overflow-hidden ${
                      isSubmissionChecked
                        ? "max-h-screen border border-2 border-gray-300 bg-white"
                        : "max-h-0"
                    }`}
                    style={{
                      maxHeight: isSubmissionChecked
                        ? `${
                            submissionContentRef?.current?.scrollHeight || 0
                          }px`
                        : "0px",
                    }}
                  >
                    <div className="max-h-screen expand">
                      <CfsIntergrate />
                    </div>
                  </div>
                </div>

                {/* <div className="tab-content p-4 bg-white">
                  {activeTab === 1 && (
                    <div>
                      <EditForm />
                    </div>
                  )}
                  {activeTab === 2 && (
                    <div>
                      <Cfs />
                    </div>
                  )}
                  {activeTab === 3 && (
                    <div>
                      <CfsIntergrate />
                    </div>
                  )}
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {loading && (
          <div className="absolute inset-0 bg-black bg-opacity-80 z-10">
            <div
              role="status"
              className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
            >
              <HashLoader color={"white"} loading={loading} size={50} />
            </div>
          </div>
        )}
      </div>
    </EventContext.Provider>
  );
};

export default EditEvent;
