export const validateForm = (event) => {
    console.log("Validating");
    const errors = {};
  
    if (event?.eventDetails?.eventType === 0) {
      if (!event?.eventDetails?.eventLocation) {
        errors["eventDetails.eventLocation"] = "Field is required";
      }
  
      if (!event?.eventDetails?.venue) {
        errors["eventDetails.venue"] = "Field is required";
      }
    }
  
    console.log("errors", errors);
    return errors;
  };

  export const setNestedValue = (obj, path, value) => {
    const keys = path.split(".");
    let temp = obj;
  
    keys.forEach((key, index) => {
      if (index === keys.length - 1) {
        temp[key] = value;
      } else {
        if (!temp[key]) temp[key] = {};
        temp = temp[key];
      }
    });
  
    return { ...obj };
  };
  