import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserRoleFromValue } from "../../enums/Providers";
import { UUIDGenerator } from "../../helper/Guid";
import UserService from "../../services/userService";
import { toast } from "react-toastify";
import { HashLoader } from "react-spinners";
import { AuthContext } from "../../context/AuthContext";

function ConsentForm() {
  const location = useLocation();
  const { email, name, providerId } = location.state || {};
  const urlParams = new URLSearchParams(location.search);
  const queryParams = new URLSearchParams(location.search);
  const from = queryParams.get('returnUrl') || '/speaker/user-profile';
  const [loading, setLoading] = useState(false);
  const code = urlParams.get("code");
  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const saveUserData = (userData) => {
    setAuth({
      role: userData?.roleId.toString(),
      userId: userData?.id,
      userEmail: userData?.email,
      userFirstName: userData?.firstName,
      userlastName: userData?.lastName,
    });
  };

  const handleSubmit = async () => {
    try{
        setLoading(true);
        var userDetails = convertToJsonData();
        var response = await UserService.createAuthUser(userDetails, providerId);
        if(response){
          const userData = await UserService.getUserbyUserId(response?.userId);
            saveUserData(userData);
            setLoading(false);
            navigate(from, { replace: true });
        }else{
            setLoading(false);
            showErrorToast();
        }
    }
    catch(error){

    }
  }

  const showErrorToast = () =>
    toast.error("Something went wrong !", { autoClose: 10000 });

  const convertToJsonData = () =>{
    const guid=UUIDGenerator.generateUUID();
    const finalData = {
      id: guid,
      firstName: name,
      email: email,
      roleId:1,
      createdBy: guid,
      createdOn: new Date().toISOString(),
      isActive: true,
      userSocialAccounts:{
        id:UUIDGenerator.generateUUID(),
        userId: guid,
        providerId: providerId,
        name: name,
        socialUserID:email,
        createdBy: guid,
        createdOn: new Date().toISOString(),
      }
    };
    return finalData;
  }

  return (
    <div className="flex items-center justify-center h-screen w-full px-5 sm:px-0">
      {loading && (
        <div className="absolute inset-0 bg-black bg-opacity-80 z-10">
          <div
            role="status"
            className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
          >
            <HashLoader color={"white"} loading={loading} size={50} />
          </div>
        </div>
      )}
      <div className="flex bg-white rounded-lg shadow-lg border overflow-hidden max-w-sm lg:max-w-4xl w-full">
        <div className="w-full p-8">
          <p className="text-xl text-gray-600 text-center">Consent form</p>
          <br></br>
          <p>
            This is the first time you're using your{" "}
            {getUserRoleFromValue(providerId)} account <strong>{email}</strong>{" "}
            to access Orator Engage, so we wanted to be sure that you want to do
            that. There is no point in creating accounts that will not be used.
          </p>
          <br></br>
          <p>
            If you've just realized that you had used another account for Orator
            Engage,{" "}
            <a href="/signin" className="text-[#625ffb]">
              click here to login
            </a>{" "}
            with the different account. If the account is of the same type, you
            may need to log out manually from {getUserRoleFromValue(providerId)}{" "}
            (closing all browser windows usually helps).
          </p>
          <div className="mt-4 p-4 flex flex-row justify-end">
            <div className="flex flex-row justify-between">
              <button
                type="submit"
                className="bg-[#625ffb] text-white font-bold py-2 px-4 w-full rounded hover:bg-[#625ffb]"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConsentForm;
