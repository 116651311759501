import { title } from "process";
import { UUIDGenerator } from "../../../helper/Guid";
import { SessionStatus } from "../../../enums/SessionStatus";

export const convertToSpeakerData = (user,speakers, setSpeakersData)=>{
  var speakerData = {
        tagLine: speakers?.tagLine ?? user?.userProfile?.tagLine,
        biography: speakers?.biography ?? user?.userProfile?.biography,
        photoPath: speakers?.photoPath ?? user?.userProfile?.photoUrl,
        tShirtSize: speakers?.tShirtSize,
        speakersType: speakers?.speakersType,
        previousTalks: speakers?.previousTalks,
        companyLogo: speakers?.companyLogo ,
        companyName: speakers?.companyName,
  }
  return speakerData;
}

export const convertToSessionModel = (event, session, userData, speakerData) => {
  var sessionModel = {
    // id: UUIDGenerator.generateUUID(),
    eventId: event?.id,
    title: session.title,
    description: session.description,
    language: session.languages,
    coSpeakers: session.coSpeakers,
    sessionStatus: SessionStatus.Nominated,
    additionalNotes : session.additionalNotes,
    category: session.category, 
    sessionLevel: session.sessionLevel,
    sessionType: session.sessionType,
    techStack: session.techStack,
    createdBy: userData.id,
    createdOn: new Date().toISOString(),
    isConfirm : true,
    speakers: [
      {
        id: UUIDGenerator.generateUUID(),
        userId: userData.id,
        eventId: event?.id,
        tagLine: speakerData?.tagLine,
        biography: speakerData?.biography,
        photoPath: speakerData?.photoPath,
        tShirtSize: speakerData?.tShirtSizes,
        speakersType: speakerData?.speakersType,
        previousTalks: speakerData?.previousTalks,
        companyLogo: speakerData?.companyLogo,
        companyName: speakerData?.companyName,
        createdBy: userData.id,
        createdOn: new Date().toISOString(),
      },
    ],
  };
  console.log(sessionModel);
  return sessionModel;
};
