export const UserURLConfig = {
    classicLogin: "User/UserLogin?",
    registerUser: "User/CreateUser",
    updateUser: "User/UpdateUser",
    createAuthUser: "User/CreateAuthUser",
    checkAuthUserExist : 'User/CheckAuthUserExist?',
    GetUserByUserId : "User/GetUserByUserId?",
    getCaptcha: "User/getCaptcha",
    isMail: "User/isMail?",
    mailSend: "User/mailasend?",
    forgetPassword: "User/forgetPassword?"
  };