import React, { FC } from "react";
import "./Footer.scss";

interface FooterProps {}

const Footer: FC<FooterProps> = () => (
  <div className="bg-[#303030] h-full">
    <div
      id="Footer"
      className="Footer mx-5 md:mx-24 md:mt-16 md:p-5 py-2  font-open_sans"
      data-testid="Footer"
    >
      <div>
        {/* <div className="flex justify-center items-center relative">
          <img
            alt="background-img"
            className=""
            src="/footer/bcakground-image.svg"
          />
          <div className="absolute flex justify-center items-center md:gap-20 p-2">
            <div className="text-xs md:text-3xl text-white md:leading-10">
              <div>
                Build stronger <span className="font-bold">speaker</span>
              </div>
              <div>
                <span className="font-bold"> relationships</span> with OratorEnage
              </div>
            </div>
            <div className="flex flex-col md:flex-row justify-center items-center gap-2 md:gap-4">
              <div>
                <input
                  className="md:p-4 p-2 text-xs md:text-base rounded-full placeholder:text-[#888] placeholder:pl-5 text-white focus:outline-none bg-[#383838]"
                  type="text"
                  placeholder="Enter your email"
                />
              </div>
              <button
                type="button"
                className="bg-white text-xs md:text-base rounded-full p-2 my-1 md:p-4 font-bold"
              >
                SUBMIT
              </button>
            </div>
          </div>
        </div> */}
        <div className="md:flex items-start justify-start text-white gap-28  my-7">
          <div className="md:-mt-16">
            <div className=" rounded-lg md:py-10 md:px-12 ">
              <div className="text-2xl flex items-center gap-2 font-bold">
                <img src="/login/logo_color.svg" />
              </div>
              <div className="my-5">
                Survey No 65/3c3, Door No 1115, Indira Nagar,
              </div>
              <div className="flex flex-col my-5">
                Mambattu, Polur, Thiruvannmalai (Dt)-606803
              </div>
              <div>
                email:
                <a
                  className="text-blue-700 font-semibold mx-1 my-5"
                  target="_blank"
                  href="mailto:support@oratorenage.com"
                >
                  support@oratorenage.com
                </a>
              </div>
            </div>
          </div>
          <div>
            <div className="flex gap-28 ">
              <div className="font-medium">
                <div className="font-bold text-xl">Support</div>
                <div className="flex flex-col  gap-4 my-4">
                  <a href="/home">
                    <div>Home</div>
                  </a>
                  {/* <a href="/#About">
                    <div>About</div>
                  </a> */}
                  {/* <a href="/#Resource">
                    <div>Resource</div>
                  </a>
                  <a href="#Pricing">
                    <div>Pricing</div>
                  </a>
                  <a href="#Testimonial">
                    <div>Testimonial</div>
                  </a> */}
                  <a href="#Contact">
                    <div>Contact</div>
                  </a>
                </div>
              </div>
              {/* <div className="font-medium">
                <div className="font-bold text-xl">Utility Pages</div>
                <div className="flex flex-col  gap-4 my-4 ">
                  <a href=" /home">
                    <div>Create Event</div>
                  </a>
                  <a href="/#About">
                    <div>About</div>
                  </a>
                  <a href="/#Resource">
                    <div>Resource</div>
                  </a>
                  <a href="#Pricing">
                    <div>Pricing</div>
                  </a>
                  <a href="#Testimonial">
                    <div>Testimonial</div>
                  </a>
                  <a href="#Contact">
                    <div></div>
                  </a>
                </div>
              </div> */}
            </div>
            <div className="border-t-2 "></div>
            <div className="mt-5 ">
              Copyright ©
              <a
                target="_blank"
                className="text-blue-700 font-semibold mx-1"
                href="https://xmonkeys360.com/"
              >
                XMonkeys360
              </a>
              2024. All rights reserved
            </div>
          </div>
          <div className="flex gap-4  items-center my-5">
            <div className="font-semibold">Follow Us :</div>
            <div className="flex gap-4 justify-center items-center">
              <a target="_blank" href="https://www.instagram.com/xmonkeys_360/">
                <img src="/footer/insta.svg" />
              </a>
              <a target="_blank" href="https://www.facebook.com/xmonkeys360">
                <img src="/footer/Fb.svg" />
              </a>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/xmonkeys360/"
              >
                <img src="/footer/In.svg" />
              </a>
              <a target="_blank" href="https://x.com/XMonkeys360">
                <img src="/footer/Twiter.svg" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
