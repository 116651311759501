import React, { FC, useContext, useEffect, useRef, useState } from "react";
import "./NominateAsOrganizer.scss";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import EventService from "../../../../services/eventService";
import BlobService from "../../../../services/blobService";
import SessionsService from "../../../../services/sessionService";
import { UUIDGenerator } from "../../../../helper/Guid";
import { AuthContext } from "../../../../context/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import { HashLoader } from "react-spinners";

interface FormValues {
  sessionTitle: string;
  description: string;
  category: string;
  sessionType: string;
  sessionLevel: string;
  languages: string;
  techStack: string;
  additionalNotes: string;
  speakerFirstName: string;
  speakerLastName: string;
  speakerBiography: string;
  designation: string;
  areaofExpertise: string;
  location: string;
  tagline: string;
  topics: string;
  linkedIn: string;
  twitter: string;
  instagram: string;
  companyWebsite: string;
  blog: string;
  facebook: string;
  speakerType: string;
  previousTalks: string;
  companyName: string;
  shirtSize: string;
  password: string;
  email: string;
}

const NominateAsOrganizer: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<FormValues>();
  const { eventId } = useParams<{ eventId: string }>();
  const [event, setEvent] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [speakerCompanyLogo, setSpeakerCompanyLogo] = useState<any>(null);
  const [speakerPhoto, setSpeakerPhoto] = useState<any>(null);
  const speakerPhotoRef = useRef<HTMLInputElement | null>(null);
  const companyPhotoRef = useRef(null);
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    getEventData();
  }, []);

  const getEventData = async () => {
    try {
      const event = (await EventService.getEventbyId(eventId)).data;
      setEvent(event);

      if (event?.eventDetails) {
        setValue(
          "category",
          JSON.parse(event.eventDetails.sessionCategory)[0] || ""
        );
        setValue(
          "sessionType",
          JSON.parse(event.eventDetails.sessionType)[0] || ""
        );
        setValue(
          "sessionLevel",
          JSON.parse(event.eventDetails.sessionLevel)[0] || ""
        );
        setValue(
          "languages",
          JSON.parse(event.eventDetails.languages)[0] || ""
        );
        setValue(
          "techStack",
          JSON.parse(event.eventDetails.techStack)[0] || ""
        );
        setValue(
          "speakerType",
          JSON.parse(event.eventDetails.speakerType)[0] || ""
        );
        setValue(
          "shirtSize",
          JSON.parse(event.eventDetails.tShirtSizes)[0] || ""
        );
      }
    } catch (err) {
      console.error("Error fetching event data:", err);
    }
  };

  const checkImagesExist = () =>{
    if(speakerPhoto===null || speakerPhoto===undefined || speakerPhoto===''){
      toast.error("Speaker Photo is required !", { autoClose: 1000 });
      return false;
    }
    
    if(speakerCompanyLogo===null || speakerCompanyLogo===undefined || speakerCompanyLogo===''){
      toast.error("Company Logo is required !", { autoClose: 1000 });
      return false;
    }
    return true;
  }

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (!checkImagesExist()) {
      return; 
    }
    setLoading(true);
    const userId = UUIDGenerator.generateUUID();
    const speaker = {
      id: UUIDGenerator.generateUUID(),
      userId: userId,
      eventId: eventId || "",
      tagLine: data?.tagline,
      biography: data?.speakerBiography,
      photoPath: speakerPhoto || "",
      tShirtSize: data?.shirtSize,
      speakersType: data?.speakerType,
      previousTalks: data?.previousTalks,
      companyLogo: speakerCompanyLogo || "",
      companyName: data?.companyName,
      createdBy: auth?.userId,
      createdOn: new Date().toISOString(),
    };

    const submissionData = {
      eventId: eventId || "",
      title: data?.sessionTitle,
      description: data?.description,
      language: data?.languages,
      coSpeakers: [],
      sessionStatus: 0,
      category: data?.category,
      sessionLevel: data?.sessionLevel,
      sessionType: data?.sessionType,
      techStack: data?.techStack,
      isConfirm: true,
      createdBy: auth?.userId,
      createdOn: new Date().toISOString(),
      speakers: [speaker],
      users: {
        id: userId,
        firstName: data?.speakerFirstName,
        lastName: data?.speakerLastName,
        email: data?.email,
        password: data?.password,
        roleId: 1,
        isActive: true,
        isProfileComplete : true,
        createdBy: auth?.userId,
        createdOn: new Date().toISOString(),
        userProfile: {
          id : UUIDGenerator.generateUUID(),
          userId : userId,
          designation : data?.designation,
          photoUrl : speakerPhoto || "",
          tagLine : data?.tagline,
          biography : data?.speakerBiography,
          areaofExpertise : data?.areaofExpertise ?? null,
          topics: data?.topics ?? null,
          location : data?.location ?? null,
          slugURL : null,
          isShowBio : true,
          isPublicProfile : true,
          CreatedBy : auth?.userId,
          createdOn: new Date().toISOString(),
        },
        userSocialMedia:{
          id: UUIDGenerator.generateUUID(),
          userId: userId,
          twitter : data?.twitter ?? null,
          facebook : data?.facebook ?? null,
          linkedIn : data?.linkedIn ?? null,
          instagram : data?.instagram ?? null,
          blog : data?.blog ?? null,
          companyWebsite : data?.companyWebsite ?? null,
          createdBy : auth?.userId,
          createdOn : new Date().toISOString(),
        }
      },
    };
    await submitSession(submissionData);
  };

  const submitSession = async (submissionDatas: any) => {
    try {
      var isSubmitted = await SessionsService.NominateAsOrganizer(submissionDatas);
      if(isSubmitted === true){
        console.log("Submission successful");
        setLoading(false);
        showSuccessToast();
        reset();
        setSpeakerPhoto(null);
        setSpeakerCompanyLogo(null);
        setTimeout(() => {
          navigate(`/organizer/session-list/${eventId}`, { replace: true });
        }, 1000);
      }
      else{
        setLoading(false);
        showErrorToast();
      }
    } catch (error) {
      setLoading(false);
      showErrorToast();
      console.error("Error submitting data:", error);
    }
    setLoading(false);
  }

  const showSuccessToast = () =>
    toast.success("Session Submitted Successfully", { autoClose: 1000 });
  const showErrorToast = () =>
    toast.error("Something went wrong !", { autoClose: 10000 });

  const handleImageChanges = async (
    e: React.ChangeEvent<HTMLInputElement>,
    folder: string,
    type: string
  ) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const result = await BlobService.uploadFile(
        file,
        event?.eventCallForSpeakers?.slugURL,
        folder
      );
      if (type === "companyLogo") {
        setSpeakerCompanyLogo(result || "");
      } else if (type === "speakerPhoto") {
        setSpeakerPhoto(result || "");
      }
    }
  };

  return (
    <div className="NominateAsOrganizer" data-testid="NominateAsOrganizer">
      <div className="p-5 text-xl font-medium">Submit your session</div>
      <hr className="h-px mx-5 bg-gray-200 border-0 dark:bg-gray-400" />
      <div className="p-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-row justify-center items-center my-5">
            <label className="text-sm font-semibold text-center w-[15%]">
              Session Title
              <span className="text-red-500 font-bold">*</span>
            </label>
            <div className="flex flex-col w-full">
              <input
                type="text"
                {...register("sessionTitle", {
                  required: "Session Title is required",
                })}
                className="border text-base w-full rounded-md p-1 focus:outline-none"
                placeholder="Session Title"
              />
              {errors.sessionTitle && (
                <div className="text-red-500 text-xs">
                  {errors.sessionTitle.message}
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-row my-6">
            <label className="text-sm font-semibold text-center w-[15%]">
              Description
              <span className="text-red-500 font-bold">*</span>
            </label>
            <div className="flex flex-col w-full">
              <textarea
                {...register("description", {
                  required: "Description is required",
                })}
                className="border text-base w-full rounded-md p-1 focus:outline-none"
                placeholder="Description"
              />
              {errors.description && (
                <div className="text-red-500 text-xs">
                  {errors.description.message}
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-row my-6">
            <label className="text-sm font-semibold text-center w-[15%]">
              Session Category
              <span className="text-red-500">*</span>
            </label>
            <div className="flex flex-col w-full">
              <select
                {...register("category", {
                  required: "Session Category is required",
                })}
                className="border w-full text-base placeholder:text-sm border-gray-300 rounded-md p-1 focus:outline-none focus:border-gray-400"
              >
                <option value="" disabled>
                  Select
                </option>
                {JSON.parse(event?.eventDetails?.sessionCategory || "[]").map(
                  (level: string) => (
                    <option key={level} value={level}>
                      {level}
                    </option>
                  )
                )}
              </select>
              {errors.category && (
                <div className="text-red-500 text-xs">
                  {errors.category.message}
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-row my-6">
            <label className="text-sm font-semibold text-center w-[15%]">
              Session Type
              <span className="text-red-500">*</span>
            </label>
            <div className="flex flex-col w-full">
              <select
                {...register("sessionType", {
                  required: "Session Type is required",
                })}
                className="border w-full text-base placeholder:text-sm border-gray-300 rounded-md p-1 focus:outline-none focus:border-gray-400"
              >
                <option value="" disabled>
                  Select
                </option>
                {JSON.parse(event?.eventDetails?.sessionType || "[]").map(
                  (level: string) => (
                    <option key={level} value={level}>
                      {level}
                    </option>
                  )
                )}
              </select>
              {errors.sessionType && (
                <div className="text-red-500 text-xs">
                  {errors.sessionType.message}
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-row my-6">
            <label className="text-sm font-semibold text-center w-[15%]">
              Session Level
              <span className="text-red-500">*</span>
            </label>
            <div className="flex flex-col w-full">
              <select
                {...register("sessionLevel", {
                  required: "Session Level is required",
                })}
                className="border w-full text-base placeholder:text-sm border-gray-300 rounded-md p-1 focus:outline-none focus:border-gray-400"
              >
                <option value="" disabled>
                  Select
                </option>
                {JSON.parse(event?.eventDetails?.sessionLevel || "[]").map(
                  (level: string) => (
                    <option key={level} value={level}>
                      {level}
                    </option>
                  )
                )}
              </select>
              {errors.sessionLevel && (
                <div className="text-red-500 text-xs">
                  {errors.sessionLevel.message}
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-row my-6">
            <label className="text-sm font-semibold text-center w-[15%]">
              Languages
              <span className="text-red-500">*</span>
            </label>
            <div className="flex flex-col w-full">
              <select
                {...register("languages", {
                  required: "Languages are required",
                })}
                className="border w-full text-base placeholder:text-sm border-gray-300 rounded-md p-1 focus:outline-none focus:border-gray-400"
              >
                <option value="" disabled>
                  Select
                </option>
                {JSON.parse(event?.eventDetails?.languages || "[]").map(
                  (level: string) => (
                    <option key={level} value={level}>
                      {level}
                    </option>
                  )
                )}
              </select>
              {errors.languages && (
                <div className="text-red-500 text-xs">
                  {errors.languages.message}
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-row my-6">
            <label className="text-sm font-semibold text-center w-[15%]">
              Tech Stack
              <span className="text-red-500">*</span>
            </label>
            <div className="flex flex-col w-full">
              <select
                {...register("techStack", {
                  required: "Tech Stack is required",
                })}
                className="border w-full text-base placeholder:text-sm border-gray-300 rounded-md p-1 focus:outline-none focus:border-gray-400"
              >
                <option value="" disabled>
                  Select
                </option>
                {JSON.parse(event?.eventDetails?.techStack || "[]").map(
                  (level: string) => (
                    <option key={level} value={level}>
                      {level}
                    </option>
                  )
                )}
              </select>
              {errors.techStack && (
                <div className="text-red-500 text-xs">
                  {errors.techStack.message}
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-row my-6">
            <label className="text-sm font-semibold text-center w-[15%]">
              Additional Notes
            </label>
            <div className="flex flex-col w-full">
              <textarea
                {...register("additionalNotes")}
                className="border text-base w-full rounded-md p-1 focus:outline-none"
                placeholder="Additional Notes"
              />
            </div>
          </div>

          <div className="flex flex-row my-6">
            <label className="text-sm font-semibold text-center w-[15%]">
              First Name <span className="text-red-500 font-bold">*</span>
            </label>
            <div className="flex flex-col w-full">
              <input
                type="text"
                {...register("speakerFirstName", {
                  required: "First Name is required",
                })}
                className="border text-base w-full rounded-md p-1 focus:outline-none"
                placeholder="Speaker Last Name"
              />
              {errors.speakerFirstName && (
                <div className="text-red-500 text-xs">
                  {errors.speakerFirstName.message}
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-row my-6">
            <label className="text-sm font-semibold text-center w-[15%]">
              Last Name <span className="text-red-500 font-bold">*</span>
            </label>
            <div className="flex flex-col w-full">
              <input
                type="text"
                {...register("speakerLastName", {
                  required: "Last Name is required",
                })}
                className="border text-base w-full rounded-md p-1 focus:outline-none"
                placeholder="Speaker Last Name"
              />
              {errors.speakerLastName && (
                <div className="text-red-500 text-xs">
                  {errors.speakerLastName.message}
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-row my-6">
            <label className="text-sm font-semibold text-center w-[15%]">
              Email <span className="text-red-500 font-bold">*</span>
            </label>
            <div className="flex flex-col w-full">
              <input
                type="text"
                {...register("email", {
                  required: {
                    value: true,
                    message: "Email is required",
                  },
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/,
                    message: "Invalid Email",
                  },
                })}
                className="border text-base w-full rounded-md p-1 focus:outline-none"
                placeholder="Email Address"
              />
              {errors.email?.message ? (
                <small className="text-red-600  my-2">
                  {errors?.email?.message.toString()}
                </small>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="flex flex-row my-6">
            <label className="text-sm font-semibold text-center w-[15%]">
              Password <span className="text-red-500 font-bold">*</span>
            </label>
            <div className="flex flex-col w-full">
              <input
                type="text"
                {...register("password", {
                  required: "Password is required",
                })}
                className="border text-base w-full rounded-md p-1 focus:outline-none"
                placeholder="Password"
              />
              {errors.password && (
                <div className="text-red-500 text-xs">
                  {errors.password.message}
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-row justify-center items-center my-5">
            <label className="text-sm font-semibold text-center w-[15%]">
              Designation
              <span className="text-red-500 font-bold">*</span>
            </label>
            <div className="flex flex-col w-full">
              <input
                type="text"
                {...register("designation", {
                  required: "Designation is required",
                })}
                className="border text-base w-full rounded-md p-1 focus:outline-none"
                placeholder="Designation"
              />
              {errors.designation && (
                <div className="text-red-500 text-xs">
                  {errors.designation.message}
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-row justify-center items-center my-5">
            <label className="text-sm font-semibold text-center w-[15%]">
              Area of Expertise
            </label>
            <div className="flex flex-col w-full">
              <input
                type="text"
                {...register("areaofExpertise")}
                className="border text-base w-full rounded-md p-1 focus:outline-none"
                placeholder="Area of Expertise"
              />
            </div>
          </div>
          <div className="flex flex-row my-6">
            <label className="text-sm font-semibold text-center w-[15%]">
              Biography
              <span className="text-red-500 font-bold">*</span>
            </label>
            <div className="flex flex-col w-full">
              <textarea
                {...register("speakerBiography", {
                  required: "Biography is required"
                })}
                className="border text-base w-full rounded-md p-1 focus:outline-none"
                placeholder="Speaker Biography"
              />
              {errors.speakerBiography && (
                <div className="text-red-500 text-xs">
                  {errors.speakerBiography.message}
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-row my-6">
            <label className="text-sm font-semibold text-center w-[15%]">
              Tagline
              <span className="text-red-500 font-bold">*</span>
            </label>
            <div className="flex flex-col w-full">
              <input
                {...register("tagline", {
                  required: "Tagline is required",
                })}
                className="border text-base w-full rounded-md p-1 focus:outline-none"
                placeholder="Tagline"
              />
              {errors.tagline && (
                <div className="text-red-500 text-xs">
                  {errors.tagline.message}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-row justify-center items-center my-5">
            <label className="text-sm font-semibold text-center w-[15%]">
              Location
            </label>
            <div className="flex flex-col w-full">
              <input
                type="text"
                {...register("location")}
                className="border text-base w-full rounded-md p-1 focus:outline-none"
                placeholder="Location"
              />
            </div>
          </div>
          <div className="flex flex-row justify-center items-center my-5">
            <label className="text-sm font-semibold text-center w-[15%]">
              Topics
            </label>
            <div className="flex flex-col w-full">
              <input
                type="text"
                {...register("topics")}
                className="border text-base w-full rounded-md p-1 focus:outline-none"
                placeholder="Topics"
              />
            </div>
          </div>
          <div className="flex flex-row    flex-grow my-6">
            <label className="text-sm font-semibold text-center w-[15%]">
              Speaker Photo
            </label>
            <div className="p-2 bg-[#f3f3f4] w-full">
              <div className="flex flex-row justify-between items-start">
                <div className="p-2 mb-2">
                  {speakerPhoto ? (
                    <img
                      src={speakerPhoto}
                      alt="Speaker Photo Preview"
                      style={{ maxWidth: "100%", maxHeight: "200px" }}
                    />
                  ) : null}
                </div>
                {speakerPhoto ? (
                  <button
                    type="button"
                    onClick={() => setSpeakerPhoto(null)}
                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  >
                    <span className="sr-only">Close menu</span>
                    <svg
                      className="h-3 w-3"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="red"
                      viewBox="0 0 24 24"
                      stroke="black"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                ) : null}
              </div>
              <div>
                <input
                  type="file"
                  name="speakerPhoto"
                  ref={speakerPhotoRef}
                  onChange={(e) =>
                    handleImageChanges(e, "SpeakerPhotos", "speakerPhoto")
                  }
                  style={{ display: "none" }}
                />
                <div
                  className="my-4 mx-2 bg-white p-2 text-center cursor-pointer"
                  onClick={() => speakerPhotoRef.current?.click()}
                >
                  Choose file or Click here to Upload
                </div>
              </div>
              {errors["speakerPhoto"] && (
                <div className="text-red-500 text-xs">
                  {errors["speakerPhoto"]}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-row justify-center items-center my-5">
            <label className="text-sm font-semibold text-center w-[15%]">
              LinkedIn
            </label>
            <div className="flex flex-col w-full">
              <input
                type="text"
                {...register("linkedIn")}
                className="border text-base w-full rounded-md p-1 focus:outline-none"
                placeholder="LinkedIn"
              />
            </div>
          </div>
          <div className="flex flex-row justify-center items-center my-5">
            <label className="text-sm font-semibold text-center w-[15%]">
              Twitter
            </label>
            <div className="flex flex-col w-full">
              <input
                type="text"
                {...register("twitter")}
                className="border text-base w-full rounded-md p-1 focus:outline-none"
                placeholder="Twitter"
              />
            </div>
          </div>
          <div className="flex flex-row justify-center items-center my-5">
            <label className="text-sm font-semibold text-center w-[15%]">
              Facebook
            </label>
            <div className="flex flex-col w-full">
              <input
                type="text"
                {...register("facebook")}
                className="border text-base w-full rounded-md p-1 focus:outline-none"
                placeholder="Facebook"
              />
            </div>
          </div>
          <div className="flex flex-row justify-center items-center my-5">
            <label className="text-sm font-semibold text-center w-[15%]">
              Instagram
            </label>
            <div className="flex flex-col w-full">
              <input
                type="text"
                {...register("instagram")}
                className="border text-base w-full rounded-md p-1 focus:outline-none"
                placeholder="Instagram"
              />
            </div>
          </div>
          <div className="flex flex-row justify-center items-center my-5">
            <label className="text-sm font-semibold text-center w-[15%]">
              Blog
            </label>
            <div className="flex flex-col w-full">
              <input
                type="text"
                {...register("blog")}
                className="border text-base w-full rounded-md p-1 focus:outline-none"
                placeholder="Blog"
              />
            </div>
          </div>
          <div className="flex flex-row justify-center items-center my-5">
            <label className="text-sm font-semibold text-center w-[15%]">
              Company Website
            </label>
            <div className="flex flex-col w-full">
              <input
                type="text"
                {...register("companyWebsite")}
                className="border text-base w-full rounded-md p-1 focus:outline-none"
                placeholder="Company Website"
              />
            </div>
          </div>

          <div className="flex flex-row my-6">
            <label className="text-sm font-semibold text-center w-[15%]">
              Speaker Type
              <span className="text-red-500 font-bold">*</span>
            </label>
            <div className="flex flex-col w-full">
              <select
                {...register("speakerType", {
                  required: "Speaker Type is required",
                })}
                className="border w-full text-base placeholder:text-sm border-gray-300 rounded-md p-1 focus:outline-none focus:border-gray-400"
              >
                <option value="" disabled>
                  Select
                </option>
                {JSON.parse(event?.eventDetails?.speakerType || "[]").map(
                  (level: string) => (
                    <option key={level} value={level}>
                      {level}
                    </option>
                  )
                )}
              </select>
              {errors.speakerType && (
                <div className="text-red-500 text-xs">
                  {errors.speakerType.message}
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-row my-6">
            <label className="text-sm font-semibold text-center w-[15%]">
              Previous Talks
              <span className="text-red-500 font-bold">*</span>
            </label>
            <div className="flex flex-col w-full">
              <textarea
                {...register("previousTalks",{
                  required:"Previous talks is required"
                })}
                className="border text-base w-full rounded-md p-1 focus:outline-none"
                placeholder="Previous Talks"
              />
              {errors.previousTalks && (
                <div className="text-red-500 text-xs">
                  {errors.previousTalks.message}
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-row my-6">
            <label className="text-sm font-semibold text-center w-[15%]">
              Company Name
              <span className="text-red-500 font-bold">*</span>
            </label>
            <div className="flex flex-col w-full">
              <input
                type="text"
                {...register("companyName",
                  {
                    required: "Company name is required"
                  }
                )}
                className="border text-base w-full rounded-md p-1 focus:outline-none"
                placeholder="Company Name"
              />
              {errors.companyName && (
                <div className="text-red-500 text-xs">
                  {errors.companyName.message}
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-row    flex-grow my-6">
            <label className="text-sm font-semibold text-center w-[15%]">
              Company logo <span className="text-red-500"> *</span>
            </label>
            <div className="p-2 bg-[#f3f3f4] w-full">
              <div className="flex flex-row justify-between items-start">
                <div className="p-2 mb-2">
                  {speakerCompanyLogo ? (
                    <img
                      src={speakerCompanyLogo}
                      alt="Preview"
                      style={{ maxWidth: "100%", maxHeight: "200px" }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                {speakerCompanyLogo ? (
                  <button
                    type="button"
                    onClick={() => {
                      setSpeakerCompanyLogo(null);
                    }}
                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  >
                    <span className="sr-only">Close menu</span>
                    <svg
                      className="h-3 w-3"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="red"
                      viewBox="0 0 24 24"
                      stroke="black"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                ) : (
                  <></>
                )}
              </div>
              <div>
                <input
                  type="file"
                  name="companyLogo"
                  ref={companyPhotoRef}
                  onChange={(e) =>
                    handleImageChanges(e, "CompanyPhotos", "companyLogo")
                  }
                  style={{ display: "none" }}
                />
                <div
                  className="my-4 mx-2 bg-white p-2 text-center cursor-pointer"
                  onClick={() => {
                    companyPhotoRef.current.click();
                  }}
                >
                  Choose file or Click here to Upload
                </div>
              </div>
              {errors["companyLogo"] && (
                <div className="text-red-500 text-xs">
                  {errors["companyLogo"]}
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-row my-6">
            <label className="text-sm font-semibold text-center w-[15%]">
              Shirt Size
            </label>
            <div className="flex flex-col w-full">
              <select
                {...register("shirtSize")}
                className="border w-full text-base placeholder:text-sm border-gray-300 rounded-md p-1 focus:outline-none focus:border-gray-400"
              >
                <option value="" disabled>
                  Select
                </option>
                {JSON.parse(event?.eventDetails?.tShirtSizes || "[]").map(
                  (level: string) => (
                    <option key={level} value={level}>
                      {level}
                    </option>
                  )
                )}
              </select>
              {errors.shirtSize && (
                <div className="text-red-500 text-xs">
                  {errors.shirtSize.message}
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-row justify-end">
            <button
              type="submit"
              className={`px-16 py-2  rounded-full bg-[#625ffb] text-white`}
            >
              Submit
            </button>
          </div>
        </form>
        {loading && (
            <div className="fixed inset-0 bg-black bg-opacity-80 z-10">
              <div
                role="status"
                className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
              >
                <HashLoader color={"white"} loading={loading} size={50} />
              </div>
            </div>
          )}
        <ToastContainer />
      </div>
    </div>
  );
};

export default NominateAsOrganizer;
