import React, { FC, useContext, useState } from "react";
import "./CFSIntergrate.scss";
import CfsSubmissionFeilds from "../CFSSubmissionFeilds/CFSSubmissionFeilds";
import { TagsInput } from "react-tag-input-component";
import { SessionCategory } from "./CFSIntegrateService";
import { EventContext } from "../EditEvent/EditEvent";
import { setNestedValue } from "../../../helper/ValidateForm";

interface CfsIntergrateProps {}
interface Field {
  id: number;
  title: string;
  type: string;
  inputType: string;
  value: string[];
  required: boolean;
}

const CfsIntergrate: FC<CfsIntergrateProps> = () => {
  const { event, setEvent, errors, setErrors } = useContext(EventContext);
  const [sessionFields, setSessionFields] = useState<Field[]>([]);
  const [speakerFields, setSpeakerFields] = useState<Field[]>([]);
  const [checkedValues, setCheckedValues] = useState(["sj"]);
  const [sessionFormat, setSessionFormat] = useState([]);
  const [track, setTrack] = useState([]);
  const [level, setLevel] = useState([]);
  const [language, setLanguage] = useState([]);

  const combinedFields = {
    sessionFields,
    speakerFields,
    checkedValues,
  };

  const mandatoryFields = ["eventDetails.category"];
  const speakerOptions = [
    { value: 0, label: "Don't allow co-speakers" },
    ...Array.from({ length: 9 }, (_, i) => ({
      value: i + 1,
      label: `Allow up to ${i + 1} co-speaker${i > 0 ? "s" : ""}`,
    })),
  ];

  const handleInputTagsChange = (name, value) => {
    console.log(name, value);
    setEvent((prevEvent) => {
      const updatedEvent = setNestedValue(
        prevEvent,
        name,
        JSON.stringify(value)
      );
      console.log(updatedEvent);
      return updatedEvent;
    });
    if (mandatoryFields.includes(name)) {
      if (value === null || value === undefined || value === "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Field is required",
        }));
        console.log(errors);
      } else {
        setErrors((prevErrors) => {
          const { [name]: _, ...rest } = prevErrors;
          return rest;
        });
        console.log(errors);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setEvent((prevEvent) => {
      const updatedEvent = setNestedValue(prevEvent, name, value);
      console.log(updatedEvent);
      return updatedEvent;
    });
    if (mandatoryFields.includes(name)) {
      if (value === null || value === undefined || value === "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Field is required",
        }));
        console.log(errors);
      } else {
        setErrors((prevErrors) => {
          const { [name]: _, ...rest } = prevErrors;
          return rest;
        });
        console.log(errors);
      }
    }
  };

  const socialMediaOptions = [
    "Twitter",
    "LinkedIn",
    "Facebook",
    "Instagram",
    "Blog",
    "Company Website",
  ];
  const handleSessionFieldsChange = (updatedFields: Field[]) => {
    setSessionFields(updatedFields);
  };
  const handleSpeakerFieldsChange = (updatedFields: Field[]) => {
    setSpeakerFields(updatedFields);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedValues((prevValues) =>
      checked
        ? [...prevValues, name]
        : prevValues.filter((value) => value !== name)
    );
  };
  return (
    <div className="flex">
      <div className="flex justify-between w-full p-2">
        <div className="w-1/2 p-2">
          <div className="flex flex-row justify-between items-center my-5">
            <label className="text-sm font-semibold w-1/3">
              Session Category
            </label>
            <div className="w-full">
              <TagsInput
                value={JSON.parse(event?.eventDetails?.sessionCategory || "[]")}
                name="eventDetails.sessionCategory"
                onChange={(e) =>
                  handleInputTagsChange("eventDetails.sessionCategory", e)
                }
                classNames={{ tag: "tag-cls", input: "input-cls" }}
                placeHolder="add options here"
              />
            </div>
          </div>
          <div className="flex flex-row justify-between items-center my-5">
            <label className="text-sm font-semibold w-1/3">Session type</label>
            <div className="w-full">
              <TagsInput
                value={JSON.parse(event?.eventDetails?.sessionType || "[]")}
                name="eventDetails.sessionType"
                onChange={(e) =>
                  handleInputTagsChange("eventDetails.sessionType", e)
                }
                classNames={{ tag: "tag-cls", input: "input-cls" }}
                placeHolder="add options here"
              />
            </div>
          </div>
          {/* <div className="flex flex-row justify-between items-center my-5">
                <label className="text-sm font-semibold w-1/3">Track</label>
                <div className="w-full">
                  <TagsInput
                    value={JSON.parse(event?.eventDetails?.trackType || "[]")}
                    name="eventDetails.trackType"
                    onChange={(e) =>
                      handleInputTagsChange("eventDetails.trackType", e)
                    }
                    classNames={{ tag: "tag-cls", input: "input-cls" }}
                    placeHolder="add options here"
                  />
                </div>
              </div> */}
          <div className="flex flex-row justify-between items-center my-5">
            <label className="text-sm font-semibold w-1/3">Level</label>
            <div className="w-full">
              <TagsInput
                value={JSON.parse(event?.eventDetails?.sessionLevel || "[]")}
                name="eventDetails.sessionLevel"
                onChange={(e) =>
                  handleInputTagsChange("eventDetails.sessionLevel", e)
                }
                classNames={{ tag: "tag-cls", input: "input-cls" }}
                placeHolder="add options here"
              />
            </div>
          </div>
          <div className="flex flex-row justify-between items-center my-5">
            <label className="text-sm font-semibold w-1/3">Tech Stack</label>
            <div className="w-full">
              <TagsInput
                value={JSON.parse(event?.eventDetails?.techStack || "[]")}
                name="eventDetails.techStack"
                onChange={(e) =>
                  handleInputTagsChange("eventDetails.techStack", e)
                }
                classNames={{ tag: "tag-cls", input: "input-cls" }}
                placeHolder="add options here"
              />
            </div>
          </div>
        </div>
        <div className="w-1/2">
          <div className="flex flex-row justify-between items-center my-5">
            <label className="text-sm font-semibold w-1/3">Language</label>
            <div className="w-full">
              <TagsInput
                value={JSON.parse(event?.eventDetails?.languages || "[]")}
                name="eventDetails.languages"
                onChange={(e) =>
                  handleInputTagsChange("eventDetails.languages", e)
                }
                classNames={{ tag: "tag-cls", input: "input-cls" }}
                placeHolder="add options here"
              />
            </div>
          </div>
          <div className="flex flex-row justify-between items-center my-5">
            <label className="text-sm font-semibold w-1/3">Shirt Sizes</label>
            <div className="w-full">
              <TagsInput
                value={JSON.parse(event?.eventDetails?.tShirtSizes || "[]")}
                name="eventDetails.tShirtSizes"
                onChange={(e) =>
                  handleInputTagsChange("eventDetails.tShirtSizes", e)
                }
                classNames={{ tag: "tag-cls", input: "input-cls" }}
                placeHolder="add options here"
              />
            </div>
          </div>
          <div className="flex flex-row justify-between items-center my-5">
            <label className="text-sm font-semibold w-1/3">Speaker Type</label>
            <div className="w-full">
              <TagsInput
                value={JSON.parse(event?.eventDetails?.speakerType || "[]")}
                name="eventDetails.speakerType"
                onChange={(e) =>
                  handleInputTagsChange("eventDetails.speakerType", e)
                }
                classNames={{ tag: "tag-cls", input: "input-cls" }}
                placeHolder="add options here"
              />
            </div>
          </div>
          <div className="flex flex-row justify-between items-center my-5">
            <label className="text-sm font-semibold w-1/3">Co-Speakers</label>
            <div className="w-full">
              <select
                name="eventDetails.coSpeakerCount"
                value={event?.eventDetails?.coSpeakerCount}
                onChange={(e)=>handleChange(e)}
                className="border text-base placeholder:text-sm border-gray-300 rounded-md p-1 focus:outline-none focus:border-gray-400 w-full"
              >
                <option value="" disabled>
                  Select speaker type
                </option>
                {speakerOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {/* <div className="flex justify-center">
              <CfsSubmissionFeilds onFieldsChange={handleSessionFieldsChange} />
            </div> */}
        </div>
      </div>

      {/* <div className="w-1/2 ">
            <div className="bg-[#e4e4e4] text-[#2f4050] p-2 font-medium rounded-md">
              Speaker feilds
            </div>

            <div className="flex flex-row justify-center items-center  flex-grow my-5">
              <label className="text-sm font-semibold w-1/3">First Name</label>
              <input
                type="text"
                disabled
                className="text-gray-700 border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-blue-700"
                placeholder="Predefined. Required"
              />
            </div>
            <div className="flex flex-row justify-center items-center  flex-grow my-5">
              <label className="text-sm font-semibold w-1/3">Last Name</label>
              <input
                type="text"
                disabled
                className="text-gray-700 border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-blue-700"
                placeholder="Predefined. Required"
              />
            </div>
            <div className="flex flex-row justify-center items-center  flex-grow my-5">
              <label className="text-sm font-semibold w-1/3">Email</label>
              <input
                type="text"
                disabled
                className="text-gray-700 border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-blue-700"
                placeholder="Predefined. Required"
              />
            </div>
            <div className="flex flex-row justify-center items-center flex-grow my-5">
              <label className="text-sm font-semibold w-1/3">Tagline</label>
              <input
                type="text"
                disabled
                className="text-gray-700 border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-blue-700"
                placeholder="Predefined. Required"
              />
            </div>
            <div className="flex flex-row justify-center items-center  flex-grow my-5">
              <label className="text-sm font-semibold w-1/3">
                Speaker Biography
              </label>
              <input
                type="text"
                disabled
                className="text-gray-700 border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-blue-700"
                placeholder="Predefined. Required"
              />
            </div>
            <div className="flex flex-row justify-center items-center  flex-grow my-5">
              <label className="text-sm font-semibold w-1/3">
                Speaker Photo
              </label>
              <input
                type="text"
                disabled
                className="text-gray-700 border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-blue-700"
                placeholder="Predefined. Required"
              />
            </div>
            <div>
              <label>
                Optionally ask for:
                <br />
                {socialMediaOptions.map((option) => (
                  <div key={option} className="ml-36 my-2 ">
                    <input
                      type="checkbox"
                      name={option.toLowerCase().replace(/\s+/g, "_")}
                      onChange={handleCheckboxChange}
                    />
                    {option}
                  </div>
                ))}
              </label>
            </div>
            <div>
              <CfsSubmissionFeilds onFieldsChange={handleSpeakerFieldsChange} />
            </div>
          </div> */}
    </div>
  );
};

export default CfsIntergrate;
