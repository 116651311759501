import React, { useContext, useEffect, useState } from "react";

// components

import CardSettings from "./ProfileCard";
import TaglineBio from "./TaglineBio/TaglineBio";
import { HashLoader } from "react-spinners";
import SocialMedia from "./SocialMedia/SocialMedia";
import { converUserModel, validateForm } from "./UserProfileService";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import UserService from "../../../services/userService";
import { toast, ToastContainer } from "react-toastify";
import { Role } from "../../../enums/Roles";
import SideBar from "../../Admin/SideBar/SideBar";
import DashboardHeader from "../../Admin/DashboardHeader/DashboardHeader";

interface TabProps {
  id: string;
  label: string;
  switchTab: number;
  isActive: boolean;
}

export const UserContext = React.createContext(null);

export default function UserProfile() {
  const [activeTab, setActiveTab] = useState<number>(1);
  const [showSidebar, setShowSidebar] = useState(true);
  const [loading, setLoading] = useState(true);
  const [curruntUser, setCurruntUser] = useState(null);
  const [user, setUser] = useState<any>();
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);

  useEffect(() => {
    setLoading(true);
    const fetchUser = async () => {
      try {
        const response = await UserService.getUserbyUserId(auth?.userId);
        if (response == null) {
          setLoading(false);
          // navigate("/not-found");
        } else {
          setUser(response);
          setCurruntUser(response);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        toast.error("Error in fetching User data");
        // navigate("/not-found");
      }
      setLoading(false);
    };
    fetchUser();
  }, [auth?.userId]);

  const handleTabClick = (tabNumber: number) => {
    setActiveTab(tabNumber);
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const formErrors = await validateForm(user, errors);
    console.log(formErrors);
    setErrors(formErrors);
    if (Object.keys(formErrors).length === 0) {
      var userData = converUserModel(curruntUser, user);
      var updatedUser = await UserService.updateUser(userData);
      if (updatedUser) {
        showSuccessToast();
        setLoading(false);
      } else {
        setLoading(false);
        showErrorToast();
      }
    } else {
      setLoading(false);
      toast.error("Form has errors !", { autoClose: 10000 });
    }
  };

  const showSuccessToast = () =>
    toast.success("User Updated Successfully", { autoClose: 2000 });
  const showErrorToast = () =>
    toast.error("Something went wrong !", { autoClose: 10000 });

  const Tab: React.FC<TabProps> = ({ id, label, switchTab, isActive }) => {
    return (
      <li className={`me-2 ${activeTab}`} role="presentation">
        <button
          className={`inline-block p-4 ${isActive && "bg-white"}`}
          id={`${id}-tab`}
          data-tabs-target={`#${id}`}
          type="button"
          role="tab"
          aria-controls={id}
          aria-selected="false"
          onClick={() => handleTabClick(switchTab)}
        >
          {label}
        </button>
      </li>
    );
  };

  return (
    <UserContext.Provider value={{ user, setUser, errors, setErrors }}>
      <div className="flex">
        {(auth?.role === Role.Admin || auth?.role === Role.User) && (
          <div
            className={` ${
              showSidebar &&
              (auth?.role === Role.Admin || auth?.role === Role.User)
                ? "md:w-[15%] w-[100%]"
                : "w-[0%]"
            }`}
          >
            {showSidebar &&
              (auth?.role === Role.Admin || auth?.role === Role.User) && (
                <div className="sidebar">
                  <SideBar onCloseSidebar={toggleSidebar} />
                </div>
              )}
          </div>
        )}

        <div
          className={` ${
            showSidebar &&
            (auth?.role === Role.Admin || auth?.role === Role.User)
              ? "w-[0%] md:w-[85%]"
              : "md:w-[100%]"
          }`}
          data-testid="Layout"
        >
          <div className="flex flex-col h-screen profile-div overflow-auto">
            <div className={`row`}>
              <DashboardHeader onCloseSidebar={toggleSidebar} />

              <div className="flex flex-row justify-between my-3 p-3 bg-white">
                <div className="p-2 text-2xl font-bold">
                  {(user?.firstName ?? "") + " " + (user?.lastName ?? "")}
                </div>
                <div></div>
              </div>

              <div className="flex flex-row justify-end p-3 gap-4">
                <button
                  className={`px-7 py-2  rounded-full bg-[#625ffb] text-white`}
                  type="button"
                  role="tab"
                  onClick={handleSubmit}
                  aria-selected="false"
                >
                  <span>Save Changes</span>
                </button>
              </div>

              <div className="p-5">
                <div className="">
                  <ul
                    className="flex flex-wrap -mb-px text-sm font-medium text-center"
                    id="default-tab"
                    data-tabs-toggle="#default-tab-content"
                    role="tablist"
                  >
                    <div
                      className={`flex flex-row  items-center px-4 ${
                        activeTab === 1 ? "bg-white" : ""
                      }`}
                      onClick={() => handleTabClick(1)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>

                      <Tab
                        id="edit-event"
                        label="Profile"
                        switchTab={1}
                        isActive={activeTab === 1}
                      />
                    </div>
                    <div
                      className={`flex flex-row  items-center px-4 ${
                        activeTab === 2 ? "bg-white" : ""
                      }`}
                      onClick={() => handleTabClick(2)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 1 1 0-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 0 1-1.44-4.282m3.102.069a18.03 18.03 0 0 1-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 0 1 8.835 2.535M10.34 6.66a23.847 23.847 0 0 0 8.835-2.535m0 0A23.74 23.74 0 0 0 18.795 3m.38 1.125a23.91 23.91 0 0 1 1.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 0 0 1.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 0 1 0 3.46"
                        />
                      </svg>
                      <Tab
                        id="call-for-speakers"
                        label="Tagline & Bio"
                        switchTab={2}
                        isActive={activeTab === 2}
                      />
                    </div>
                    <div
                      className={`flex flex-row  items-center px-4 ${
                        activeTab === 3 ? "bg-white" : ""
                      }`}
                      onClick={() => handleTabClick(3)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 1 1 0-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 0 1-1.44-4.282m3.102.069a18.03 18.03 0 0 1-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 0 1 8.835 2.535M10.34 6.66a23.847 23.847 0 0 0 8.835-2.535m0 0A23.74 23.74 0 0 0 18.795 3m.38 1.125a23.91 23.91 0 0 1 1.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 0 0 1.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 0 1 0 3.46"
                        />
                      </svg>
                      <Tab
                        id="social-media"
                        label="Social Media"
                        switchTab={3}
                        isActive={activeTab === 3}
                      />
                    </div>
                  </ul>
                </div>
                <div className="w-full my-3">
                  {activeTab === 1 && (
                    <div className="bg-white md:p-5">
                      <CardSettings />
                    </div>
                  )}
                  {activeTab === 2 && (
                    <div className="bg-white md:p-5">
                      <TaglineBio />
                    </div>
                  )}
                  {activeTab === 3 && (
                    <div className="bg-white md:p-5">
                      <SocialMedia />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
        {loading && (
          <div className="absolute inset-0 bg-black bg-opacity-80 z-10">
            <div
              role="status"
              className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
            >
              <HashLoader color={"white"} loading={loading} size={50} />
            </div>
          </div>
        )}
      </div>
    </UserContext.Provider>
  );
}
