import { FC, useContext, useEffect, useRef, useState } from "react";
import "./DetailSpeaker.scss";
import { useNavigate, useParams } from "react-router-dom";
import SpeakerService from "../../../../services/speakerService";
import { HashLoader } from "react-spinners";
import { useForm } from "react-hook-form";
import BlobService from "../../../../services/blobService";
import { AuthContext } from "../../../../context/AuthContext";
import { toast, ToastContainer } from "react-toastify";

interface DetailSpeakerProps {}

const DetailSpeaker: FC<DetailSpeakerProps> = () => {
  const { speakerId } = useParams();
  const [loading, setLoading] = useState(false);
  const [speakerData, setSpeakerData] = useState<any>(null);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const companyPhotoRef = useRef(null);

  useEffect(() => {
    fetchSpeakerData();
  }, [speakerId]);
  const {
    register: registerUpdate,
    handleSubmit: handleSubmitUpdate,
    formState: { errors: errorsUpdate },
    setValue: setValueUpdate,
    setError: setErrorsUpdate,
    clearErrors: clearErrors,
    watch,
  } = useForm();

  const companyLogo = watch("companyLogo");
  const speakerImage = watch("photoPath");

  const [selectedStatus, setSelectedStatus] = useState({
    Nominated: true,
    Accepted: true,
    "Accept Queue": true,
    "Decline Queue": true,
    Declined: true,
  });

  const fetchSpeakerData = async () => {
    setLoading(true);
    try {
      const speaker = await SpeakerService.getSpeakerById(speakerId);
      setSpeakerData(speaker);
      setFormValues(speaker);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching session data:", error);
    }
  };

  const setFormValues = (data: any) => {
    setValueUpdate("firstName", data.users.firstName);
    setValueUpdate("lastName", data.users.lastName);
    setValueUpdate("emailId", data.users.email);
    setValueUpdate("tagLine", data.tagLine);
    setValueUpdate("biography", data.biography);
    setValueUpdate("tShirtSize", data.tShirtSize);
    setValueUpdate("speakersType", data.speakersType);
    setValueUpdate("companyLogo", data.companyLogo);
    setValueUpdate("companyName", data.companyName);
    setValueUpdate("photoPath", data.photoPath);
  };

  const handleImageChanges = async (e, fieldName, folder) => {
    if (
      e.target.name != null &&
      e.target.files[0] != null &&
      e.target.files[0] != undefined
    ) {
      var name = e.target.name;
      console.log(name);
      var result = await BlobService.uploadFile(
        e.target.files[0],
        speakerData.event?.eventCallForSpeakers?.slugURL,
        folder
      );
      console.log(result);
      setValueUpdate(fieldName, null);
      setValueUpdate(fieldName, result);
      clearErrors(fieldName);
      return result;
    }
  };

  const statusMapping = {
    0: "Nominated",
    1: "Accepted",
    2: "Accept Queue",
    3: "Decline Queue",
    4: "Declined",
  };

  const toggleIsUpdate = () => {
    setIsUpdateOpen(!isUpdateOpen);
  };

  const onUpdateSpeaker = async (data: any) => {
    try {
      setLoading(true);
      const updatedSpeakerData = await convertToSpeakerData(data);
      console.log(data);
      var speakerUpdated = await SpeakerService.updateSpeakerDetails(
        updatedSpeakerData
      );
      if (speakerUpdated) {
        setLoading(false);
        toast.success("Speaker Updated Successfully", { autoClose: 2000 });
        fetchSpeakerData();
        toggleIsUpdate();
      } else {
        setLoading(false);
        toast.error("Something went wrong !", { autoClose: 10000 });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating session:", error);
    }
  };

  const convertToSpeakerData = async (data: any) => {
    var updatedSpeakerData = speakerData;
    updatedSpeakerData.users.firstName = data?.firstName;
    updatedSpeakerData.users.lastName = data?.lastName;
    updatedSpeakerData.users.email = data?.emailId;
    updatedSpeakerData.users.normalizedEmail = data?.emailId.toUpperCase();
    updatedSpeakerData.users.userName = data?.emailId;
    updatedSpeakerData.users.normalizedUserName = data?.emailId.toUpperCase();
    updatedSpeakerData.users.modifiedBy = auth.userId;
    updatedSpeakerData.users.modifiedOn = new Date().toISOString();
    updatedSpeakerData.tagLine = data?.tagLine;
    updatedSpeakerData.biography = data?.biography;
    updatedSpeakerData.photoPath = data?.photoPath;
    updatedSpeakerData.tShirtSize = data?.tShirtSize;
    updatedSpeakerData.speakersType = data?.speakersType;
    updatedSpeakerData.companyLogo = data?.companyLogo;
    updatedSpeakerData.companyName = data?.companyName;
    updatedSpeakerData.modifiedBy = auth.userId;
    updatedSpeakerData.modifiedOn = new Date().toISOString();
    return updatedSpeakerData;
  };

  return (
    <div>
      {loading && (
        <div className="absolute inset-0 bg-black bg-opacity-80 z-10">
          <div
            role="status"
            className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
          >
            <HashLoader color={"white"} loading={loading} size={50} />
          </div>
        </div>
      )}
      <div>
        <div className="rounded-md">
          <div className="flex gap-5 my-11 mx-6">
            <div
              className="speaker-details w-3/5 bg-white "
              data-testid="DetailSpeaker"
            >
              <div className="border-b pb-3">
                <div className="session px-5 pt-3">
                  <div className="flex flex-row justify-between">
                    <div className="font-bold">
                      {speakerData?.users?.firstName +
                        " " +
                        speakerData?.users?.lastName || "N/A"}
                    </div>
                    <button
                      className="flex justify-center items-center gap-2 bg-[#625ffb] py-1 px-3 text-white rounded-lg font-semibold"
                      onClick={() => toggleIsUpdate()}
                    >
                      <img
                        className=""
                        height={15}
                        width={20}
                        src="/session/edit.svg"
                        alt="edit"
                      />
                      Edit
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex gap-5 justify-start items-start p-7">
                <div className="flex flex-col justify-center items-center w-1/5 -mt-3">
                  <img
                    src={
                      speakerData?.photoPath ||
                      "/session/profile-placeholder-2.png"
                    }
                    className="rounded-circle"
                    alt="Profile Avatar"
                  />
                  <a href={speakerData?.photoPath} download="user_profolio.png">
                    <button className="border-2 my-3 border-[#625ffb] text-[#625ffb] rounded text-xs p-1">
                      Download
                    </button>
                  </a>
                </div>
                <div className="w-4/5">
                  <div className="font-bold text-[1.3em] text-[#625ffb]">
                    {speakerData?.users?.firstName +
                      " " +
                      speakerData?.users?.lastName || "N/A"}
                  </div>
                  <div className="font-bold text-base my-2">
                    {speakerData?.tagLine || "N/A"}
                  </div>
                  <div className="text-sm my-3">
                    {speakerData?.biography || "N/A"}
                  </div>

                  <div className="text-xs flex justify-between border-b py-2 mt-4">
                    <div className="font-bold">Speaker Type</div>
                    <div>
                      <div className=" text-[#5e5e5e] rounded-sm  p-1 mr-10  bg-[#d1dade]">
                        {speakerData?.speakersType || "N/A"}
                      </div>
                    </div>
                  </div>
                  {/* <div className="text-xs flex justify-between border-b py-2 mt-4">
                        <div className="font-bold">Session Level</div>
                        <div>
                          <div className=" text-[#5e5e5e] rounded-sm  p-1 mr-10  bg-[#d1dade]">
                            {speakerData?.sessionLevel || "N/A"}
                          </div>
                        </div>
                      </div> */}
                  <div className="text-xs flex justify-between border-b py-2">
                    <div className="font-bold">Speaker's Company Logo</div>
                    <div>
                      <div className="text-[#5e5e5e] rounded-sm  p-1 mr-10  bg-[#d1dade]">
                        <a
                          href={speakerData?.companyLogo}
                          download="organization_logo.png"
                        >
                          <button className="btn btn-primary">Download</button>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="text-xs flex justify-between border-b py-2">
                    <div className="font-bold">Company Name</div>
                    <div>
                      <div className=" text-[#5e5e5e] rounded-md p-1 mr-10  ">
                        {speakerData?.companyName || "N/A"}
                      </div>
                    </div>
                  </div>
                  <div className="text-xs flex justify-between border-b py-2">
                    <div className="font-bold">Email</div>
                    <div>
                      <div className=" text-[#5e5e5e] rounded-md p-1 mr-10  ">
                        {speakerData?.users?.email || "N/A"}
                      </div>
                    </div>
                  </div>
                  <div className="card-profile-stats flex gap-3 justify-content-center">
                    <div>
                      <span className="description cursor-pointer">
                        <a
                          target="_blank"
                          href={speakerData?.users?.userSocialMedia?.linkedIn}
                        >
                          <img src="/session/linkedIn.svg" />
                        </a>
                      </span>
                    </div>
                    <div>
                      <span className="description cursor-pointer">
                        <a
                          target="_blank"
                          href={speakerData?.users?.userSocialMedia?.instagram}
                        >
                          <img src="/session/instagram.svg" />
                        </a>
                      </span>
                    </div>
                    <div>
                      <span className="description cursor-pointer">
                        <a
                          target="_blank"
                          href={speakerData?.users?.userSocialMedia?.twitter}
                        >
                          <img src="/session/twitter.svg" />
                        </a>
                      </span>
                    </div>
                    <div>
                      <span className="description cursor-pointer">
                        <a
                          target="_blank"
                          href={speakerData?.users?.userSocialMedia?.facebook}
                        >
                          <img src="/session/facebook.svg" />
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-2/5 bg-white">
              <div className="border-b p-5 font-bold">Sessions</div>
              <div className=" m-auto ">
                <div className="card-body">
                  {speakerData && speakerData.sessions.length > 0 ? (
                    <div className="session-list   rounded-md m-3">
                      {speakerData?.sessions.map((session) => (
                        <div
                          key={session.id}
                          className="session-row my-3 border-b-2 flex items-center gap-4 p-1 w-full"
                        >
                          <div
                            onClick={() =>
                              navigate(
                                `/organizer/session-list-details/${session.id}`
                              )
                            }
                            className="cursor-pointer text-sm font-semibold text-[#625ffb] session-item w-[70%] custom-ellipsis-speaker"
                          >
                            {session.title}
                          </div>
                          <div className="session-item w-1/6">
                            <button
                              className={`custom-font-size ${
                                statusMapping[session.sessionStatus] ===
                                "Nominated"
                                  ? "badge badge-warning"
                                  : statusMapping[session.sessionStatus] ===
                                    "Accepted"
                                  ? "badge badge-success"
                                  : statusMapping[session.sessionStatus] ===
                                    "Accept Queue"
                                  ? "badge badge-info"
                                  : statusMapping[session.sessionStatus] ===
                                    "Decline Queue"
                                  ? "badge badge-danger"
                                  : "status-decline"
                              }`}
                            >
                              {statusMapping[session.sessionStatus] || "NIL"}
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="no-session">No sessions available.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {isUpdateOpen && (
          <div className="model-wrap popupscreen">
            <form
              className="modal-content"
              onSubmit={handleSubmitUpdate(onUpdateSpeaker)}
            >
              <div className="container">
                <div className="update-header p-5">
                  <div className="update-heading">Update Speaker :-</div>
                  <div>
                    <button onClick={() => toggleIsUpdate()}>
                      <img
                        className="close-btn mx-3"
                        src="/session/close.svg"
                        alt="close"
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div className="overall-container px-5">
                <div className="flex gap-4 justify-between">
                  <div className="container-feild w-1/2">
                    <div className="sub-title">First Name</div>
                    <input
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm bg-[#f3f3f4] focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      {...registerUpdate("firstName", {
                        required: "First Name is required",
                      })}
                    />
                    {errorsUpdate.firstName && (
                      <p className="text-red-500 text-xs mt-2">
                        {errorsUpdate.firstName.message.toString()}
                      </p>
                    )}
                  </div>
                  <div className="container-feild w-1/2">
                    <div className="sub-title">Last Name</div>
                    <input
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm bg-[#f3f3f4] focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      {...registerUpdate("lastName", {
                        required: "Last Name is required",
                      })}
                    />
                    {errorsUpdate.lastName && (
                      <p className="text-red-500 text-xs mt-2">
                        {errorsUpdate.lastName.message.toString()}
                      </p>
                    )}
                  </div>
                </div>
                <div className="container-feild">
                  <div className="sub-title">Email</div>
                  <input
                    readOnly={!speakerData?.users?.isClassicLogin}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm bg-[#f3f3f4] focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    {...registerUpdate("emailId", {
                      required: "Email is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: "Invalid email address",
                      },
                    })}
                  />
                  {errorsUpdate.emailId && (
                    <p className="text-red-500 text-xs mt-2">
                      {errorsUpdate.emailId.message.toString()}
                    </p>
                  )}
                </div>
                <div className="container-feild">
                  <div className="sub-title">Tagline</div>
                  <input
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm bg-[#f3f3f4] focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    {...registerUpdate("tagLine", {
                      required: "Tagline is required",
                    })}
                  />
                  {errorsUpdate.tagLine && (
                    <p className="text-red-500 text-xs mt-2">
                      {errorsUpdate.tagLine.message.toString()}
                    </p>
                  )}
                </div>
                <div className="container-feild">
                  <div className="sub-title">Biography</div>
                  <textarea
                    rows={4}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm bg-[#f3f3f4] focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    {...registerUpdate("biography", {
                      required: "Biography is required",
                    })}
                  />
                  {errorsUpdate.biography && (
                    <p className="text-red-500 text-xs mt-2">
                      {errorsUpdate.biography.message.toString()}
                    </p>
                  )}
                </div>
                <div className="flex gap-4 justify-between">
                  <div className="container-feild w-1/2">
                    <div className="sub-title">TShirt Size</div>
                    <select
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm bg-[#f3f3f4] focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      {...registerUpdate("tShirtSize")}
                    >
                      {JSON.parse(
                        speakerData.event?.eventDetails?.tShirtSizes || "[]"
                      ).map((level) => (
                        <option key={level} value={level}>
                          {level}
                        </option>
                      ))}
                    </select>
                    {errorsUpdate.tShirtSize && (
                      <p className="text-red-500 text-xs mt-2">
                        {errorsUpdate.tShirtSize.message.toString()}
                      </p>
                    )}
                  </div>
                  <div className="container-feild w-1/2">
                    <div className="sub-title">Speaker Type</div>
                    <select
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm bg-[#f3f3f4] focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      {...registerUpdate("speakersType")}
                    >
                      {JSON.parse(
                        speakerData.event?.eventDetails?.speakerType || "[]"
                      ).map((level) => (
                        <option key={level} value={level}>
                          {level}
                        </option>
                      ))}
                    </select>
                    {errorsUpdate.speakersType && (
                      <p className="text-red-500 text-xs mt-2">
                        {errorsUpdate.speakersType.message.toString()}
                      </p>
                    )}
                  </div>
                </div>
                <div className="container-feild">
                  <div className="sub-title">Company Name</div>
                  <input
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm bg-[#f3f3f4] focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    {...registerUpdate("companyName", {
                      required: "Company Name is required",
                    })}
                  />
                  {errorsUpdate.companyName && (
                    <p className="text-red-500 text-xs mt-2">
                      {errorsUpdate.companyName.message.toString()}
                    </p>
                  )}
                </div>
                <div className="flex gap-4 justify-between">
                  <div className="container-feild w-1/2">
                    <div className="sub-title">Company Logo</div>
                    <div className="p-2 bg-[#f3f3f4] w-full">
                      {companyLogo != null && (
                        <div className="flex flex-row justify-between items-start">
                          <div className="p-2 mb-2">
                            <img
                              src={companyLogo}
                              alt="Preview"
                              // onClick={handleImageChanges}
                              style={{ maxWidth: "100%", maxHeight: "200px" }}
                            />
                          </div>
                          <button
                            type="button"
                            onClick={() => {
                              setValueUpdate("companyLogo", null);
                              setErrorsUpdate("companyLogo", {
                                type: "manual",
                                message: "Company logo is required",
                              });
                            }}
                            className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                          >
                            <span className="sr-only">Close menu</span>
                            <svg
                              className="h-3 w-3"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="red"
                              viewBox="0 0 24 24"
                              stroke="black"
                              aria-hidden="true"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        </div>
                      )}
                      <div>
                        <input
                          type="file"
                          name="companyLogo"
                          ref={companyPhotoRef}
                          // onChange={(e) => {setValueUpdate("companyLogo", handleImageChanges(e, "SpeakerPhotos"));}}
                          onChange={(e) =>
                            handleImageChanges(e, "companyLogo", "CompanyLogo")
                          }
                          style={{ display: "none" }}
                        />
                        <div
                          className="my-4 mx-2 bg-white p-2 text-center cursor-pointer"
                          onClick={() => {
                            companyPhotoRef.current.click();
                          }}
                        >
                          Choose file or Click here to Upload
                        </div>
                      </div>
                    </div>
                    {errorsUpdate.companyLogo && (
                      <p className="text-red-500 text-xs mt-2">
                        {errorsUpdate.companyLogo.message.toString()}
                      </p>
                    )}
                  </div>
                  <div className="container-feild w-1/2">
                    <div className="sub-title">Speaker photo</div>
                    <div className="p-2 bg-[#f3f3f4] w-full">
                      {speakerImage != null && (
                        <div className="flex flex-row justify-between items-start">
                          <div className="p-2 mb-2">
                            <img
                              src={speakerImage}
                              alt="Preview"
                              // onClick={handleImageChanges}
                              style={{ maxWidth: "100%", maxHeight: "200px" }}
                            />
                          </div>
                          <button
                            type="button"
                            onClick={() => {
                              setValueUpdate("photoPath", null);
                              setErrorsUpdate("photoPath", {
                                type: "manual",
                                message: "Speaker photo is required",
                              });
                            }}
                            className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                          >
                            <span className="sr-only">Close menu</span>
                            <svg
                              className="h-3 w-3"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="red"
                              viewBox="0 0 24 24"
                              stroke="black"
                              aria-hidden="true"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        </div>
                      )}
                      <div>
                        <input
                          type="file"
                          name="photoPath"
                          ref={fileInputRef}
                          onChange={(e) =>
                            handleImageChanges(e, "photoPath", "SpeakerPhotos")
                          }
                          style={{ display: "none" }}
                        />
                        <div
                          className="my-4 mx-2 bg-white p-2 text-center cursor-pointer"
                          onClick={() => {
                            fileInputRef.current.click();
                          }}
                        >
                          Choose file or Click here to Upload
                        </div>
                      </div>
                    </div>
                    {errorsUpdate.photoPath && (
                      <p className="text-red-500 text-xs mt-2">
                        {errorsUpdate.photoPath.message.toString()}
                      </p>
                    )}
                  </div>
                </div>
                <div className="actions">
                  <button
                    className={`px-7 my-3 py-2  rounded-full bg-[#625ffb] text-white`}
                    type="submit"
                  >
                    Update Speaker
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default DetailSpeaker;
function setValue(arg0: string, sessionStatus: any) {
  throw new Error("Function not implemented.");
}
