import React, { FC, useState, useEffect, useRef, useContext } from "react";
import "./Header.scss";
import AuthService from "../../../services/authService";
import { AuthContext } from "../../../context/AuthContext";
import { Role } from "../../../enums/Roles";


interface HeaderProps {
  bgColor: string;
  textColor: string;
}

const Header: FC<HeaderProps> = (props) => {
  const [showBreadcrumb, setShowBreadcrumb] = useState(false);
  const authContext = useContext(AuthContext);
  const auth = authContext.auth;
  const breadcrumbRef = useRef<HTMLDivElement>(null);
  const isAuthenticated = authContext.auth?.role!=null && authContext.auth?.role!=undefined ;
  const toggleBreadcrumb = () => {
    setShowBreadcrumb(!showBreadcrumb);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      breadcrumbRef.current &&
      !breadcrumbRef.current.contains(event.target as Node)
    ) {
      setShowBreadcrumb(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const navigationItems = [
    // { id: "home-nav", text: "Home", href: "/home" },
    // { id: "about-nav", text: "About", href: "/home/#About" },
    // { id: "resource-nav", text: "Resource", href: "/home/#Resource" },
    // { id: "pricing-nav", text: "Pricing", href: "/home/#Pricing" },
    // { id: "contact-nav", text: "Contact", href: "/home/#Footer" },
  ];

  return (
    <header className="md:px-12 flex justify-between fixed bg-white w-full top-0 md:flex items-center z-20 md:pt-[1em] md:pb-[1em] md:pl-[3em] ">
      <div className="text-2xl flex justify-center items-center gap-2 font-bold">
        <img src="/login/logooratorengage.svg" />
      </div>
      <ul className="lg:flex flex-wrap ml-auto gap-16 items-center pr-[5em] hidden">
        {navigationItems.map((item) => (
          <li key={item.id} className={`nav-item ${item.id}`}>
            <a href={item.href}>{item.text}</a>
          </li>
        ))}
        <li className="nav-item login-nav font-semibold " id="login-nav">
          <button
            className={`px-7 py-4  rounded-full ${props.bgColor} ${props.textColor}`}
          >
            <a href={`${isAuthenticated ? (auth.role === Role.Admin ? "/organizer/event-list" : "/speaker/user-profile") : "/signin"}`}>
              {`${isAuthenticated ? "DASHBOARD" : "LOGIN"}`}
            </a>
          </button>
        </li>
        {/* {isAuthenticated && (
          <li className="nav-item login-nav font-semibold " id="login-nav">
          <button
            
          >
            <a href={`${isAuthenticated ? "/speaker/event-creation" : "/signin"}`}>
            <img
          className="inline-block h-12 w-12 rounded-full ring-2 ring-white"
          src="https://t3.ftcdn.net/jpg/05/53/79/60/360_F_553796090_XHrE6R9jwmBJUMo9HKl41hyHJ5gqt9oz.jpg"
          alt=""
        />
            </a>
          </button>
        </li>
        )} */}
      </ul>
      <div className="lg:hidden" ref={breadcrumbRef}>
        <button className="mr-7" onClick={toggleBreadcrumb}>
          <svg
            height="32px"
            id="Layer_1"
            version="1.1"
            viewBox="0 0 32 32"
            width="32px"
            fill="currentColor"
          >
            <path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"></path>
          </svg>
        </button>
        {showBreadcrumb && (
          <div className="popup absolute right-10 bg-white rounded-lg p-6">
            <ul className="flex flex-col items-center gap-4">
              {navigationItems.map((item) => (
                <li key={item.id} className={`nav-item ${item.id}`}>
                  <a href={item.href}>{item.text}</a>
                </li>
              ))}
              <li className="nav-item login-nav font-semibold " id="login-nav">
                <button
                  className={`px-7 py-4  rounded-full ${props.bgColor} ${props.textColor}`}
                >
                  <a href={`${isAuthenticated ? (auth.role === Role.Admin ? "/organizer/event-list" : "/speaker/user-profile") : "/signin"}`}>
                    {`${isAuthenticated ? "DASHBOARD" : "LOGIN"}`}
                  </a>
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
