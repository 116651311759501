import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

function PopupHandler() {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const Provider = urlParams.get("Provider");
  const Email = urlParams.get("Email");
  const hasFetched = useRef(false);

  useEffect(() => {
    const fetchData = async () => {
    //   if (code && !hasFetched.current) {
    //     hasFetched.current = true;
    //     const url = `https://localhost:44356/api/v1.0/Auth/GetGithubAccessToken?code=${code}`;
    //     try {
    //       const tokenResponse = await fetch(url, {
    //         method: 'GET',
    //       });

    //       if (!tokenResponse.ok) {
    //         throw new Error(`Request failed with status: ${tokenResponse.status}`);
    //       }

    //       const tokenData = await tokenResponse.json();
    //       console.log(tokenData);
    //     } catch (error) {
    //       console.error("Error fetching the access token:", error);
    //     }
    //   }
    };

    fetchData();
  }, [Provider, Email]);

  return <div>Processing GitHub login...</div>;
}

export default PopupHandler;
