import {
  Appointment,
  AppointmentStatusCode,
  EVENT_STATUS_COLORS,
} from "./DndHelper";

export default function AppointmentEvent({
  appointment,
}: {
  appointment: Appointment;
}) {
  const { location, status, resource, address } = appointment;
  const background = EVENT_STATUS_COLORS[status as AppointmentStatusCode];

  return (
    <div className={`bg-${background} p-1 h-full text-black`}>
      <div className="flex items-center justify-between">
        <div>
          <p className="text-xs">{resource}</p>
        </div>
      </div>
    </div>
  );
}
