import React, { useState } from "react";
import { useForm } from "react-hook-form";

interface RegisterProps {
  toggleSignup: Function;
}

const ForgetPassword: React.FC<RegisterProps> = ({ toggleSignup }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    console.log(data);
  };
  return (
    <div className="min-h-screen bg-[url('https://iated.org/archive/iceri2022_pictures//iceri22-096.jpg')] bg-no-repeat bg-cover flex items-center justify-center">
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 max-w-md w-full">
        <h1 className="text-center text-2xl font-bold mb-6">Forgot Password</h1>
        <div className="mx-6 text-center">
          No worries, we've got you covered! and we'll help you reset it in no
          time.
        </div>
        <form>
          <div className="my-4">
            <label className="block text-gray-700 font-bold mb-2">
              Email Address
            </label>
            <input
                    className="w-full px-5 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    {...register("email", {
                      required: {
                        value: true,
                        message: "Email is required",
                      },
                      pattern: {
                        value:
                          /^[a-zA-Z0-9]{0,61}@[a-zA-Z0-9]{0,255}.[a-zA-Z0-9]{0,24}$/,
                        message: "Invalid Email",
                      },
                    })}
                  />
            {/* <input
              className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              placeholder="Enter your email address"
              {...register("email", {
                            required: {
                              value: true,
                              message: "Email is required",
                            },
                            pattern: {
                              value:
                                /^[a-zA-Z0-9]{0,61}@[a-zA-Z0-9]{0,255}.[a-zA-Z0-9]{0,24}$/,
                              message: "Invalid Email",
                            },
                          })}
            /> */}
            {errors?.email?.message && (
              <p className="text-red-500 text-xs mt-2">
                {errors?.email?.message.toString()}
              </p>
            )}
          </div>
          <button onClick={handleSubmit(onSubmit)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
            type="submit"
          >
            Reset Password
          </button>
        </form>
        <div className="flex flex-row my-4 items-center justify-center">
          Know your Password?
          <a href="/signin">
            <span className="text-[#625FFB] font-bold cursor-pointer ml-1">
              Login!
            </span>
          </a>
        </div>
      </div>
    </div>
    // <div>
    //   <img classNameNameName="ml-5" src="/login/logooratorengage.svg" />
    //   <div classNameNameName="min-h-screen flex flex-col mt-20 items-center gap-7 relative ">
    //     <div classNameNameName="font-bold text-3xl">Forget Password</div>
    //     <div classNameNameName="mx-6">
    //       No worries, we've got you covered! and we'll help you reset it in no
    //       time.
    //     </div>

    //     <div classNameNameName="md:w-1/2 w-[80%] z-50">
    //       <div classNameNameName=" flex justify-center gap-2 items-center rounded-full   text-black  bg-[#eee] ">
    //         <div>
    //           <img classNameNameName="ml-3" src="/login/email.svg" />
    //         </div>
    //         <input
    //           classNameNameName=" outline-none w-full h-full py-4 mx-2 bg-[#eee] rounded-full placeholder:text-[#888]  focus:outline-none"
    //           type="text"
    //           placeholder="Your email address"
    //           {...register("email", {
    //             required: {
    //               value: true,
    //               message: "Email is required",
    //             },
    //             pattern: {
    //               value:
    //                 /^[a-zA-Z0-9]{0,61}@[a-zA-Z0-9]{0,255}.[a-zA-Z0-9]{0,24}$/,
    //               message: "Invalid Email",
    //             },
    //           })}
    //         />
    //       </div>
    //       {errors.email?.message ? (
    //         <small classNameNameName="text-red-600  ml-5 my-2">
    //           {errors?.email?.message.toString()}
    //         </small>
    //       ) : (
    //         <></>
    //       )}
    //     </div>

    //     <button
    //       onClick={handleSubmit(onSubmit)}
    //       classNameNameName={`z-50 px-7 py-4 text-sm rounded-full bg-[#625FFB] text-white`}
    //     >
    //       <a>RESERT</a>
    //     </button>
    //     <div classNameNameName="z-50">
    //       Know your Password?
    //       <a href="/signin">
    //       <span
    //         classNameNameName="text-[#625FFB] font-bold cursor-pointer ml-1"
    //       >
    //         Login!
    //       </span>
    //       </a>
    //     </div>
    //     <div classNameNameName="absolute -left-24 bottom-32 ">
    //       <img classNameNameName="" src="/login/arrow_design.svg" />
    //     </div>
    //   </div>
    // </div>
  );
};

export default ForgetPassword;
