import { useEffect, useState } from "react";
import SessionsService from "../../../services/sessionService";
import ReactApexChart from "react-apexcharts";
import { useParams } from "react-router-dom";

interface ListEvents {
  data: {
    sessionStatus: number;
  }[];
}

const DonutChart = () => {
  const [listEvents, setListEvents] = useState<ListEvents | null>(null);
  const { eventId } = useParams<{ eventId: string }>();

  useEffect(() => {
    getSessionsByEventId(eventId);
  }, [eventId]);

  const statusMapping = {
    0: "Nominated",
    1: "Accepted",
    2: "Accept Queue",
    3: "Decline Queue",
    4: "Declined",
  };

  const getSessionsByEventId = (eventId: string) => {
    SessionsService.getSessionByEventId(eventId)
      .then((resData) => {
        if (resData) {
          setListEvents(resData);
        }
      })
      .catch((err) => {
        console.error("Error fetching sessions:", err);
      });
  };

  const countStatuses = () => {
    if (!listEvents) return [0, 0, 0, 0, 0];

    const counts = [0, 0, 0, 0, 0];

    listEvents.data.forEach((event) => {
      if (event.sessionStatus in statusMapping) {
        counts[event.sessionStatus]++;
      }
    });

    return counts;
  };

  const state = {
    series: countStatuses(),
    options: {
      chart: {
        width: 280,
        type: "donut" as "donut",
      },
      labels: [
        "Nominated",
        "Accepted",
        "Accept Queue",
        "Declined",
        "Decline Queue",
      ],
      dataLabels: {
        enabled: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 500,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
      legend: {
        position: "right" as "right",
        offsetY: 0,
        height: 280,
      },
      title: {
        text: "Speaker Acceptance",
        align: "left" as "left",
      },
    },
  };

  return (
    <div className="p-4">
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="donut"
        height={350}
      />
    </div>
  );
};

export default DonutChart;
