import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import SessionsService from "../../../../services/sessionService";
import "./DetailSession.scss";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HashLoader } from "react-spinners";
const DetailSessionComponent = () => {
  const { sessionId } = useParams();
  const [sessionData, setSessionData] = useState<any>(null);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const {
    register: registerUpdate,
    handleSubmit: handleSubmitUpdate,
    formState: { errors: errorsUpdate },
    setValue: setValueUpdate,
  } = useForm();

  useEffect(() => {
    fetchSessionData();
  }, [sessionId]);

  const fetchSessionData = async () => {
    setLoading(true);
    try {
      const data = await SessionsService.getSessionById(sessionId);
      setSessionData(data);
      console.log(data);
      setFormValues(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching session data:", error);
    }
  };

  const setFormValues = (data: any) => {
    setValue("status", data.sessionStatus);
    setValueUpdate("title", data.title);
    setValueUpdate("description", data.description);
    setValueUpdate("room", data.room);
    setValueUpdate("startsAt", data.startsAt);
    setValueUpdate("endsAt", data.endsAt);
    setValueUpdate("sessionType", data.sessionType);
  };

  const roomOptions: any[] = [
    "Track 1",
    "Track 2",
    "Track 3",
    "Track 4",
    "Track 5",
    "Track 6 (Workshop)",
  ];
  const statusMapping = {
    0: "Nominated",
    1: "Accepted",
    2: "Accept Queue",
    3: "Decline Queue",
    4: "Declined",
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const options: any = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    return date.toLocaleString("en-GB", options);
  };

  const toggleIsUpdate = () => {
    setIsUpdateOpen(!isUpdateOpen);
  };
  const onUpdateStatus = async (data: any) => {
    setLoading(true);
    try {
      var statusUpdate = await SessionsService.updateSessionStatus(
        sessionId,
        data.status
      );
      if (statusUpdate) {
        setLoading(false);
        toast.success("Session status updated successfully", {
          autoClose: 2000,
        });
        fetchSessionData();
      } else {
        setLoading(false);
        toast.error("Something went wrong !", { autoClose: 10000 });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating session status:", error);
    }
  };

  const onUpdateSession = async (data: any) => {
    try {
      const sessionDetails = {
        id: sessionId,
        startsAt: data.startsAt,
        endsAt: data.endsAt,
        room: data.room,
        title: data.title,
        description: data.description,
        // sessionType: Number(
        //   Object.keys(sessionTypeMapping).find(
        //     (key) => sessionTypeMapping[key] === data.sessionType
        //   )
        // ),
        sessionType : data.sessionType
      };
      setLoading(true);
      var sessionUpdated = await SessionsService.sessionTimeUpdate(
        sessionDetails
      );
      if (sessionUpdated) {
        setLoading(false);
        toast.success("Session Updated Successfully", { autoClose: 2000 });
        fetchSessionData();
        toggleIsUpdate();
      } else {
        setLoading(false);
        toast.error("Something went wrong !", { autoClose: 10000 });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating session:", error);
    }
  };

  const cancelWithdrawal = async () => {
    try {
      const data = await SessionsService.withdrawalSession(
        sessionId,
        false,
        ""
      );
      showSuccessToast();
      fetchSessionData();
      return data;
    } catch (error) {
      showErrorToast();
    }
  };
  const showSuccessToast = () =>
    toast.success("Withdrawal status updated successfully", {
      autoClose: 1000,
    });
  const showErrorToast = () =>
    toast.error("Withdrawal status update failed. Please try again later", {
      autoClose: 10000,
    });

  const handleChange = (event) => {
    const selectedStatus = event.target.value;
    onUpdateStatus({ status: selectedStatus });
  };

  const handleDeclined = () => {
    onUpdateStatus({ status: 4 });
  };

  return (
    <div>
      {loading && (
        <div className="absolute inset-0 bg-black bg-opacity-80 z-10">
          <div
            role="status"
            className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
          >
            <HashLoader color={"white"} loading={loading} size={50} />
          </div>
        </div>
      )}
      <div className="bg-[#f3f3f4]">
        <div className="card boxing  rounded-md">
          <div className="flex gap-5">
            <div className="session-details w-3/5 bg-white ">
              <div className="border-b pb-3">
                <div className="session px-5 pt-3">
                  <div className="font-bold">{sessionData?.title || "N/A"}</div>
                  <div>
                    <button
                      className="flex justify-center items-center gap-2 bg-[#625ffb] p-2 text-white rounded-lg font-semibold"
                      onClick={toggleIsUpdate}
                    >
                      <img className="" src="/session/edit.svg" alt="edit" />
                      Edit session
                    </button>
                  </div>
                </div>
              </div>
              <div className="overall-container p-5">
                {sessionData?.isSessionWithdrawn &&
                  !(sessionData?.sessionStatus === 4) && (
                    <div className="withdrawal  bg-red-100 text-red-500 px-3 py-1 rounded-md">
                      <div className="mb-2 flex gap-2 ">
                        <div>
                          <img src="/session/retry.svg" />
                        </div>
                        <div className="flex gap-3">
                          <div>This session submission has been</div>
                          <div className="flex gap-3">
                            <button
                              type="button"
                              onClick={handleDeclined}
                              className="px-4 text-sm  bg-[#fb5f69] text-white rounded"
                            >
                              Mark session as Declined
                            </button>
                            <button
                              onClick={cancelWithdrawal}
                              className="px-4 text-sm outline outline-1 outline-[#fb5f69] text-[#fb5f69] rounded"
                            >
                              Cancel withdrawal
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        withdrawn by session owner on{" "}
                        <span className="text-sm">
                          {" "}
                          {formatDate(sessionData?.withdrawDateTime) || "N/A"}
                        </span>
                      </div>
                    </div>
                  )}

                <div className="container-feild">
                  <h4 className="text-[#797c7d] font-semibold">Description</h4>
                  <p className="title text-[#686b6d]">
                    {sessionData?.description || "N/A"}
                  </p>
                </div>
                <div className="container-feild">
                  <h4 className="text-[#797c7d] font-semibold">
                    Additional notes
                  </h4>
                  <p className="title text-[#686b6d]">
                    {sessionData?.additionalNotes || "N/A"}
                  </p>
                </div>
                <div className="text-xs flex justify-between border-b pb-2">
                  <div className="font-normal">Category</div>
                  <div>
                    <div className=" text-[#5e5e5e] rounded-sm p-1 mr-10  bg-[#d1dade]">
                      {sessionData?.category || "N/A"}
                    </div>
                  </div>
                </div>
                <div className="text-xs flex justify-between border-b py-2">
                  <div className="font-normal">Session Type</div>
                  <div>
                    <div className=" text-[#5e5e5e] rounded-sm  p-1 mr-10  bg-[#d1dade]">
                      {sessionData?.sessionType || "N/A"}
                    </div>
                  </div>
                </div>
                <div className="text-xs flex justify-between border-b py-2">
                  <div className="font-normal">Session Level</div>
                  <div>
                    <div className=" text-[#5e5e5e] rounded-sm  p-1 mr-10  bg-[#d1dade]">
                      {sessionData?.sessionLevel || "N/A"}
                    </div>
                  </div>
                </div>
                <div className="text-xs flex justify-between border-b py-2">
                  <div className="font-normal">Event name</div>
                  <div>
                    <div className=" text-[#5e5e5e] rounded-sm  p-1 mr-10  bg-[#d1dade]">
                      {sessionData?.event?.eventName || "N/A"}
                    </div>
                  </div>
                </div>
                <div className="container-feild">
                  <h4 className="text-[#797c7d] font-semibold">Submitted </h4>
                  <p className="title text-[#686b6d]">
                    {formatDate(sessionData?.createdOn) || "N/A"}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-2/5 bg-white">
              <form className="" onSubmit={handleSubmit(onUpdateStatus)}>
                <div>
                  <div className="">
                    <div className="border-b p-5 font-bold">Session Status</div>
                    <div className="w-[90%] m-auto ">
                      <select
                        className="text-white border-2 my-6 w-full py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm bg-[#625ffb] focus:outline-none m-auto ease-linear transition-all duration-150"
                        {...register("status")}
                        onChange={handleChange}
                      >
                        {Object.entries(statusMapping).map(([key, value]) => (
                          <option className="p-2" key={key} value={key}>
                            {value}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="text-sm mx-4">
                      {` This session has been ${
                        statusMapping[sessionData?.sessionStatus]
                      }`}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {sessionData?.speakers ? (
            <div className="bg-white w-3/5 p-5 my-5">
              {sessionData.speakers.map((speaker: any) => (
                <div key={speaker.id} className="speaker overall-container ">
                  <div className="flex gap-5 justify-start items-start">
                    <div className="flex flex-col justify-center items-center w-1/5 -mt-3">
                      <img
                        src={
                          speaker?.photoPath ||
                          "/session/profile-placeholder-2.png"
                        }
                        className="rounded-circle"
                        alt="Profile Avatar"
                      />
                      <a href={speaker?.photoPath} download="user_profolio.png">
                        <button className="border-2 my-3 border-[#625ffb] text-[#625ffb] rounded text-xs p-1">
                          Download
                        </button>
                      </a>
                    </div>
                    <div className="w-4/5">
                      <div className="font-bold text-[1.3em] text-[#625ffb]">
                        {speaker?.users?.firstName +
                          " " +
                          speaker?.users?.lastName || "N/A"}
                      </div>
                      <div className="font-bold text-base my-2">
                        {speaker?.tagLine || "N/A"}
                      </div>
                      <div className="text-sm my-3">
                        {speaker?.biography || "N/A"}
                      </div>
                      <div className="text-xs flex justify-between border-b py-2 mt-4">
                        <div className="font-bold">Speaker Type</div>
                        <div>
                          <div className=" text-[#5e5e5e] rounded-sm  p-1 mr-10  bg-[#d1dade]">
                            {speaker?.speakersType || "N/A"}
                          </div>
                        </div>
                      </div>
                      <div className="text-xs flex justify-between border-b py-2">
                        <div className="font-bold">Speaker's Company Logo</div>
                        <div>
                          <div className="text-[#5e5e5e] rounded-sm  p-1 mr-10  bg-[#d1dade]">
                            <a
                              href={speaker?.companyLogo}
                              download="organization_logo.png"
                            >
                              <button className="btn btn-primary">
                                Download
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="text-xs flex justify-between border-b py-2">
                        <div className="font-bold">Company Name</div>
                        <div>
                          <div className=" text-[#5e5e5e] rounded-md p-1 mr-10  ">
                            {speaker?.companyName || "N/A"}
                          </div>
                        </div>
                      </div>
                      <div className="text-xs flex justify-between border-b py-2">
                        <div className="font-bold">Email</div>
                        <div>
                          <div className=" text-[#5e5e5e] rounded-md p-1 mr-10  ">
                            {speaker?.users?.email || "N/A"}
                          </div>
                        </div>
                      </div>

                      <div className="card-profile-stats flex gap-3 justify-content-center">
                        <div>
                          <span className="description cursor-pointer">
                            <a
                              target="_blank"
                              href={speaker?.users?.userSocialMedia?.linkedIn}
                            >
                              <img src="/session/linkedIn.svg" />
                            </a>
                          </span>
                        </div>
                        <div>
                          <span className="description cursor-pointer">
                            <a
                              target="_blank"
                              href={speaker?.users?.userSocialMedia?.instagram}
                            >
                              <img src="/session/instagram.svg" />
                            </a>
                          </span>
                        </div>
                        <div>
                          <span className="description cursor-pointer">
                            <a
                              target="_blank"
                              href={speaker?.users?.userSocialMedia?.twitter}
                            >
                              <img src="/session/twitter.svg" />
                            </a>
                          </span>
                        </div>
                        <div>
                          <span className="description cursor-pointer">
                            <a
                              target="_blank"
                              href={speaker?.users?.userSocialMedia?.facebook}
                            >
                              <img src="/session/facebook.svg" />
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div>No Speakers Added</div>
          )}

          {isUpdateOpen && (
            <div className="model-wrap ">
              <form
                className="modal-content"
                onSubmit={handleSubmitUpdate(onUpdateSession)}
              >
                <div className="container">
                  <div className="update-header p-5">
                    <div className="update-heading">Update Session :-</div>
                    <div>
                      <button onClick={toggleIsUpdate}>
                        <img
                          className="close-btn mx-3"
                          src="/session/close.svg"
                          alt="close"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="overall-container px-5">
                  <div className="container-feild">
                    <div className="sub-title">Title</div>
                    <input
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm bg-[#f3f3f4] focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      {...registerUpdate("title", {
                        required: "Title is required",
                      })}
                    />
                    {errorsUpdate.title && (
                    <p className="text-red-500 text-xs mt-2">
                      {errorsUpdate.title.message.toString()}
                    </p>
                  )}
                  </div>
                  <div className="container-feild">
                    <div className="sub-title">Description</div>
                    <textarea
                      rows={4}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm bg-[#f3f3f4] focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      {...registerUpdate("description", {
                        required: "Description is required",
                      })}
                    />
                    {errorsUpdate.description && (
                    <p className="text-red-500 text-xs mt-2">
                      {errorsUpdate.description.message.toString()}
                    </p>
                  )}
                  </div>
                  <div className="flex gap-4 justify-between">
                    <div className="container-feild w-1/2">
                      <div className="sub-title">Starts At</div>
                      <input
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm bg-[#f3f3f4] focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        type="datetime-local"
                        {...registerUpdate("startsAt")}
                      />
                      {errorsUpdate.startsAt && (
                    <p className="text-red-500 text-xs mt-2">
                      {errorsUpdate.startsAt.message.toString()}
                    </p>
                  )}
                    </div>
                    <div className="container-feild w-1/2">
                      <div className="sub-title"> Ends At</div>
                      <input
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm bg-[#f3f3f4] focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        type="datetime-local"
                        {...registerUpdate("endsAt")}
                      />
                      {errorsUpdate.endsAt && (
                    <p className="text-red-500 text-xs mt-2">
                      {errorsUpdate.endsAt.message.toString()}
                    </p>
                  )}
                    </div>
                  </div>
                  <div className="flex gap-4 justify-between">
                    <div className="container-feild w-1/2">
                      <div className="sub-title">Room</div>
                      <select
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm bg-[#f3f3f4] focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        {...registerUpdate("room")}
                      >
                        {roomOptions.map((type, idx) => (
                          <option key={idx} value={type}>
                            {type}
                          </option>
                        ))}
                      </select>
                      {errorsUpdate.room && (
                    <p className="text-red-500 text-xs mt-2">
                      {errorsUpdate.room.message.toString()}
                    </p>
                  )}
                    </div>
                    <div className="container-feild w-1/2">
                      <div className="sub-title">Session Type</div>
                      <select
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm bg-[#f3f3f4] focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        {...registerUpdate("sessionType")}
                      >
                        {JSON.parse(
                          sessionData.event?.eventDetails?.sessionType || "[]"
                        ).map((level) => (
                          <option key={level} value={level}>
                            {level}
                          </option>
                        ))}
                      </select>
                      {errorsUpdate.sessionType && (
                    <p className="text-red-500 text-xs mt-2">
                      {errorsUpdate.sessionType.message.toString()}
                    </p>
                  )}
                    </div>
                  </div>
                  <div className="actions">
                    <button
                      className={`px-7 my-3 py-2  rounded-full bg-[#625ffb] text-white`}
                      type="submit"
                    >
                      Update Session
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default DetailSessionComponent;
