import React, { FC, useContext, useEffect, useRef, useState } from "react";
import "./CFS.scss";
import { useForm } from "react-hook-form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EventContext } from "../EditEvent/EditEvent";
import {
  EditorState,
} from "draft-js";
import ReactQuill from "react-quill";
import BlobService from "../../../services/blobService";
import { setNestedValue } from "../../../helper/ValidateForm";

interface CfsProps {}

const Cfs: FC<CfsProps> = () => {
  const {
    register,
    handleSubmit,
    reset
  } = useForm();

  const [eventCategories, setEventCategories] = useState<string[]>([]);
  const [eventTopics, setEventTopics] = useState<string[]>([]);
  const [CfSdetails, setCfSdetails] = useState();
  const fileInputRef = useRef(null);
  const [isImageExist, setImageExist]=useState(true);
  const { event, setEvent, errors, setErrors } = useContext(EventContext);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const isEditorStateInitialized = useRef(false);
  const handleEditorChange = (state) => {
    console.log(state);
    setEvent((prevEvent) => {
      const updatedEvent = setNestedValue(prevEvent, "eventCallForSpeakers.description", state)
      console.log(updatedEvent);
      return updatedEvent;
    });
    
    // setEvent((prevEvent) => ({
    //   ...prevEvent,
    //   c: {
    //     ...event?.eventCallForSpeakers,
    //     description : state
    //   },
    // }));
  };

  const mandatoryFields = [
    'eventCallForSpeakers.startDate',
    'eventCallForSpeakers.endDate',
    'eventCallForSpeakers.slugURL',
    'eventCallForSpeakers.supportEmail',
    "eventDetails.eventType",
    "eventDetails.eventLocation",
    "eventDetails.venue",
  ];

  useEffect(() => {
    if (event?.description && !isEditorStateInitialized.current) {
      setEditorState(event.description);
      isEditorStateInitialized.current = true;
    }
  }, [event]);

  useEffect(() => {
    reset({
      ...event,
      eventDetails: {
        ...event?.eventDetails,
      },
      eventCallForSpeakers: {
        ...event?.eventCallForSpeakers,
      },
    });
  }, [event]);

  const handleExpenseChange = (name,value) =>{
    console.log(name,value);
    setEvent((prevEvent) => {
      const updatedEvent = setNestedValue(prevEvent, name, !value);
      console.log(updatedEvent);
      return updatedEvent;
    });
    if (mandatoryFields.includes(name)) {
      if (value === null || value === undefined || value === "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Field is required",
        }));
        console.log(errors);
      } else {
        setErrors((prevErrors) => {
          const { [name]: _, ...rest } = prevErrors;
          return rest;
        });
        console.log(errors);
      }
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name,value);
    setEvent((prevEvent) => {
      const updatedEvent = setNestedValue(prevEvent, name, value);
      console.log(updatedEvent);
      return updatedEvent;
    });
    if (mandatoryFields.includes(name)) {
      if (value === null || value === undefined || value === "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Field is required",
        }));
        console.log(errors);
      } else {
        setErrors((prevErrors) => {
          const { [name]: _, ...rest } = prevErrors;
          return rest;
        });
        console.log(errors);
      }
    }
  };

  const handleImageChanges =async (e)=>{
    var result = await BlobService.uploadFile(e.target.files[0], event?.eventCallForSpeakers?.slugURL,"BannerImages");
    setEvent((prevEvent) => ({
      ...prevEvent,
      eventCallForSpeakers: {
        ...event?.eventCallForSpeakers,
        eventBanner : result
      },
    }));
    console.log(result);
  }

  const handleAddItem = (
    event: React.KeyboardEvent<HTMLInputElement>,
    setter: React.Dispatch<React.SetStateAction<string[]>>
  ) => {
    const value = event.currentTarget.value.trim();

    if (event.key === "Enter" && value !== "") {
      setter((prevItems) => [...prevItems, value]);
      event.currentTarget.value = "";
    }
  };

  const handleRemoveItem = (
    index: number,
    setter: React.Dispatch<React.SetStateAction<string[]>>
  ) => {
    setter((prevItems) => prevItems.filter((_, i) => i !== index));
  };

  const onSubmit = (data: any) => {
    data.eventCategories = eventCategories;
    data.eventTopics = eventTopics;
    data.CfSdetails = CfSdetails;
  };

  return (
    <div className="row">
      <div className="forms row">
        <form>
          <div className="flex flex-row justify-between">
            <div className="w-1/2 p-2">
              <div className="flex flex-col gap-2 my-2 p-2">
                <label className="text-sm font-semibold">
                  CFS Open From<span className="text-red-500"> *</span>
                </label>
                <input
                  {...register("eventCallForSpeakers.startDate", { required: true })}
                  type="datetime-local"
                  name = "eventCallForSpeakers.startDate"
                  value={event?.eventCallForSpeakers?.startDate}
                  onChange={(e)=>handleChange(e)}
                  className="text-gray-700 border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-blue-700"
                  placeholder=""
                />
                {errors["eventCallForSpeakers.startDate"] && (
                  <small className="text-red-500">
                    From date required
                  </small>
                )}
              </div>

              <div className="flex flex-col gap-2 my-2 p-2">
                <label className="text-sm font-semibold">
                  CFS Open To<span className="text-red-500"> *</span>
                </label>
                <input
                  {...register("eventCallForSpeakers.endDate", { required: true })}
                  type="datetime-local"
                  value={event?.eventCallForSpeakers?.endDate}
                  name = "eventCallForSpeakers.endDate"
                  onChange={(e)=>handleChange(e)}
                  className="text-gray-700 border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-blue-700"
                  placeholder=""
                />
                {errors["eventCallForSpeakers.endDate"] && (
                  <small className="text-red-500">
                    To date required
                  </small>
                )}
              </div>

              <div className="flex flex-col gap-2 my-2 p-2">
                <label className="text-sm font-semibold">
                  CFS Address<span className="text-red-500"> *</span>
                </label>
                <div className="flex text-gray-700 border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-blue-700">
                  oratorengage.com/
                  <input
                    {...register("eventCallForSpeakers.slugURL", { required: true })}
                    type="text"
                    value={event?.eventCallForSpeakers?.slugURL}
                    onChange={(e)=>handleChange(e)}
                    className="placeholder:text-sm focus:outline-none w-full"
                    placeholder="CFS address"
                  />
                </div>
                {errors["eventCallForSpeakers.slugURL"] && (
                  <small className="text-red-500  my-2">
                    CFS Address is required
                  </small>
                )}
              </div>

              <div className="flex flex-col gap-2 my-2 p-2">
                <label className="text-sm font-semibold">CFS Details</label>
                <div className="flex flex-col gap-2 border">
                <ReactQuill theme="snow" style={{ height: '300px' }} value={event?.eventCallForSpeakers?.description} onChange={(e)=>handleEditorChange(e)}/>
                </div>
              </div>
            </div>
            <div className="w-1/2 p-2">
              <div className="flex flex-row justify-evenly">
                <label className="text-sm font-semibold w-1/5">
                  Expenses coverage
                </label>
                <div className="flex flex-col justify-between w-3/5">
                  <div className="inline-flex items-center">
                    <label
                      className="relative flex items-center px-2 rounded-full cursor-pointer"
                      htmlFor="eventCallForSpeakers.travelExpenses"
                    >
                      <input
                      {...register("eventCallForSpeakers.travelExpenses")}
                        type="checkbox"
                        className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border bg-[#eee] border-gray-400 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                        id="eventCallForSpeakers.travelExpenses"
                        name = "eventCallForSpeakers.travelExpenses"
                        onChange={(e)=>handleExpenseChange(e.target.name,event?.eventCallForSpeakers?.travelExpenses )}
                        checked={event?.eventCallForSpeakers?.travelExpenses}
                      />
                      <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-3.5 w-3.5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          stroke="currentColor"
                          stroke-width="1"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </span>
                    </label>
                    <label
                      className="mt-px font-medium text-gray-700 cursor-pointer select-none"
                      htmlFor="eventCallForSpeakers.travelExpenses"
                    >
                      Travel expenses covered
                    </label>
                  </div>
                  <div className="inline-flex items-center">
                    <label
                      className="relative flex items-center px-2 rounded-full cursor-pointer"
                      htmlFor="eventCallForSpeakers.accommodationExpenses"
                    >
                      <input
                        type="checkbox"
                        className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border bg-[#eee] border-gray-400 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                        id="eventCallForSpeakers.accommodationExpenses"
                        name = "eventCallForSpeakers.accommodationExpenses"
                        onChange={(e)=>handleExpenseChange(e.target.name,event?.eventCallForSpeakers?.accommodationExpenses )}
                        checked={event?.eventCallForSpeakers?.accommodationExpenses}
                      />
                      <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-3.5 w-3.5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          stroke="currentColor"
                          stroke-width="1"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </span>
                    </label>
                    <label
                      className="mt-px font-medium text-gray-700 cursor-pointer select-none"
                      htmlFor="eventCallForSpeakers.accommodationExpenses"
                    >
                      Accomdation expenses covered
                    </label>
                  </div>
                  <div className="inline-flex items-center">
                    <label
                      className="relative flex items-center px-2 rounded-full cursor-pointer"
                      htmlFor="eventCallForSpeakers.fullExpenses"
                    >
                      <input
                        type="checkbox"
                        className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border bg-[#eee] border-gray-400 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                        id="eventCallForSpeakers.fullExpenses"
                        name = "eventCallForSpeakers.fullExpenses"
                        onChange={(e)=>handleExpenseChange(e.target.name,event?.eventCallForSpeakers?.fullExpenses )}
                        checked={event?.eventCallForSpeakers?.fullExpenses}
                      />
                      <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-3.5 w-3.5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          stroke="currentColor"
                          stroke-width="1"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </span>
                    </label>
                    <label
                      className="mt-px font-medium text-gray-700 cursor-pointer select-none"
                      htmlFor="eventCallForSpeakers.fullExpenses"
                    >
                      Full conference fee covered
                    </label>
                  </div>
                  <input
                    {...register("fullExpensesDetails", { required: true })}
                    type="text"
                    name="eventCallForSpeakers.fullExpensesDetails"
                    value={event?.eventCallForSpeakers?.fullExpensesDetails}
                    onChange={handleChange}
                    className="mt-2 text-gray-700 border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-blue-700"
                    placeholder="Full expenses details"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-2 my-2 p-2">
                <label className="text-sm font-semibold">
                  Speaker Support Email
                  <span className="text-red-500"> *</span>
                </label>
                <input
                  {...register("eventCallForSpeakers.supportEmail", {
                    required: {
                      value: true,
                      message: "Email is required",
                    },
                    pattern: {
                      value:
                        /^[a-zA-Z0-9]{0,61}@[a-zA-Z0-9]{0,255}.[a-zA-Z0-9]{0,24}$/,
                      message: "Invalid Email",
                    },
                  })}
                  type="text"
                  value={event?.eventCallForSpeakers?.supportEmail}
                  name="eventCallForSpeakers.supportEmail"
                  onChange={(e)=>handleChange(e)}
                  className="text-gray-700 border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-blue-700"
                  placeholder="eg. abc@gmail.com"
                />
                {errors['eventCallForSpeakers.supportEmail'] && (
                  <small className="text-red-500">
                    {errors['eventCallForSpeakers.supportEmail']}
                  </small>
                )}
              </div>

              <div className="flex flex-col gap-2 my-2 p-2">
                <label className="text-sm font-semibold">Header Banner</label>
                <div className="p-2 bg-[#f3f3f4]">
                  {event?.eventCallForSpeakers?.eventBanner!=null && (
                    <div className="flex flex-row justify-between items-start">
                      <div className="p-2 mb-2">
                        <img
                          src={
                            event?.eventCallForSpeakers?.eventBanner
                          }
                          alt="Preview"
                          style={{ maxWidth: "100%", maxHeight: "200px" }}
                        />
                      </div>
                      <button
                        type="button"
                        onClick={()=>{setEvent((prevEvent) => ({
                          ...prevEvent,
                          eventCallForSpeakers: {
                            ...event?.eventCallForSpeakers,
                            eventBanner : null
                          },
                        }));}}
                        className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                      >
                        <span className="sr-only">Close menu</span>
                        <svg
                          className="h-3 w-3"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="red"
                          viewBox="0 0 24 24"
                          stroke="black"
                          aria-hidden="true"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  )}
                  <div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={(e)=>handleImageChanges(e)}
                      style={{ display: "none" }}
                    />
                    <div
                      className="my-4 mx-2 bg-white p-2 text-center cursor-pointer"
                      onClick={() => {
                        fileInputRef.current.click();
                      }}
                    >
                      Choose file or Click here to Upload
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Cfs;
