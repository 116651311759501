export const validateForm = (event, errors) => {
    console.log("Validating");
    const updatedErrors = { ...errors };
  
    if (event?.eventDetails?.eventType === 0) {
      if (!event?.eventDetails?.eventLocation) {
        updatedErrors["eventDetails.eventLocation"] = "Field is required";
      } else {
        delete updatedErrors["eventDetails.eventLocation"];
      }
      if (!event?.eventDetails?.venue) {
        updatedErrors["eventDetails.venue"] = "Field is required";
      } else {
        delete updatedErrors["eventDetails.venue"];
      }
    }

    if(event?.timeZone === null || event.timeZone ===undefined || event.timeZone === ''){
      updatedErrors["timeZone"] = "Field is required";
    }
    else{
      delete updatedErrors["timeZone"];
    }

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailPattern.test(event?.eventCallForSpeakers?.supportEmail)) {
        updatedErrors["eventCallForSpeakers.supportEmail"] = "Enter Valid Email Id";
    }
  
    console.log("Updated Errors: ", updatedErrors);
    return updatedErrors;
  };

  export const convertToEventModel = (event, auth) => {
    
    var eventModel = {
      "id": event.id,
      "eventAPIKey": event.eventAPIKey,
      "eventName": event.eventName,
      "eventStartDate": event.eventStartDate,
      "eventEndDate": event.eventEndDate,
      "timeZone": event.timeZone,
      "description": event.description,
      "isActive": true,
      "createdBy": event.createdBy ?? auth.userId,
      "createdOn": event.createdOn ?? new Date().toISOString(),
      "modifiedBy": auth.userId,
      "modifiedOn": new Date().toISOString(),
      "eventDetails": {
        "id": event?.eventDetails?.id,
        "eventId":event?.eventDetails?.eventId,
        "eventType": event?.eventDetails?.eventType,
        "sessionLevel": event?.eventDetails?.sessionLevel,
        "techStack":event?.eventDetails?.techStack,
        "speakerType":event?.eventDetails?.speakerType,
        "sessionType": event?.eventDetails?.sessionType,
        "sessionCategory": event?.eventDetails?.sessionCategory,
        "coSpeakerCount": event?.eventDetails?.coSpeakerCount,
        "tShirtSizes": event?.eventDetails?.tShirtSizes,
        "languages": event?.eventDetails?.languages,
        "eventLocation": event?.eventDetails?.eventLocation,
        "venue": event?.eventDetails?.venue,
        "eventWebsite": event?.eventDetails?.eventWebsite,
        "twitter": event?.eventDetails?.twitter,
        "linkedIn": event?.eventDetails?.linkedIn,
        "facebook": event?.eventDetails?.facebook,
        "instagram": event?.eventDetails?.instagram,
        "isActive": true,
        "createdBy": event?.eventDetails?.createdBy ?? auth.userId,
        "createdOn": event?.eventDetails?.createdOn ?? new Date().toISOString(),
        "modifiedBy": auth.userId,
        "modifiedOn": new Date().toISOString()
      },
      "eventCallForSpeakers": {
        "id": event?.eventCallForSpeakers?.id,
        "eventId": event?.eventCallForSpeakers?.eventId,
        "startDate": event?.eventCallForSpeakers?.startDate,
        "endDate": event?.eventCallForSpeakers?.endDate,
        "slugURL": event?.eventCallForSpeakers?.slugURL,
        "description": event?.eventCallForSpeakers?.description,
        "supportEmail": event?.eventCallForSpeakers?.supportEmail,
        "eventBanner": event?.eventCallForSpeakers?.eventBanner,
        "travelExpenses": event?.eventCallForSpeakers?.travelExpenses,
        "accommodationExpenses": event?.eventCallForSpeakers?.accommodationExpenses,
        "fullExpenses": event?.eventCallForSpeakers?.fullExpenses,
        "fullExpensesDetails": event?.eventCallForSpeakers?.fullExpensesDetails,
        "createdBy": event?.eventCallForSpeakers?.createdBy ?? auth.userId ,
        "createdOn": event?.eventCallForSpeakers?.createdOn ?? new Date().toISOString(),
        "modifiedBy": auth.userId,
        "modifiedOn": new Date().toISOString(),
        "isActive": true
      },
      "eventUserPermissions": event.eventUserPermissions
    };
    return eventModel;
  };
  
  