export enum Providers {
  GMAIL = 0,
  MICROSOFT = 1,
  GITHUB = 2,
}

export function getUserRoleFromValue(value: number): string {
    switch (value) {
      case 0:
        return "Gmail";
      case 1:
        return "Micorsoft";
      case 2:
        return "Github";
      default:
        throw new Error(`Invalid user role value: ${value}`);
    }
  }
