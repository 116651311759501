import { FC, useContext, useEffect, useRef, useState } from "react";
import "../CFSPage/CFSPage.scss";
import AuthService from "../../../services/authService";
import EventService from "../../../services/eventService";
import { toast } from "react-toastify";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import ReactQuill from "react-quill";
import UserService from "../../../services/userService";
import { HashLoader } from "react-spinners";
import { SubmitSession } from "../SubmitSession/SubmitSession";
import { AuthContext } from "../../../context/AuthContext";
import { Helmet } from "react-helmet-async";

interface CfsPageProps {}

const CfsPage: FC<CfsPageProps> = () => {
  // const routeParams = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { slugUrl } = useParams<{ slugUrl: string }>();
  const [event, setEvent] = useState<any>({});
  const [user, setUser] = useState<any>({});
  const [speaker, setSpeaker] = useState<any>({});
  const { auth } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [eventDuration, setEventDuration] = useState();
  const [cfsDuration, setCfsDuration] = useState();
  //#region  Get Event Data
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        const event = await EventService.getEventsBySlugUrl(
          slugUrl,
          auth?.userId ?? ""
        );
        setEvent(event?.event);
        setSpeaker(event?.speaker);
        if (auth != null && auth?.userId != null) {
          const user = await UserService.getUserbyUserId(auth?.userId ?? "");
          setUser(user);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error("Error fetching events");
      }
    };
    fetchEvents();
  }, []);

  const calculateDates = (date: any) => {
    try {
      const currentDate = new Date();
      const targetDate = new Date(date);
      const timeDifference = targetDate.getTime() - currentDate.getTime();
      const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      console.log(daysLeft);
      if (daysLeft < 0) {
        return `finished ${Math.abs(daysLeft)} days ago`;
      } else {
        return `${daysLeft} days Left`;
      }
    } catch (error) {
      toast.error("Error fetching events");
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    return formattedDate;
  }

  function formatTime(dateTimeString) {
    const date = new Date(dateTimeString);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutesStr = minutes < 10 ? "0" + minutes : minutes;
    return hours + ":" + minutesStr + " " + ampm;
  }

  //#endregion

  const isAuthenticated = AuthService.isAuthenticated();
  const isCfsEnded =
    new Date(event?.eventCallForSpeakers?.endDate) > new Date();
  console.log(isAuthenticated, isCfsEnded);
  const showSubmitField = isAuthenticated && isCfsEnded;
  return (
    <div>
      <div className="CFSPage" data-testid="CfsPage">
        <Helmet>
          <title>{event?.eventName || "Default Title"}</title>

          <link rel="canonical" href="https://stage.oratorengage.com/" />

          <meta
            property="og:title"
            content={event?.eventName || "Default Title"}
          />
          <meta
            property="og:image"
            content={
              event?.eventCallForSpeakers?.eventBanner ||
              "default-image-url.jpg"
            }
          />
          {/* <meta name="description" content={event?.description || 'Default description'} /> */}
          <meta property="og:url" content="https://stage.oratorengage.com/" />
          <meta property="og:type" content="website" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content={event?.eventName || "Default Title"}
          />
          {/* <meta name="twitter:description" content={event?.description || 'Default description'} /> */}
          <meta
            name="twitter:image"
            content={
              event?.eventCallForSpeakers?.eventBanner ||
              "default-image-url.jpg"
            }
          />
        </Helmet>
        <div className="mx-5 my-5 md:mx-52 md:my-16">
          <div className="bg-white">
            <div className="text-center text-2xl py-5 border-b font-semibold">
              <h2>CALL FOR SPEAKERS</h2>
            </div>
            <div className="w-2/3 m-auto">
              <img
                src={event?.eventCallForSpeakers?.eventBanner}
                alt="banner"
              />
            </div>
          </div>

          {!isCfsEnded && (
            <div className="my-5 p-3 w-full flex gap-10 bg-white justify-center">
              <p className="text-[#ff0000]">
                Call for Speakers is officially closed, but{" "}
                <strong>as a team member</strong> you can submit your session.
              </p>
            </div>
          )}

          <div
            className={`${
              isCfsEnded ? "my-10" : ""
            } w-full flex gap-10`}
          >
            {showSubmitField ? (
              <div className="w-[60%] bg-white ">
                <SubmitSession
                  event={event}
                  users={user}
                  loading={loading}
                  setLoading={setLoading}
                  speakers={speaker}
                />
              </div>
            ) : (
              <></>
            )}

            <div
              className={` ${
                showSubmitField ? "w-[40%]" : "md:flex gap-10"
              } gap-5`}
            >
              <div
                className={`bg-white p-3 ${!showSubmitField ? "md:w-1/2" : ""}`}
              >
                <div className="flex justify-center items-center ">
                  <div className="text-xl font-medium w-[68%]">
                    {event?.eventName}
                  </div>
                  <div className="text-xs w-[32%]  text-center text-white font-semibold rounded-full p-1">
                    {calculateDates(event?.eventEndDate)}
                  </div>
                </div>
                <div className="border-b border-t my-7">
                  <div className="flex gap-24 my-5">
                    <div>
                      <div className="flex gap-2 items-center ">
                        <img
                          alt="calendar"
                          className="w-6 h-auto"
                          src="/event/calendar.png"
                        />
                        <div className="text-sm text-[#625FFB] font-bold">
                          Event starts
                        </div>
                      </div>
                      <div className="font-extralight text-2xl font-normal">
                        {formatDate(event?.eventStartDate)}
                      </div>
                    </div>
                    <div>
                      <div className="flex gap-2 items-center ">
                        <img
                          alt="calendar"
                          className="w-6 h-auto"
                          src="/event/calendar.png"
                        />
                        <div className="text-sm text-[#625FFB] font-bold">
                          Event ends
                        </div>
                      </div>
                      <div className="font-extralight text-2xl font-normal">
                        {formatDate(event?.eventEndDate)}
                      </div>
                    </div>
                  </div>
                  {event?.venue && (
                    <div className="my-5">
                      <div className="flex gap-2 items-center ">
                        <img
                          alt="location"
                          className="w-5 h-auto"
                          src="/event/location.png"
                        />
                        <div className="text-sm text-[#625FFB] font-bold">
                          location
                        </div>
                      </div>
                      <div className="font-extralight text-2xl">
                        {event?.venue}
                      </div>
                    </div>
                  )}
                  {event?.eventWebsite && (
                    <div className="my-5">
                      <div className="flex gap-2 items-center ">
                        <img
                          alt="globe"
                          className="w-5 h-auto"
                          src="/event/globe.png"
                        />
                        <div className="text-sm text-[#625FFB] font-bold">
                          {event?.eventWebsite}
                        </div>
                      </div>
                      <div className="font-extralight text-2xl text-blue-400">
                        <a href={event?.eventWebsite} target="_blank">
                          {event?.eventWebsite}
                        </a>
                      </div>
                    </div>
                  )}
                </div>
                {/* need to remove content from here */}
                {/* <div className="text-sm">
                <p>
                  <strong>**** In-Person Only Event *****</strong>
                </p>
                <p>
                  AzConf is Asia’s largest community-driven Microsoft Azure
                  Cloud technology event which brings together everyone in the
                  dev/IT landscape, world-leading speakers, industry leaders,
                  large customers, partners, and thousands of delegates and
                  offers an in-depth technology learning and ideal professional
                  networking environment for all attendees. The AzConf offers
                  cutting-edge technology content, in both business and
                  technology arenas, and as such represents a perfect learning
                  and professional opportunity.
                </p>
                <p>
                  Across the two-day of immersive technology learning, attendees
                  will get to learn from hundreds of educational sessions,
                  dozens of real-life case studies, and panel discussions,
                  delivered by 100+ world-className experts from across the world.
                </p>
                <p>Themes of AzConf 2022</p>
                <ul>
                  <li>App &amp; Infra Modernization</li>
                  <li>Data &amp; AI innovation</li>
                  <li>
                    Developer 2.0 (Emerging Trends, Developer Productivity,
                    Upskilling, Adopting Change)
                  </li>
                  <li>Hybrid Cloud (Edge to Cloud)</li>
                  <li>Future is Now– Quantum, Blockchain, IoT, Meta etc.</li>
                </ul>
                <p>
                  We pride ourselves about driving community-based learning.
                  AzConf is a platform where tech enthusiasts and experts can
                  connect on a live platform, network, and learn from first-hand
                  experiences.
                  <br />
                  &nbsp;
                </p>
                <p>&nbsp;</p>
                <p>
                  AzConf 2022 will feature keynotes from Microsoft executives
                  and expert talks from notable members of the community.&nbsp;
                </p>
                <p>
                  Speaker(s) of all skill levels is welcome. At AzConf, we want
                  to create a safe space for new speakers to learn and grow.
                  Under the guidance of volunteers and MVPs, we will mentor new
                  speakers and provide them the opportunity to present at this
                  global conference.
                </p>
                <p>
                  Please note that all selected speakers must follow our Code of
                  Conduct, which can be found on our event web page
                </p>
                <p>
                  <br />
                  &nbsp;
                </p>
              </div> */}
                <ReactQuill
                  theme="snow"
                  modules={{ toolbar: false }}
                  value={event?.description}
                  readOnly={true}
                />
              </div>
              <div
                className={`bg-white p-3  ${
                  !showSubmitField ? "md:w-1/2" : "my-10"
                }`}
              >
                <div className="flex justify-center items-center ">
                  <div className="text-xl font-medium w-[68%]">
                    Call For Speakers
                  </div>
                  <div className="text-xs w-[32%] bg-[#ed5565] text-center text-white font-semibold rounded-full p-1">
                    {calculateDates(event?.eventCallForSpeakers?.endDate)}
                  </div>
                </div>
                <div className="border-b border-t my-5 pb-5 ">
                  <div className="flex gap-24 my-5">
                    <div>
                      <div className="text-sm text-[#625FFB] font-bold">
                        Call opens at{" "}
                        {formatTime(event?.eventCallForSpeakers?.startDate)}
                      </div>
                      <div className="font-extralight text-2xl font-normal">
                        {formatDate(event?.eventCallForSpeakers?.startDate)}
                      </div>
                    </div>
                    <div>
                      <div className="text-sm text-[#625FFB] font-bold">
                        Call closes at{" "}
                        {formatTime(event?.eventCallForSpeakers?.endDate)}
                      </div>
                      <div className="font-extralight text-2xl font-normal">
                        {formatDate(event?.eventCallForSpeakers?.endDate)}
                      </div>
                    </div>
                  </div>
                  <div className="text-xs">
                    <p>
                      Call closes in
                      <strong> {event?.timeZone}</strong> timezone.
                    </p>
                    <p className="my-1">
                      {`Closing time in your timezone (${
                        Intl.DateTimeFormat().resolvedOptions().timeZone
                      }) is`}
                      <strong>
                        {" "}
                        {formatDate(event?.eventCallForSpeakers?.endDate)}{" "}
                        {formatTime(event?.eventCallForSpeakers?.endDate)}.
                      </strong>
                    </p>
                  </div>
                </div>
                <ReactQuill
                  className="my-4"
                  theme="snow"
                  modules={{ toolbar: false }}
                  value={event?.eventCallForSpeakers?.description}
                  readOnly={true}
                />

                {!(
                  event?.eventCallForSpeakers?.fullExpensesDetails == null ||
                  event?.eventCallForSpeakers?.travelExpenses == null ||
                  event?.eventCallForSpeakers?.accommodationExpenses == null
                ) && (
                  <div className="border-b border-t my-5 pb-5 text-md">
                    <div className="flex flex-row gap-2 my-5">
                      {event?.eventCallForSpeakers?.travelExpenses && (
                        <div className="flex flex-col">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z"
                            />
                          </svg>
                          Travel
                          <span>
                            <strong>expenses covered</strong>
                          </span>
                        </div>
                      )}
                      {event?.eventCallForSpeakers?.accommodationExpenses && (
                        <div className="flex flex-col">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z"
                            />
                          </svg>
                          Accommodation
                          <span>
                            <strong>expenses covered</strong>
                          </span>
                        </div>
                      )}
                      {event?.eventCallForSpeakers?.fullExpenses && (
                        <div className="flex flex-col">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z"
                            />
                          </svg>
                          Event Fee
                          <span>
                            <strong>free for speakers</strong>
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="text-sm">
                      {event?.eventCallForSpeakers?.fullExpensesDetails}
                    </div>
                  </div>
                )}

                {/* need to remove content from here */}
                {/* <div className="text-sm">
                <p>
                  At AzConf, we strive to create an experience for our community
                  of enthusiasts and experts to learn, share and thrive. But we
                  need you! We couldn't pull this off without our fantastic
                  speakers.
                </p>
                <p>
                  We encourage you to submit your top presentation on the topics
                  you are most passionate about. You can submit session
                  proposal(s) across a variety of formats- Interactive talks.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>Format and duration</strong>
                </p>
                <p>
                  <strong>Interactive talks</strong>: 50 minutes
                </p>
                <p>
                  <strong>Here are some suggestions for topics:</strong>
                  <br />
                  &nbsp;
                </p>
                <ul>
                  <li>Programming Languages e.g C#/Java/Python/Go etc</li>
                  <li>Architecture &amp; Design</li>
                  <li>Serverless and Cloud-Native</li>
                  <li>Micro- Services / Event-Driven solutions</li>
                  <li>Cloud/Kubernetes / Containers/ K3S/ Open Source</li>
                  <li>Mobile/Web</li>
                  <li>Data Science, AI, and Machine Learning</li>
                  <li>Edge, IoT, AR/VR, and hardware</li>
                  <li>Non-Tech&nbsp; &nbsp;</li>
                </ul>
                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•
                  Ethics in software development
                </p>
                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•
                  Modern development practices
                </p>
                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•
                  Testing
                </p>
                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•
                  DevOps
                </p>
                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•
                  Tools for software development
                </p>
                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•
                  Software methodology and process
                </p>
                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•
                  Teams, people, roles, and how we work.
                </p>
                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•
                  Product development
                </p>
                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•
                  Project and incident postmortems
                </p>
                <p>&nbsp;</p>
                <p>
                  We are accepting proposals until{" "}
                  <strong>31st August 2022</strong>. Please consider our
                  recommendations below as you make session proposals. Submitted
                  sessions will be shortlisted and we will reach out to selected
                  speakers by 15th September 2022.
                </p>
                <p>
                  Selected speakers will be invited to present the papers at
                  AzConf, October 20- 21’ 2022. Details regarding the final
                  agenda, schedule, pre-event practice dates, etc., will be
                  shared with the selected speakers.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>Recommendations for session proposals:</strong>
                </p>
                <p>&nbsp;</p>
                <ul>
                  <li>
                    Preferably submit advanced/expert talks (levels 300-400)
                    sharing your in-depth subject understanding and/or detailed
                    technical overview of the product/technology features. If
                    proposing basic/ intermediate talks (levels 100-200) that
                    cover the concepts, functions, features, or benefits of the
                    technology, we recommend focusing on currently released or
                    upcoming technologies.
                  </li>
                  <li>
                    Keep your target audience in mind. Write a descriptive, fun,
                    and enticing title that will instantly connect with the
                    audience.
                  </li>
                  <li>
                    Plan to make a presentation that is new, unique, or
                    significantly refreshed from previous presentation(s)&nbsp;
                  </li>
                  <li>
                    Focus on a solution-oriented approach. Audiences are keen to
                    see you dive into an interesting problem and then discuss
                    your solution. Kindly refrain from marketing/sales-related
                    content in your presentations.
                  </li>
                  <li>
                    Share your past speaking experiences, if any (session
                    recordings etc.) under Notes.&nbsp;
                  </li>
                  <li>
                    Include live demo(s) for technical sessions; historically,
                    sessions with strong demos have received higher audience
                    engagement.
                  </li>
                  <li>
                    Sales pitches, vague abstracts, content wholly unrelated to
                    cloud won't be considered for evaluation
                  </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                  Also, the AzConf organizers reserve the right to select
                  specific sessions based on other criteria or preferences, as
                  needed.
                </p>
                <p>
                  ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                </p>
                <p>
                  <strong>Your advantages as a speaker</strong>
                </p>
                <p>
                  To stand in front of a group of people as a speaker and pass
                  on one's knowledge is a challenge - but also an award.
                  Teaching something to others ultimately leads to even more
                  knowledge about a topic (teach to learn). We want you to be a
                  speaker at our conferences and show you here just only some
                  advantages you have besides learning:
                </p>
                <p>
                  <strong>Your own presentation platform</strong>
                </p>
                <p>
                  Present yourself with your lectures to a great community,
                  share your knowledge and increase your profile.
                </p>
                <p>
                  <strong>Free conference visit &amp; catering</strong>
                </p>
                <p>
                  As a speaker, you will receive free access to the conference
                  for the entire duration of the event. Balanced and varied
                  catering at the conference is also included.
                </p>
                <p>
                  <strong>Networking</strong>
                </p>
                <p>
                  Benefit from various networking opportunities, exchange ideas
                  with other speakers, participants and exhibitors and make new
                  contacts and business opportunities.
                </p>
                <p>
                  ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                </p>
                <p>
                  <strong>TERMS AND CONDITIONS</strong>
                </p>
                <ul>
                  <li>
                    Biography along with photo and video interviews to be
                    published and promoted on the following but not limited to,
                    event website, brochure, agenda, marketing mailers,
                    newsletters, press releases, social media, post-event
                    reports, future events, and references.
                  </li>
                  <li>
                    AzConf retains the right to change the venue, dates,
                    program, speakers and participants of the event and/or to
                    cancel the event.
                  </li>
                  <li>
                    In no circumstances shall we bear liability for any damage
                    or loss, which might occur to persons and/or properties
                    during the event, including specifically disturbances of
                    possession and all commercial losses.
                  </li>
                  <li>
                    AzConf may only use an individual attendee’s personal
                    information for the purpose of sending to the individual
                    attendee’s details of its services or those offered its
                    marketing partners or members of its group.
                  </li>
                  <li>
                    AzConf may also disclose the event, whether in photographic,
                    audio or audiovisual format, without any limitation and in
                    any format. The attendees hereby give up any privacy right
                    they might have due to their presence at the event and
                    accept not to enter into any legal proceedings against us
                    arising from our use of such material.
                  </li>
                </ul>
                <p>
                  <br />
                  &nbsp;
                </p>
              </div> */}
                {!isAuthenticated && isCfsEnded && (
                  <div className="mt-8">
                    <button
                      type="submit"
                      onClick={() =>
                        navigate(
                          `/signin?returnUrl=${encodeURIComponent(
                            `/cfs/${slugUrl}`
                          )}`
                        )
                      }
                      className="bg-blue-700 text-white font-bold py-2 px-4 w-full rounded hover:bg-blue-600"
                    >
                      Login to Submit Session
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showSubmitField && user.isProfileComplete === false && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-10">
          <div
            role="status"
            className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
          >
            <div className="flex flex-col bg-white p-4 align-center my-4 p-4">
              <p className="text-center">Please complete your User Profile</p>
              <button
                onClick={() => {
                  window.open("/speaker/user-profile", "_blank");
                }}
                className="flex flex-row items-center gap-5 justify-center p-2 m-4 text-sm font-medium focus:outline-none bg-[#1ab394] hover:bg-gray-100 hover:text-white dark:text-white dark:hover:text-white dark:hover:bg-[#08745f]"
                type="button"
              >
                Go to User Profile
              </button>
              <p>
                If you already updated{" "}
                <button
                  className="text-blue-800"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  click here
                </button>{" "}
                to refresh
              </p>
            </div>
            {/* <HashLoader color={'white'} loading={loading} size={50}/> */}
          </div>
        </div>
      )}
      {loading && (
        <div className="fixed inset-0 bg-black bg-opacity-90 z-10">
          <div
            role="status"
            className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
          >
            <HashLoader color={"white"} loading={loading} size={50} />
          </div>
        </div>
      )}
    </div>
  );
};

export default CfsPage;
