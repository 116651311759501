import axios from "axios";
import { SessionConfig } from "../config/SessionConfig";
import { SpeakerInviteConfig } from "../config/SpeakerInviteConfig";

const axiosInstance = axios.create({
  baseURL:
    process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_VERSION,
  headers: {
    "Content-Type": "application/json",
  },
});

const SpeakerInviteService = {
    getSpeakerInvite: async (inviteLink: any, userId:any) => {
    try {
      const response = await axiosInstance.get(
        SpeakerInviteConfig.getSpeakerInvite+'?inviteLink='+inviteLink+'&userId='+userId,
      );
      if (response.status == 200 || response.status == 204) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },

  postSpeakerInvite:async (acceptInviteModel: any) => {
    try {
      const response = await axiosInstance.post(
        SpeakerInviteConfig.postSpeakerInvite,
        acceptInviteModel
      );
      if (response.status == 200 || response.status == 204) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },
};

export default SpeakerInviteService;
