import React from "react";
import Header from "../Header/Header";
import "./Home.scss";
import HeadingButton from "../../../shared/HeadingButton";

const Home: React.FC = () => {
  return (
    <div id="Home" className="min-h-screen  font-open_sans mt-36">
      `
      <div className="absolute top-0 right-0 h-[90vh] w-[24%] md:bg-[#625FFB] rounded-bl-[80px]"></div>
      <div className="absolute mx-4 mx:mx-10 bottom-16 ">
        <img src="/home/Pattern.svg" />
      </div>
      <div className="md:flex justify-center items-center mx-9 md:mx-40 mt-14 mr-10 ">
        <div className="md:w-1/2">
          <HeadingButton title={"Home"} />
          <div className="my-6">
            <div className="text-2xl md:text-5xl font-normal leading-9 my-3">
              Revolutionize<span className="font-bold"> Call for</span>
              <div className="text-2xl md:text-5xl font-normal leading-9 my-6">
                <span className="font-bold">Papers,</span> Schedule, and
              </div>
            </div>
            <div className="font-bold text-2xl md:text-5xl text-[#625FFB] my-6">
              Speaker Management
            </div>
          </div>
          <div className="text-base md:text-xl my-6">
            Effortless, secure, and Loved by speakers.
          </div>
          <a href="/organizer/event-list" className="cursor-pointer">
            <div className="flex gap-3 md:gap-8 my-10">
              <button className="bg-[#303030] cursor-pointer font-semibold text-xs md:text-base p-4 rounded-full text-white">
                START WITH YOUR EVENT
              </button>
            </div>
          </a>
        </div>
        <div className="md:w-1/2 z-10">
          <img className="rounded-lg" src="/about/about.png" />
        </div>
      </div>
    </div>
  );
};

export default Home;
