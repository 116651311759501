export const URLConfig = {
  eventYear: "2023",
  apiURL: process.env.REACT_APP_BASEURL,
  login: "User?",
  register: "User",
  speakerNominate: "Session/PostNewSession",
  userAndSpeakerNominate: "Session/PostNewSpeakerSession",
  getSessions: "Session/GetSessionsByUserId",
  getCaptcha: "User/getCaptcha",
  isMail: "User/isMail?",
  mailSend: "User/mailasend?",
  forgetPassword: "User/forgetPassword?",
  getSasToken: "Blob/GetSASToken?",
  AcceptSession: "Session/AcceptSession?",
  getEventSessions: "Event/GetEventDashboard",
  getSessionsBySessionId: "Session/GetSessionsBySessionId",
  updateSessionStatus: "Session/UpdateStatus",
  getAllSpeakers: "Speaker/GetAllSpeakers",
  getSpeaker: "Speaker/GetSpeakerById?",
  sessionTimeUpdate: "Session/UpdateSession",
};


