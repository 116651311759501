import React, { FC } from "react";
import "./Layout.scss";

import Resources from "../../components/Website/Resources/Resources";
import Pricing from "../../components/Website/Pricing/Pricing";
import About from "../../components/Website/About/About";
import Footer from "../../components/Website/Footer/Footer";
import Home from "../../components/Website/Home/Home";
import Header from "../../components/Website/Header/Header";

interface LayoutProps {}

const Layout: FC<LayoutProps> = () => (
  <div className="Layout" data-testid="Layout">
    <Header bgColor={"bg-[#625ffb]"} textColor={"text-white"} />
    <Home />
    {/* <About /> */}
    {/* <Resources />
    <Pricing /> */}
    <Footer />
  </div>
);

export default Layout;
