import { UUIDGenerator } from "../../../helper/Guid";

export const formatEventDates = (startDate: string | undefined, endDate: string | undefined): string => {
    const isValidDate = (dateStr: string | undefined): boolean => {
      if (!dateStr) return false;
      const date = new Date(dateStr);
      return !isNaN(date.getTime());
    };
  
    const parseDate = (dateStr: string): Date => {
      return new Date(dateStr);
    };
  
    const formatDate = (date: Date): string => {
      const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', year: 'numeric' };
      return date.toLocaleDateString('en-GB', options).replace(/ /g, ' ');
    };
  
    if (!isValidDate(startDate) || !isValidDate(endDate)) {
      return 'Invalid date';
    }
  
    const start = parseDate(startDate!);
    const end = parseDate(endDate!);
  
    if (start.getTime() === end.getTime()) {
      return formatDate(start);
    }
  
    return `${formatDate(start)} - ${formatDate(end)}`;
  };

  export const convertToSpeakerData = (user,speakers)=>{
    var speakerData = {
          tagLine: speakers?.tagLine ?? user?.userProfile?.tagLine,
          biography: speakers?.biography ?? user?.userProfile?.biography,
          photoPath: speakers?.photoPath ?? user?.userProfile?.photoUrl,
          tShirtSize: speakers?.tShirtSize,
          speakersType: speakers?.speakersType,
          previousTalks: speakers?.previousTalks,
          companyLogo: speakers?.companyLogo ?? null ,
          companyName: speakers?.companyName,
    }
    return speakerData;
  }

  export const convertToAcceptSpeakerInviteModel = (event, session, userData, speakerData, inviteLink) =>{
    var inviteAcceptModel ={
      session:{
        "id": session?.id,
        "eventId": session?.eventId,
        "modifiedBy": userData?.id,
        "modifiedOn": new Date().toISOString(),
      },
      speaker:{
        "id": speakerData?.id ?? UUIDGenerator.generateUUID(),
        "userId": userData?.id,
        "eventId": session?.eventId,
        "tagLine": speakerData?.tagLine,
        "biography": speakerData?.biography,
        "photoPath": speakerData?.photoPath,
        "tShirtSize": speakerData?.tShirtSize,
        "speakersType": speakerData?.speakersType,
        "previousTalks": speakerData?.previousTalks,
        "companyLogo": speakerData?.companyLogo,
        "companyName": speakerData?.companyName,
        "isSessionOwner": false,
        "createdBy": speakerData?.createdBy ?? userData?.id,
        "createdOn": speakerData?.createdOn ?? new Date().toISOString(),
        "modifiedBy": (speakerData?.createdOn !== null && speakerData?.createdOn !== undefined && speakerData?.createdOn !== "") ? userData?.id : null,
        "modifiedOn": (speakerData?.createdOn !== null && speakerData?.createdOn !== undefined && speakerData?.createdOn !== "") ? new Date().toISOString() : null,
      },
      inviteLink : inviteLink
    };
    return inviteAcceptModel; 
  }
  