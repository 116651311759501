import React, { FC, useContext, useEffect, useState } from "react";
import "./ListEvents.scss";
import EventService from "../../../services/eventService";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { HashLoader } from "react-spinners";

interface IEvent {
  id: string;
  eventName: string;
  eventStartDate: string;
  eventEndDate: string;
  timeZone: string;
  description: string | null;
  isActive: boolean;
  sessions: any[];
  eventDetails: any;
  eventCFS: any;
}

interface ListEventsProps {}

const ListEvents: FC<ListEventsProps> = () => {
  const [eventsList, setEventsList] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      try {
        const response = await EventService.getEventbyUserId(auth?.userId);
        setEventsList(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error("Error fetching events");
      }
    };
    fetchEvents();
  }, []);

  const navigateToEventCreation = () => {
    navigate("/organizer/event-creation");
  };

  const navigateToEvent = (eventId: string, page: string) => {
    navigate(`organizer/${page}/${eventId}`);
  };

  const handleDeleteEvent = async (eventId: string) => {
    try {
      await EventService.deleteEvent(eventId);
      setEventsList(eventsList.filter((event) => event.id !== eventId));
      toast.success("Event deleted successfully");
    } catch (error) {
      toast.error("Error deleting event");
    }
  };

  const filteredEvents = eventsList.filter(
    (event) =>
      event.eventName &&
      event.eventName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const formatDate = (dateString) => {
    const options: any = { day: "2-digit", month: "short", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };
  let daysDifference;
  const calculateEventDaysDifference = (eventDate) => {
    const currentDate: any = new Date();
    const eventDateObj: any = new Date(eventDate);
    const timeDifference = eventDateObj - currentDate;
    daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
  };

  const getCFSStatus = (startDate, endDate) => {
    const currentDate = new Date();
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    if (currentDate < startDateObj) {
      return "Not Yet Started";
    } else if (currentDate >= startDateObj && currentDate <= endDateObj) {
      return "Open";
    } else {
      return "Closed";
    }
  };

  return (
    <div className="ListEvents" data-testid="ListEvents">
      <div className="flex justify-between items-center mb-4">
        <div className="text-2xl font-bold">My Events</div>
      </div>
      <div className="flex justify-between items-center mb-4">
        <div className="w-3/5 my-3 flex justify-center gap-2 items-center rounded-full text-black bg-white ">
          <div>
            <img className="ml-3" src="/event/search.svg" />
          </div>
          <input
            className="outline-none w-full text-base placeholder:text-sm h-full py-2 mx-2 bg-white rounded-full placeholder:text-[#888] focus:outline-none"
            type="text"
            placeholder="Search For Events"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <button
          onClick={navigateToEventCreation}
          className="px-5 py-2 text-white rounded-full bg-[#625FFB]"
        >
          + New Event
        </button>
      </div>

      <div className="w-full flex flex-col">
        <div className="w-full flex text-base bg-[#FF99000D] shadow-sm rounded-md p-4 text-black font-bold">
          <div className="w-[10%] text-left">S No</div>
          <div className="w-2/3 text-left ml-5">Event Name</div>
          <div className="w-1/3 text-left">Status</div>
          <div className="w-1/3 text-left">Event Date</div>
          <div className="w-1/3 text-left ml-5">Call For Speakers</div>
          <div className="w-1/3 text-right mr-4">Action</div>
        </div>

        <div>
          {filteredEvents.map((event, index) => (
            <a href={`/organizer/${event.id}`} className="">
              <div
                key={event.id}
                className="w-full flex text-sm px-4  rounded-md gap-2  p-2  items-center bg-[#f3f3f4] shadow-sm my-3"
              >
                <div className="w-[10%] text-left">{index + 1}</div>
                <div className="w-2/3 text-[#625ffb] font-bold">
                  {event.eventName}
                </div>
                <div className={`w-1/3 rounded-full font-semibold `}>
                  <div
                    className={` px-2 flex justify-center items-center  text-sm ${
                      event.isActive
                        ? "bg-[#75D45E4D] w-14 text-[#2E771C] rounded-full font-semibold"
                        : "bg-[#CCCAC64D] w-16 text-[#303030] rounded-full font-semibold"
                    }`}
                  >
                    {event.isActive ? "Active" : "Inactive"}
                  </div>
                </div>
                <div className="w-1/3">
                  {formatDate(event.eventStartDate)}
                  <div
                    className={`text-sm mt-1 font-semibold ${
                      calculateEventDaysDifference(event.eventStartDate) > 0
                        ? "text-[#159021]"
                        : "text-red-500"
                    }`}
                  >
                    {calculateEventDaysDifference(event.eventStartDate) > 0
                      ? `In ${daysDifference} days`
                      : `Ended`}
                  </div>
                </div>
                <div className="w-1/3">
                  {formatDate(event.eventCallForSpeakers.startDate)}-{" "}
                  {formatDate(event.eventCallForSpeakers.endDate)}
                  <div
                    className={`text-sm mt-1 font-semibold ${
                      getCFSStatus(
                        event.eventCallForSpeakers.startDate,
                        event.eventCallForSpeakers.endDate
                      ) === "Open"
                        ? "text-[#159021]"
                        : "text-red-500"
                    }`}
                  >
                    {getCFSStatus(
                      event.eventCallForSpeakers.startDate,
                      event.eventCallForSpeakers.endDate
                    )}
                  </div>
                </div>
                <div className="w-1/3 flex justify-end gap-2">
                  <a href={`/organizer/dashboard/${event.id}`}>
                    <button>
                      <div className="rounded-full h-10 w-10 flex items-center justify-center ">
                        <img src="/event/dashboard.svg" />
                      </div>
                    </button>
                  </a>
                  <a href={`/organizer/edit-event/${event.id}`}>
                    <button>
                      <div className="rounded-full h-10 w-10 flex items-center justify-center ">
                        <img src="/event/edit.svg" />
                      </div>
                    </button>
                  </a>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
      {loading && (
        <div className="absolute inset-0 bg-black bg-opacity-80 z-10">
          <div
            role="status"
            className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
          >
            <HashLoader color={"white"} loading={loading} size={50} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ListEvents;
