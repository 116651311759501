import { FC, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./SideBar.scss";
import ConfirmationDialog from "../../../utils/ConfirmationDialog/ConfirmationDialog";
import { useConfirmationDialog } from "../../../utils/ConfirmationDialog/useConfirmationDialog";
import { AuthContext } from "../../../context/AuthContext";
import { Role } from "../../../enums/Roles";

interface SideBarProps {
  onCloseSidebar: () => void;
}

const SideBar: FC<SideBarProps> = ({ onCloseSidebar }) => {
  const [expanded, setExpanded] = useState(null);
  const [subMenus, setSubMenus] = useState(null);
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const { dialog, showDialog, closeDialog } = useConfirmationDialog();

  useEffect(() => {
    const pathname = window.location.pathname;
    const parts = pathname.split("/").filter((part) => part !== "");
    const menu = parts[0];
    const subMenu = parts[1];
    setExpanded(menu);
    setSubMenus(subMenu);
  }, []);

  const handleSubMenus = (subMenuName) => {
    setSubMenus(subMenuName);
  };

  const handleAccordionToggle = (accordion) => {
    setExpanded(expanded === accordion ? null : accordion);
  };

  const handleSignout = () => {
    showDialog({
      title: "Sign Out",
      description: "Are you sure you want to sign out?",
      onConfirm: () => {
        localStorage.clear();
        navigate("/signin");
      },
    });
  };
  return (
    <div>
      <nav className="sidenav md:w-[15%] sm:w-full md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden md:shadow-xl md:bg-[#36364c] flex flex-wrap flex-col items-start justify-between relative z-10 bg-gray-50 dark:bg-[#303030] h-screen">
        <ul className="font-medium w-[100%]">
          <button
            onClick={onCloseSidebar}
            className="md:hidden absolute text-right text-red-500  z-50  top-5 px-4  rounded-full p-2 right-3 text-gray-500 hover:text-white"
          >
            &#x2715;
          </button>
          <li>
            <div className="flex flex-column justify-between relative shadow-md">
              <Link
                to="/"
                className="text-blue-600 font-bold bg-[#303030] w-full text-lg hover:text-blue-800"
              >
                <img src="/login/logo_color.svg"></img>
              </Link>
            </div>
          </li>
          {auth?.role === Role.Admin && (
            <div id="accordion-collapse" data-accordion="collapse">
              <li>
                <button
                  type="button"
                  className={`accordion-label flex justify-between items-center  w-full p-4 font-medium rtl:text-right gap-3 dark:text-white hover:bg-gray-100 dark:hover:bg-[#3f3f3f] group
                `}
                  aria-expanded={expanded === "organizer"}
                  onClick={() => handleAccordionToggle("organizer")}
                  aria-controls="accordion-collapse-body-1"
                >
                  <div className="flex gap-4">
                    <svg
                      className={` w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white group-hover:fill-white`}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill={`${
                        expanded === "organizer" ? "#625ffb" : "currentColor"
                      }`}
                      viewBox="0 0 18 18"
                    >
                      <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
                    </svg>
                    <span
                      className={`whitespace-nowrap ${
                        expanded === "organizer"
                          ? " group-hover:text-white"
                          : ""
                      }`}
                    >
                      Organizer
                    </span>
                  </div>
                  {expanded === "organizer" ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-6 group-hover:stroke-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m4.5 15.75 7.5-7.5 7.5 7.5"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-6 group-hover:stroke-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m19.5 8.25-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  )}
                </button>
              </li>
              <div
                id="accordion-collapse-body-1"
                className={`accordion-content ${
                  expanded === "organizer" ? "expanded bg-[#303030]" : ""
                }`}
                aria-labelledby="accordion-collapse-heading-1"
              >
                <ul className="space-y-2 p-3">
                  <Link to="/organizer/event-list">
                    {" "}
                    <li className="flex items-center dark:hover:bg-[#3f3f3f] hover:rounded-md hover:cursor-pointer px-2">
                      {subMenus === "event-list" && (
                        <img src="/event/active-menu.svg" />
                      )}

                      <div
                        className={`block px-4 py-2 rounded-md text-blueGray-700   text-white ${
                          subMenus === "event-list" ? "" : "ml-3"
                        }`}
                      >
                        Event List
                      </div>
                    </li>
                  </Link>
                  <Link to="/organizer/event-creation">
                    {" "}
                    <li className="flex items-center dark:hover:bg-[#3f3f3f] hover:rounded-md hover:cursor-pointer px-2">
                      {subMenus === "event-creation" && (
                        <img src="/event/active-menu.svg" />
                      )}

                      <div
                        className={`block px-4 py-2  rounded-md text-blueGray-700  text-white ${
                          subMenus === "event-creation" ? "" : "ml-3"
                        }`}
                      >
                        New Event
                      </div>
                    </li>
                  </Link>
                </ul>
              </div>
            </div>
          )}
          <div id="accordion-collapse" data-accordion="collapse">
            <li>
              <button
                type="button"
                className={`accordion-label flex justify-between items-center md:justify-between justify-center w-full p-4 font-medium rtl:text-right gap-3 dark:text-white hover:bg-gray-100 dark:hover:bg-[#3f3f3f] group
      
                `}
                aria-expanded={expanded === "speaker"}
                onClick={() => handleAccordionToggle("speaker")}
                aria-controls="accordion-collapse-body-2"
              >
                <div className="flex gap-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill={`${
                      expanded === "speaker" ? "#625ffb" : "currentColor"
                    }`}
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className={`flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white group-hover:fill-white`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 1 1 0-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 0 1-1.44-4.282m3.102.069a18.03 18.03 0 0 1-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 0 1 8.835 2.535M10.34 6.66a23.847 23.847 0 0 0 8.835-2.535m0 0A23.74 23.74 0 0 0 18.795 3m.38 1.125a23.91 23.91 0 0 1 1.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 0 0 1.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 0 1 0 3.46"
                    />
                  </svg>
                  <span
                    className={`flex-1 whitespace-nowrap ${
                      expanded === "speaker" ? " group-hover:text-white " : ""
                    }`}
                  >
                    Speakers
                  </span>
                </div>
                {expanded === "speaker" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6 group-hover:stroke-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m4.5 15.75 7.5-7.5 7.5 7.5"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m19.5 8.25-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                )}
              </button>
            </li>
            <div
              id="accordion-collapse-body-1"
              className={`accordion-content ${
                expanded === "speaker" ? "expanded " : ""
              }`}
              aria-labelledby="accordion-collapse-heading-1"
            >
              <ul className="space-y-2 p-3">
                <Link to="/speaker/user-profile">
                  <li className="flex items-center dark:hover:bg-[#3f3f3f] hover:rounded-md hover:cursor-pointer px-2">
                    {subMenus === "user-profile" && (
                      <img src="/event/active-menu.svg" />
                    )}

                    <div
                      className={`block px-4 py-2 rounded-md text-white ${
                        subMenus === "user-profile" ? "" : "ml-3"
                      }`}
                    >
                      My Profile
                    </div>
                  </li>
                </Link>
                <Link to="/speaker/my-events">
                  <li className="flex items-center dark:hover:bg-[#3f3f3f] hover:rounded-md hover:cursor-pointer px-2">
                    {subMenus === "my-events" && (
                      <img src="/event/active-menu.svg" />
                    )}
                    <div
                      className={`block px-4 py-2 rounded-md text-blueGray-700 text-white ${
                        subMenus === "my-events" ? "" : "ml-3"
                      }`}
                    >
                      My Sessions
                    </div>
                  </li>
                </Link>
              </ul>
            </div>
          </div>
          {/* <div id="accordion-collapse" data-accordion="collapse">
            <li>
              <button
                type="button"
                className="accordion-label flex items-center md:justify-between justify-center w-full p-4 font-medium rtl:text-right gap-3 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                aria-expanded={expanded === "speaker"}
                onClick={() => handleAccordionToggle("speaker")}
                aria-controls="accordion-collapse-body-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 1 1 0-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 0 1-1.44-4.282m3.102.069a18.03 18.03 0 0 1-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 0 1 8.835 2.535M10.34 6.66a23.847 23.847 0 0 0 8.835-2.535m0 0A23.74 23.74 0 0 0 18.795 3m.38 1.125a23.91 23.91 0 0 1 1.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 0 0 1.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 0 1 0 3.46"
                  />
                </svg>
                <span className="flex-1 whitespace-nowrap">Speakers</span>
                {expanded === "speaker" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m4.5 15.75 7.5-7.5 7.5 7.5"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m19.5 8.25-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                )}
              </button>
            </li>
            <div
              id="accordion-collapse-body-2"
              className={`accordion-content ${
                expanded === "speaker" ? "expanded" : ""
              }`}
              aria-labelledby="accordion-collapse-heading-2"
            >
              <ul className="space-y-2 p-3">
                <li>
                  <Link
                    to="/auth/login"
                    className="block px-4 py-2 md:text-white md:hover:text-black rounded-md text-blueGray-700 hover:bg-blueGray-100 hover:bg-gray-100"
                  >
                    My Profile
                  </Link>
                </li>
                <li>
                  <Link
                    to="/auth/register"
                    className="block px-4 py-2 md:text-white md:hover:text-black rounded-md text-blueGray-700 hover:bg-blueGray-100 hover:bg-gray-100"
                  >
                    My Events
                  </Link>
                </li>
              </ul>
            </div>
          </div> */}
          <div>
            <li>
              <button
                type="button"
                className="accordion-label flex justify-between items-center md:justify-between  w-full p-4 font-medium rtl:text-right gap-3 dark:text-white hover:bg-gray-100 dark:hover:bg-[#3f3f3f]   group"
                onClick={() => {
                  handleSignout();
                }}
              >
                <div className="flex gap-4">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 18 16"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3"
                    />
                  </svg>
                  <span className="flex-1 whitespace-nowrap">Signout</span>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor hidden"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m4.5 15.75 7.5-7.5 7.5 7.5"
                  />
                </svg>
              </button>
            </li>
          </div>
        </ul>
      </nav>
      <div style={{ zIndex: 2000 }}>
        <ConfirmationDialog
          open={dialog.open}
          title={dialog.title}
          description={dialog.description}
          onClose={closeDialog}
          onConfirm={dialog.onConfirm}
        />
      </div>
    </div>
  );
};

export default SideBar;
