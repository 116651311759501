import "./App.css";
import AppRoutes from "./routes";
import { BrowserRouter as Router } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import DashBoardLayout from "./layout/DashBoardLayout/DashBoardLayout";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AuthProvider } from "./context/AuthContext";
import { HelmetProvider } from "react-helmet-async";
const helmetContext = {};
function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <HelmetProvider context={helmetContext}>
        <Router>
          <AuthProvider>
            <div className="" data-testid="Master">
              <DashBoardLayout>
                <AppRoutes />
              </DashBoardLayout>

              <Toaster />
            </div>
          </AuthProvider>
        </Router>
      </HelmetProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
