import { useLocation, Navigate, Outlet, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";

const Auth = ({ allowedRoles }) => {
  const { auth } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  console.log(location);

  useEffect(() => {
    if (!auth || auth.role===null || auth.role===undefined) {
      navigate(`/signin?returnUrl=${encodeURIComponent(location.pathname + location.search)}`, { state: { from: location } });
    }
  }, [location]);

  return allowedRoles.find((role) => auth?.role?.includes(role)) ? (
    <Outlet />
  ) : auth?.role ? (
    <Navigate to="/speaker/user-profile" state={{ from: location }} replace />
  ) : (
    <Navigate to={`/signin?returnUrl=${encodeURIComponent(location.pathname + location.search)}`} state={{ from: location }} replace />
  );
};

export default Auth;