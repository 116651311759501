import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../UserProfile";
import { setNestedValue } from "../../../../helper/ValidateForm";

interface TabProps {
  id: string;
  label: string;
  switchTab: number;
  isActive: boolean;
}

export default function TaglineBio() {
  const [activeTab, setActiveTab] = useState<number>(1);
  const [showSidebar, setShowSidebar] = useState(true);
  const navigate = useNavigate();
  const { user, setUser, errors, setErrors } = useContext(UserContext);
// const auth = useContext(ProfileContext);

useEffect(()=>{
//   console.log(auth);
},[])

  const handleTabClick = (tabNumber: number) => {
    setActiveTab(tabNumber);
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const mandatoryFields = [
    "userProfile.designation",
    "userProfile.tagLine",
    "userProfile.biography",
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevEvent) => {
      const updatedEvent = setNestedValue(prevEvent, name, value);
      console.log(updatedEvent);
      return updatedEvent;
    });
    if (mandatoryFields.includes(name)) {
      if (value === null || value === undefined || value === "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Field is required",
        }));
      } else {
        setErrors((prevErrors) => {
          const { [name]: _, ...rest } = prevErrors;
          return rest;
        });
      }
    }
  };

  const Tab: React.FC<TabProps> = ({ id, label, switchTab, isActive }) => {
    return (
      <li className={`me-2 ${activeTab}`} role="presentation">
        <button
          className={`inline-block p-4 ${isActive && "bg-white"}`}
          id={`${id}-tab`}
          data-tabs-target={`#${id}`}
          type="button"
          role="tab"
          aria-controls={id}
          aria-selected="false"
          onClick={() => handleTabClick(switchTab)}
        >
          {label}
        </button>
      </li>
    );
  };

  return (
    <div className="flex">
      <div className="flex-auto md:p-5 p-1">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block text-blueGray-600 text-xs font-semibold mb-2"
                htmlFor="designation"
              >
                Designation
              </label>
              <input
                type="text"
                onChange={handleChange}
                name="userProfile.designation"
                value={user?.userProfile?.designation}
                className="text-gray-700 text-sm  border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-[#7270fc]"
              
                placeholder="Enter your designation"
              />
              {errors["userProfile.designation"] && (
                <small className="text-red-500">Designation is required</small>
              )}
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block text-blueGray-600 text-xs font-semibold mb-2"
                htmlFor="tagline"
              >
                TagLine
              </label>
              <input
                type="text"
                onChange={handleChange}
                name="userProfile.tagLine"
                value={user?.userProfile?.tagLine}
               className="text-gray-700 text-sm  border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-[#7270fc]"
              
                placeholder="Enter your tagline"
              />
              {errors["userProfile.tagLine"] && (
                <small className="text-red-500">Tagline is required</small>
              )}
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block text-blueGray-600 text-xs font-semibold mb-2"
                htmlFor="biography"
              >
                Biography
              </label>
              <input
                type="text"
                onChange={handleChange}
                name="userProfile.biography"
                value={user?.userProfile?.biography}
               className="text-gray-700 text-sm  border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-[#7270fc]"
              
                placeholder="About yourself"
              />
              {errors["userProfile.biography"] && (
                <small className="text-red-500">Biography is required</small>
              )}
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block text-blueGray-600 text-xs font-semibold mb-2"
                htmlFor="expertise"
              >
                Area of Expertise
              </label>
              <input
                type="text"
                onChange={handleChange}
                name="userProfile.areaofExpertise"
                value={user?.userProfile?.areaofExpertise}
               className="text-gray-700 text-sm  border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-[#7270fc]"
              
                placeholder="Area of expertise"
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block text-blueGray-600 text-xs font-semibold mb-2"
                htmlFor="topics"
              >
                Topics
              </label>
              <input
                type="text"
                onChange={handleChange}
                name="userProfile.topics"
                value={user?.userProfile?.topics}
               className="text-gray-700 text-sm  border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-[#7270fc]"
              
                placeholder="Topics"
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block text-blueGray-600 text-xs font-semibold mb-2"
                htmlFor="location"
              >
                Location
              </label>
              <input
                type="text"
                onChange={handleChange}
                name="userProfile.location"
                value={user?.userProfile?.location}
               className="text-gray-700 text-sm  border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-[#7270fc]"
              
                placeholder="Enter your location"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
