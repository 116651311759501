import axios from "axios";
import { URLConfig } from "../config/URL.Config";
import { SessionConfig } from "../config/SessionConfig";
import { SpeakerConfig } from "../config/SpeakerConfig";

const axiosInstance = axios.create({
  baseURL:
    process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_VERSION,
  headers: {
    "Content-Type": "application/json",
  },
});

const SpeakerService = {
  getSpeakersByEventId: async (eventId: any) => {
    try {
      const response = await axiosInstance.get(
        SpeakerConfig.getSpeakersByEventId+'?eventId='+eventId,
      );
      if (response.status == 200 || response.status == 204) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },

  getSpeakerById: async(id: string) => {
    try {
      const response = await axiosInstance.get(
        SpeakerConfig.getSpeakerById + "?speakerId=" + id
      );
      if (response.status == 200 || response.status == 204) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },

  updateSpeakerDetails: async (speakerData : any) => {
    try{
      const response = await axiosInstance.put(
        SpeakerConfig.updateSpeakerDetails ,
        speakerData
      );
      if (response.status == 200 || response.status == 204) {
        return response.data;
      } else {
        return false;
      }
    }
    catch(error){
      return false;
    }
  }
};

export default SpeakerService;
