import { useState } from "react";

export const useConfirmationDialog = () => {
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    description: "",
    onConfirm: () => {},
  });

  const showDialog = ({ title, description, onConfirm }) => {
    setDialog({
      open: true,
      title,
      description,
      onConfirm: () => {
        onConfirm();
        closeDialog();
      },
    });
  };

  const closeDialog = () => {
    setDialog((prevState) => ({ ...prevState, open: false }));
  };

  return {
    dialog,
    showDialog,
    closeDialog,
  };
};
