import React, { FC, useContext, useEffect, useState } from "react";
import "./SpeakerSessionList.scss";
import { AuthContext } from "../../../context/AuthContext";
import { HashLoader } from "react-spinners";
import DashboardHeader from "../../Admin/DashboardHeader/DashboardHeader";
import { UserContext } from "../UserProfile/UserProfile";
import SessionsService from "../../../services/sessionService";
import { Role } from "../../../enums/Roles";
import SideBar from "../../Admin/SideBar/SideBar";
import { useNavigate } from "react-router-dom";

interface SessionListProps {}

const SpeakerSessionList: FC<SessionListProps> = () => {
  const { auth } = useContext(AuthContext);
  const [showSidebar, setShowSidebar] = useState(true);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<any>();
  const [sessionList, setSessionList] = useState<any>(null);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const fetchSessionData = async () => {
      setLoading(true);
      try {
        const data = await SessionsService.getSessionsByUserId(auth?.userId);
        setSessionList(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching session data:", error);
      }
    };
    fetchSessionData();
  }, [auth?.userId]);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const statusMapping = {
    0: "Nominated",
    1: "Accepted",
    2: "Accept Queue",
    3: "Decline Queue",
    4: "Declined",
    5: "Withdrawn"
  };

  const navigateToDetailSession = (sessionId: string) => {
    navigate(`/speaker/my-events/${sessionId}`);
  };

  return (
    <UserContext.Provider value={{ user, setUser, errors, setErrors }}>
      <div className="flex">
        {(auth?.role === Role.Admin || auth?.role === Role.User) && (
          <div
            className={` ${
              showSidebar &&
              (auth?.role === Role.Admin || auth?.role === Role.User)
                ? "md:w-[15%] w-[100%]"
                : "w-[0%]"
            }`}
          >
            {showSidebar &&
              (auth?.role === Role.Admin || auth?.role === Role.User) && (
                <div className="sidebar">
                  <SideBar onCloseSidebar={toggleSidebar} />
                </div>
              )}
          </div>
        )}
        {loading && (
          <div className="absolute inset-0 bg-black bg-opacity-80 z-10">
            <div
              role="status"
              className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
            >
              <HashLoader color={"white"} loading={loading} size={50} />
            </div>
          </div>
        )}

        <div
          data-testid="Layout"
          className={` ${
            showSidebar &&
            (auth?.role === Role.Admin || auth?.role === Role.User)
              ? "w-[0%] md:w-[85%]"
              : "md:w-[100%]"
          }`}
        >
          <div className="w-full">
            <div className="w-full">
              <DashboardHeader onCloseSidebar={toggleSidebar} />
            </div>
            <div className="w-full flex flex-col p-10">
              <div className="w-full flex text-base bg-[#FF99000D] shadow-sm rounded-md p-4 text-black font-bold">
                <div className="md:mx-5 text-center">S.No</div>
                <div className="md:w-3/6 w-3/6 text-left">Title</div>
                <div className="md:w-3/6 w-3/6 text-left">Event</div>
                <div className="w-1/6 text-center">Session Type</div>
                <div className="w-1/6 text-center ml-3">Status</div>
              </div>
              {sessionList && sessionList.length > 0 ? (
                sessionList.map((session: any, index) => (
                  <div key={index}>
                    <div className="w-full flex text-sm px-4 rounded-md gap-2 p-2 items-center bg-[#f3f3f4] shadow-sm my-3 hover:border hover:cursor-pointer"
                    onClick={() => navigateToDetailSession(session.id)}
                    >
                      <div className="md:mx-3 mx-1 md:px-5">{index + 1}</div>
                      <div className="md:w-3/6 w-3/6 mr-3 center text-black font-medium">
                        {session?.title || "N/A"}
                      </div>
                      <div className="md:w-3/6 w-3/6 mr-3 center text-black font-medium">
                        {session?.event?.eventName || "N/A"}
                      </div>
                      <div className="w-1/6 text-center text-black font-medium">
                        {session?.sessionType || "N/A"}
                      </div>
                      <div className="w-1/6 ml-3 text-center">
                        <button
                          className={`custom-font-size ${
                            statusMapping[session.sessionStatus] === "Nominated"
                              ? "badge badge-warning"
                              : statusMapping[session.sessionStatus] ===
                                "Accepted"
                              ? "badge badge-success"
                              : statusMapping[session.sessionStatus] ===
                                "Accept Queue"
                              ? "badge badge-info"
                              : statusMapping[session.sessionStatus] ===
                                "Withdrawn"
                              ? 'badge badge-info'
                              : statusMapping[session.sessionStatus] ===
                                "Decline Queue"
                              ? "badge badge-danger"
                              : "status-decline"
                          }`}
                        >
                          {statusMapping[session.sessionStatus] || "NIL"}
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="w-full flex justify-center text-base text-black font-medium py-4">
                  No session found
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </UserContext.Provider>
  );
};

export default SpeakerSessionList;
