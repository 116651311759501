import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../UserProfile";
import { setNestedValue } from "../../../../helper/ValidateForm";

interface TabProps {
  id: string;
  label: string;
  switchTab: number;
  isActive: boolean;
}

export default function SocialMedia() {
  const [activeTab, setActiveTab] = useState<number>(1);
  const [showSidebar, setShowSidebar] = useState(true);
  const navigate = useNavigate();
  const { user, setUser, errors, setErrors } = useContext(UserContext);
  // const auth = useContext(ProfileContext);

  useEffect(() => {
    //   console.log(auth);
  }, []);

  const handleTabClick = (tabNumber: number) => {
    setActiveTab(tabNumber);
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevEvent) => {
      const updatedEvent = setNestedValue(prevEvent, name, value);
      console.log(updatedEvent);
      return updatedEvent;
    });
  };

  const Tab: React.FC<TabProps> = ({ id, label, switchTab, isActive }) => {
    return (
      <li className={`me-2 ${activeTab}`} role="presentation">
        <button
          className={`inline-block p-4 ${isActive && "bg-white"}`}
          id={`${id}-tab`}
          data-tabs-target={`#${id}`}
          type="button"
          role="tab"
          aria-controls={id}
          aria-selected="false"
          onClick={() => handleTabClick(switchTab)}
        >
          {label}
        </button>
      </li>
    );
  };

  return (
    <div className="flex">
      <div className="flex-auto p-1 md:p-5">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block text-blueGray-600 text-xs font-semibold mb-2"
                htmlFor="linkedIn"
              >
                LinkedIn
              </label>
              <input
                type="text"
                name="userSocialMedia.linkedIn"
                onChange={handleChange}
                value={user?.userSocialMedia?.linkedIn}
               className="text-gray-700 text-sm  border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-[#7270fc]"
              
                placeholder="https://www.linkedin.com"
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block text-blueGray-600 text-xs font-semibold mb-2"
                htmlFor="twitter"
              >
                Twitter
              </label>
              <input
                type="text"
                name="userSocialMedia.twitter"
                onChange={handleChange}
                value={user?.userSocialMedia?.twitter}
               className="text-gray-700 text-sm  border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-[#7270fc]"
              
                placeholder="https://twitter.com"
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block text-blueGray-600 text-xs font-semibold mb-2"
                htmlFor="facebook"
              >
                Facebook
              </label>
              <input
                type="text"
                name="userSocialMedia.facebook"
                onChange={handleChange}
                value={user?.userSocialMedia?.facebook}
               className="text-gray-700 text-sm  border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-[#7270fc]"
              
                placeholder="https://www.facebook.com"
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block text-blueGray-600 text-xs font-semibold mb-2"
                htmlFor="instagram"
              >
                Instagram
              </label>
              <input
                type="text"
                name="userSocialMedia.instagram"
                onChange={handleChange}
                value={user?.userSocialMedia?.instagram}
               className="text-gray-700 text-sm  border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-[#7270fc]"
              
                placeholder="https://instagram.com"
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block text-blueGray-600 text-xs font-semibold mb-2"
                htmlFor="blog"
              >
                Blog
              </label>
              <input
                type="text"
                name="userSocialMedia.blog"
                onChange={handleChange}
                value={user?.userSocialMedia?.blog}
               className="text-gray-700 text-sm  border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-[#7270fc]"
              
                placeholder="https://blogs.com"
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block text-blueGray-600 text-xs font-semibold mb-2"
                htmlFor="companyWebsite"
              >
                Company Website
              </label>
              <input
                type="text"
                name="userSocialMedia.companyWebsite"
                onChange={handleChange}
                value={user?.userSocialMedia?.companyWebsite}
                className="text-gray-700 text-sm  border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-[#7270fc]"
                placeholder="https://microsoft.com"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
