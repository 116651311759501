import axios from "axios";
import { URLConfig } from "../config/URL.Config";
import { AuthConfig } from "../config/AuthConfig";

const axiosInstance = axios.create({
  baseURL:
    process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_VERSION,
  headers: {
    "Content-Type": "application/json",
  },
});

const AuthService = {
  login: async (userDetails: any) => {
    try {
      const response = await axiosInstance.get(
        `${URLConfig.login}email=${userDetails.email}&password=${userDetails.password}`
      );
      return response;
    } catch (error) {
      throw error.response.data.message;
    }
  },

  getCurruntUser: async (userId: any) => {
    try {
      const response = await axiosInstance.get(
        `${URLConfig.register}/GetUserByUserId?userId=${userId}`
      );
      return response;
    } catch (error) {
      throw error.response.data.message;
    }
  },

  userRegister: async (userDetails: any) => {
    try {
      const response = await axiosInstance.post(
        URLConfig.register,
        userDetails
      );
      return response;
    } catch (error) {
      throw error.response.data.message;
    }
  },

  googleUserData: async (accessToken: any) => {
    try {
      console.log(accessToken);
      const response = await axiosInstance.get(
        `${AuthConfig.getGoogleUserProfile}?accessToken=${accessToken}`
      );
      return response.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },

  logout: () => {
    localStorage.removeItem("token");
  },

  isAuthenticated: () => {
    return !!localStorage.getItem("auth");
    // return !!localStorage.getItem("token");
  },

  getAccessToken: () => {
    return localStorage.getItem("token");
  },

  getUserDetails: () => {
    return localStorage.getItem("userDetails");
  }, 
};

export default AuthService;
