export const EventConfig = {
    createEvent : "/Event/EventCreate",
    getEvents: "Event/GetAll",
    getEventByUserId : "/Event/GetEventsById?",
    getEventById : "Event?",
    eventDelete: "Event",
    updateEvent : "Event",
    suggestSlugURL: "/Event/SuggestSlugURL?eventName=",
    slugURLCheck: "Event/SlugURLCheck?slugURL=",
    getEventBySlugUrl: "Event/GetEventBySlugUrl?slugUrl=",
  };