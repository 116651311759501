import React, { useContext, useEffect, useRef, useState } from "react";
import "./signin.scss";
import AuthService from "../../services/authService";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the styles
import {
  GoogleLogin,
  GoogleOAuthProvider,
  TokenResponse,
  useGoogleLogin,
} from "@react-oauth/google";
import MicrosoftLogin from "react-microsoft-login";
import {
  GithubLoginButton,
  GoogleLoginButton,
  MicrosoftLoginButton,
} from "react-social-login-buttons";
import UserService from "../../services/userService";
import { AuthConfig } from "../../config/AuthConfig";
import { Providers } from "../../enums/Providers";
import { CircleLoader, ClipLoader, HashLoader } from "react-spinners";
import { AuthContext } from "../../context/AuthContext";

const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const [isLoginOpen, setIsLoginOpen] = useState(true);
  const [isForgetPassword, setIsForgetPassword] = useState(true);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // const from = queryParams.get('returnUrl') || '/speaker/user-profile';
  const from = (location.state?.from?.pathname ? location.state?.from?.pathname : queryParams.get('returnUrl') ? queryParams.get('returnUrl') : "/speaker/user-profile");
  const urlParams = new URLSearchParams(location.search);
  const code = urlParams.get("code");
  const hasFetched = useRef(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { auth, setAuth } = useContext(AuthContext);

  const saveUserData = (userData) => {
    setAuth({
      role: userData?.roleId.toString(),
      userId: userData?.id,
      userEmail: userData?.email,
      userFirstName: userData?.firstName,
      userlastName: userData?.lastName,
    });
  };

  useEffect(() => {
    const fromPage = location.pathname;
    console.log(fromPage);
    const fetchData = async () => {
      if (code && !hasFetched.current) {
        hasFetched.current = true;
        const url = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_VERSION}${AuthConfig.githubAccessToken}?code=${code}`;
        try {
          setLoading(true);
          const tokenResponse = await fetch(url, {
            method: "GET",
          });

          if (!tokenResponse.ok) {
            setLoading(false);
            throw new Error(
              `Request failed with status: ${tokenResponse.status}`
            );
          }

          const tokenData = await tokenResponse.json();
          if (tokenData?.isExists === false) {
            setLoading(false);
            navigate(`/consent-form?returnUrl=${encodeURIComponent(from)}`, {
              state: {
                email: tokenData?.email,
                name: tokenData?.name,
                providerId: Providers.GITHUB,
              },
            });
          }
          if (tokenData?.isExists === true) {
            const userData = await UserService.getUserbyUserId(
              tokenData?.userId
            );
            saveUserData(userData);
            setLoading(false);
            //navigate("/home");
            navigate(from, { replace: true });
          }
        } catch (error) {
          console.error("Error fetching the access token:", error);
        }
      }
    };
    fetchData();
  }, [code]);

  const toggleSignup = () => {
    setIsLoginOpen((prevState) => !prevState);
  };

  const toggleForgotPassword = () => {
    setIsForgetPassword((prevState) => !prevState);
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };
  const authHandler = async (err, data) => {
    const from = location.state?.from?.pathname || "/speaker/user-profile";
    setLoading(true);
    if (data !== undefined && data !== null) {
      var isAuthUserExist = await UserService.checkAuthUserExist(
        data?.account?.username,
        Providers.MICROSOFT
      );
      if (!isAuthUserExist?.isExists) {
        
        console.log(location.state);
        console.log(from);
        setLoading(false);
        navigate(`/consent-form?returnUrl=${encodeURIComponent(from)}`, {
          state: {
            email: data?.account?.username,
            name: data?.account?.name,
            providerId: Providers.MICROSOFT,
          },
        });
      }
      if (isAuthUserExist?.isExists) {
        const userData = await UserService.getUserbyUserId(
          isAuthUserExist?.userId
        );
        saveUserData(userData);
        setLoading(false);
        //navigate("/home");
        navigate(from, { replace: true });
      }
    }
    setLoading(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    try {
      e.preventDefault();
      setLoading(true);
      var response = await UserService.login(email, password);
      localStorage.setItem("userDetails", JSON.stringify(response));
      if (response?.id) {
        saveUserData(response);
        showSuccessToast();
        setLoading(false);
        setTimeout(() => {
          navigate(from, { replace: true });
        }, 1000);
      } else {
        setLoading(false);
        showErrorToast();
      }
    } catch (error) {
      toast.error("Something went wrong login after some some.");
    }
  };
  const showSuccessToast = () =>
    toast.success("Login Successful", { autoClose: 1000 });
  const showErrorToast = () =>
    toast.error("Email ID or Password is incorrect!", { autoClose: 10000 });

  const loginWithGitHub = () => {
    const clientID = process.env.REACT_APP_GITHUB_CLIENT_ID;
    const redirectURI = process.env.REACT_APP_GITHB_REDIRECT_URI;
    window.location.href = `https://github.com/login/oauth/authorize?scope=user:email,read:user&client_id=${clientID}&redirect_uri=${redirectURI}`;
  };

  //#region  Google Login
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setLoading(true);
      let data = await AuthService.googleUserData(tokenResponse.access_token);
      const tokenData = await data;
      if (tokenData?.isExists === true) {
        const userData = await UserService.getUserbyUserId(tokenData?.userId);
        saveUserData(userData);
        setLoading(false);
        //navigate("/home");
        navigate(from, { replace: true });
      }
      if (tokenData?.isExists === false) {
        setLoading(false);
        navigate(`/consent-form?returnUrl=${encodeURIComponent(from)}`, {
          state: {
            email: tokenData?.email,
            name: tokenData?.name,
            providerId: Providers.GMAIL,
          },
        });
      }
    },
    onError: (error) => console.log("Login Failed:", error),
  });
  //#endregion

  return (
    <div className="relative bg-[url('https://iated.org/archive/iceri2022_pictures//iceri22-096.jpg')] bg-no-repeat bg-cover flex items-center justify-center h-screen w-full px-5 sm:px-0">
      <div className="absolute h-screen inset-0 bg-black bg-opacity-80">
        <div className="relative h-full p-10 z-10">
          {loading && (
            <div className="absolute inset-0 bg-black bg-opacity-80 z-10">
              <div
                role="status"
                className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
              >
                <HashLoader color={"white"} loading={loading} size={50} />
              </div>
            </div>
          )}
          <div className="flex bg-white h-full m-auto rounded-lg shadow-lg border overflow-hidden max-w-sm lg:max-w-4xl w-full">
            <div
              className="hidden md:block lg:w-1/2 bg-cover bg-blue-700"
              style={{
                backgroundImage: `url(https://www.tailwindtap.com/assets/components/form/userlogin/login_tailwindtap.jpg)`,
              }}
            ></div>
            <div className="w-full p-8 lg:w-1/2">
              <p className="text-xl text-gray-600 text-center">Welcome back!</p>
              <form onSubmit={handleSubmit} className="mt-4">
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Email Address
                  </label>
                  <input
                    className="text-gray-700 border border-gray-300 rounded py-2 px-4 block w-full focus:outline-2 focus:outline-blue-700"
                    type="email"
                    value={email}
                    placeholder="Enter your Email Address"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-4">
                  <div className="flex justify-between">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Password
                    </label>
                  </div>
                  <input
                    className="text-gray-700 border border-gray-300 rounded py-2 px-4 block w-full focus:outline-2 focus:outline-blue-700"
                    type="password"
                    value={password}
                    placeholder="Enter your password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <a
                    href="/forget-password"
                    className="text-xs text-gray-500 hover:text-gray-900 text-end w-full mt-2"
                  >
                    Forget Password?
                  </a>
                </div>
                <div className="mt-8">
                  <button
                    type="submit"
                    className="bg-[#625ffb] text-white font-bold py-2 px-4 w-full rounded hover:bg-[#625ffb]"
                  >
                    Login
                  </button>
                </div>
              </form>
              <div className="mt-4 flex flex-col justify-between">
                <GoogleLoginButton onClick={() => login()} />
              </div>
              <div className="mt-4 flex flex-col justify-between">
                <MicrosoftLogin
                  clientId={process.env.REACT_APP_MICROSOFT_CLIENT_ID}
                  authCallback={authHandler}
                  graphScopes={["user.read"]}
                  children={<MicrosoftLoginButton />}
                />
              </div>
              <div className="mt-4 flex flex-col justify-between">
                <GithubLoginButton onClick={loginWithGitHub} />
              </div>
              <div
                className="mt-4 flex items-center w-full text-center"
                onClick={() =>
                  navigate(`/register?returnUrl=${encodeURIComponent(from)}`)
                }
              >
                <button
                  // href="#"
                  className="text-xs font-bold text-gray-500 capitalize text-center w-full"
                >
                  Don&apos;t have any account yet?
                  <span className="text-[#625ffb]"> Sign Up</span>
                </button>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
