import React, { useContext, useEffect, useState } from "react";
import SideBar from "../SideBar/SideBar";
import ListEvents from "../ListEvents/ListEvents";
import "./EventList.scss";
import { useMediaQuery } from "react-responsive";
import DashboardHeader from "../DashboardHeader/DashboardHeader";
import { AuthContext } from "../../../context/AuthContext";
import { Role } from "../../../enums/Roles";

interface EventListProps {}

const EventList: React.FC<EventListProps> = () => {
  const [showSidebar, setShowSidebar] = useState(true);
  const isSmallScreen = useMediaQuery({ maxWidth: 767 });
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (isSmallScreen) {
      setShowSidebar(false);
    }
  }, []);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <div>
      {!isSmallScreen && (
        <div className="flex">
          {auth.auth?.role === Role.Admin && (
            <div
              className="column"
              style={{
                width:
                  showSidebar && auth.auth?.role === Role.Admin ? "15%" : "0%",
              }}
            >
              {showSidebar && auth.auth?.role === Role.Admin && (
                <div className="sidebar">
                  <SideBar onCloseSidebar={toggleSidebar} />
                </div>
              )}
            </div>
          )}
          <div
            className="column"
            style={{
              width:
                showSidebar && auth.auth?.role === Role.Admin ? "85%" : "100%",
            }}
          >
            <div className={`row`}>
              <DashboardHeader onCloseSidebar={toggleSidebar} />
              <div className="p-5">
                <ListEvents />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventList;
