import { FC, useContext, useEffect, useRef, useState } from "react";
import "./CreateEvent.scss";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import EventService from "../../../services/eventService";
import { useNavigate } from "react-router-dom";
import DashboardHeader from "../DashboardHeader/DashboardHeader";
import SideBar from "../SideBar/SideBar";
import { UUIDGenerator } from "../../../helper/Guid";
import { HashLoader } from "react-spinners";
import { AuthContext } from "../../../context/AuthContext";
import { timeZoneOptions } from "../../../helper/TimeZone";
import { validateForm } from "./CreateEventService";

export interface CreateEventProps {}

const CreateEvent: FC<CreateEventProps> = () => {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  const handleCancel = () => {
    reset();
  };
  const navigate = useNavigate();
  const [showSidebar, setShowSidebar] = useState(true);
  const [isCFSAddressValid, setCFSAddressValid] = useState(null);
  const [cFSAddress, setCFSAddress] = useState("");
  const [eventFromDate, setEventFromDate]=useState(null);
  const [eventEndDate, setEventEndDate] = useState(null);
  const [cfsFromDate, setCFSFromDate]=useState(null);
  const [cfsEndDate, setCFSEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const {auth} = useContext(AuthContext);

  const onSubmit = async (data) => {
    console.log("onSubmit");
    // const formErrors = validateForm(setError, data, clearErrors);
    // console.log(formErrors);
    if(cFSAddress==="" || cFSAddress===null || cFSAddress===undefined){
      setError("cfsaddress", {
        type: "manual",
        message: "CFS Address is Required",
      });
    }
    else if(!isCFSAddressValid){
      setError("cfsaddress", {
        type: "manual",
        message: "CFS Address is Not Valid",
      });
    }
    else if(isCFSAddressValid && cFSAddress!=="" && cFSAddress!==null && cFSAddress!==undefined){
    console.log("Before validating");
    setLoading(true);
    var userId = auth.userId;
    let eventObject = {
      eventname: data.eventname,
      eventstartdate: data.eventstartdate,
      eventenddate: data.eventenddate,
      timezone: data.timezone,
      createdBy: userId,
      isActive: true,
      eventDetails: {
        eventType: parseInt(data.eventtype),
        createdBy: userId,
        isActive: true,
      },
      eventCallForSpeakers: {
        startDate: data.cfsopentimefrom,
        endDate: data.cfsopentimeto,
        slugURL: cFSAddress,
        supportEmail : data.email,
        createdBy: userId,
        isActive: true,
      },
      eventUserPermissions:[
        {
          userId : userId,
          accessRoleType: 0,
          isActive: true,
        }
      ]
    };
    try {
      let response = await EventService.createEvent(eventObject);
      if (response.data===true) {
        setLoading(false);
        toast.success("Event Created Successfully");
        reset();
        navigate("/organizer/event-list");
      }
      else{
        setLoading(false);
        toast.error("Something went wrong !");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong. Please try again later.");
    }
    }
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const generateCFSAddress = async (e) => {
    setCFSAddressValid(true);
    const slugUrl = await EventService.suggestSlugUrl(e.target.value);
    setCFSAddress(slugUrl);
    clearErrors("cfsaddress");
    clearErrors("eventname");
  };

  const changeCFSAddress = async (e) => {
    var cfsAddress = e.target.value;
    setCFSAddress(cFSAddress);
    if (cfsAddress.includes(" ")) {
      setError("cfsaddress", {
        type: "manual",
        message: "Space not allowed",
      });
    } else if (
      cfsAddress !== null &&
      cfsAddress !== undefined &&
      cfsAddress !== ""
    ) {
      var isExist = await EventService.checkSlugUrl(cfsAddress);
      if (isExist) {
        setCFSAddressValid(true);
        clearErrors("cfsaddress");
      } else {
        setCFSAddressValid(false);
        setError("cfsaddress", {
          type: "manual",
          message: "CFS Address is Already Exist",
        });
      }
    } else {
      setError("cfsaddress", {
        type: "manual",
        message: "CFS Address is Required",
      });
    }
    setCFSAddress(cfsAddress);
  };

  const resetToDate = () =>{
    document.getElementById("eventenddate").setAttribute('value',null);
  }

  return (
    <div className="flex">
      <div className="column" style={{ width: showSidebar ? "15%" : "0%" }}>
        {showSidebar && (
          <div className="sidebar">
            <SideBar onCloseSidebar={toggleSidebar} />
          </div>
        )}
      </div>
      <div className="column" style={{ width: showSidebar ? "85%" : "100%" }}>
        <div className={`row`}>
          <DashboardHeader onCloseSidebar={toggleSidebar} />
          <div className="flex flex-row justify-between my-5 p-6 bg-white">
            <div className="p-2 text-2xl font">Create Event</div>
          </div>
          <div className="m-5 p-5 bg-white">
            <div className="row">
              <div className="headers">
                <div className="border-b border-gray-300 py-3 mx-3 font-semibold">
                  Basic Info
                </div>
              </div>
              <div className="forms row">
                <form onSubmit={() => handleSubmit(onSubmit)}>
                  <div className="px-3 flex md:flex-row flex-col">
                    <div className="flex flex-col gap-2 my-2 p-2 sm:w-3/4 md:w-3/4 lg:w-3/4 xl:w-3/4">
                      <label className="text-sm font-semibold">
                        Event Name<span className="text-red-500"> *</span>
                      </label>
                      <input
                        {...register("eventname", { required: true })}
                        type="text"
                       className="text-gray-700 text-sm  border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-[#7270fc]"
              
                        placeholder="eg. ABC Conference 2024"
                        onChange={(e) => {
                          generateCFSAddress(e);
                        }}
                      />
                      {errors.eventname && (
                        <small className="text-red-500">
                          Event Name is required
                        </small>
                      )}
                    </div>
                    <div className="flex flex-col gap-2 my-2 p-2 sm:w-3/4 md:w-3/4 lg:w-3/4 xl:w-3/4">
                      <label className="text-sm font-semibold">
                        Event From Date<span className="text-red-500"> *</span>
                      </label>
                      <input
                        {...register("eventstartdate", {
                          required: true,
                        })}
                        type="date"
                        min={new Date().toISOString().split("T")[0]}
                        onChange={(e) => {
                          setEventEndDate("");
                          setEventFromDate(e.target.value);
                          clearErrors("eventstartdate");
                        }}
                       className="text-gray-700 text-sm  border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-[#7270fc]"
              
                        placeholder=""
                      />
                      {errors.eventstartdate && (
                        <small className="text-red-500">
                          From date required
                        </small>
                      )}
                    </div>
                    <div className="flex flex-col gap-2 my-2 p-2 sm:w-3/4 md:w-3/4 lg:w-3/4 xl:w-3/4">
                      <label className="text-sm font-semibold">
                        Event To Date<span className="text-red-500"> *</span>
                      </label>
                      <input
                        {...register("eventenddate", {
                          required: true,
                        })}
                        type="date"
                        min={eventFromDate}
                        value={eventEndDate}
                        onChange={(e) => {
                          setEventEndDate(e.target.value);
                          clearErrors("eventenddate");
                        }}
                       className="text-gray-700 text-sm  border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-[#7270fc]"
              
                        placeholder="DD-MM-YYYY"
                      />
                      {errors.eventenddate && (
                        <small className="text-red-500">
                          {errors?.eventenddate?.message.toString()}
                        </small>
                      )}
                    </div>
                  </div>

                  <div className="px-3 flex md:flex-row flex-col">
                    {/* <div className="flex flex-col gap-2 my-2 p-2 sm:w-3/4 md:w-2/4 lg:w-3/4 xl:w-1/3">
                      <label className="text-sm font-semibold">
                        Event Location<span className="text-red-500"> *</span>
                      </label>
                      <input
                        {...register("eventlocation", { required: true })}
                        type="text"
                        className="border bg-[#f3f3f4] text-base placeholder:text-sm border-gray-300 rounded-md p-1 focus:outline-none focus:border-gray-400"
                        placeholder="Start typing city name"
                      />
                      {errors.eventlocation && (
                        <small className="text-red-500">
                          Event Location is required
                        </small>
                      )}
                    </div> */}

                    <div className="flex flex-col gap-2 my-2 p-2 sm:w-3/4 md:w-3/4 lg:w-3/4 xl:w-1/3">
                      <label className="text-sm font-semibold">
                        Event Time Zone<span className="text-red-500"> *</span>
                      </label>
                      <select
                        {...register("timezone", { required: true })}
                       className="text-gray-700 text-sm  border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-[#7270fc]"
              
                      >
                        <option disabled>Select</option>
                        {Object.entries(timeZoneOptions).map(
                          ([value, label]) => (
                            <option key={value} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                      {errors.timezone && (
                        <small className="text-red-500 ml-5 my-2">
                          Event Time Zone is required
                        </small>
                      )}
                    </div>
                    <div className="flex justify-center flex-col gap-2 my-2 p-2 sm:w-3/4 md:w-2/4 lg:w-3/4 xl:w-1/3">
                      <div className="flex gap-10 my-1 font-semibold">
                        <div className="flex gap-2">
                          <input
                            type="radio"
                            id="option1"
                            name="inperson"
                            value="0"
                            {...register("eventtype", { required: true })}
                          />

                          <label
                            className="text-sm font-semibold"
                            htmlFor="option1"
                          >
                            In Person Event
                          </label>
                        </div>
                        <div className="flex gap-2">
                          <input
                            type="radio"
                            id="option2"
                            name="online"
                            value="1"
                            {...register("eventtype", { required: true })}
                          />
                          <label
                            htmlFor="option2"
                            className="text-sm font-semibold"
                          >
                            Online Event
                          </label>
                        </div>
                      </div>
                      {errors.eventtype && (
                        <small className="text-red-600 ml-3">
                          Please select a Event type
                        </small>
                      )}
                    </div>
                    <div className="flex flex-col gap-2 my-2 p-2">
                      <label className="text-sm font-semibold">
                        CFS Address<span className="text-red-500"> *</span>
                      </label>
                      <div className="flex flex-row border  rounded-md focus:outline-2 focus:outline-[#7270fc]">
                        <div className="p-1">oratorengage.com/</div>
                        <input
                          // {...register("cfsaddress")}
                          type="text"
                          value={cFSAddress}
                          className="text-gray-700  border-none rounded px-2 block w-full focus:outline-none"
                          placeholder=" CFS Address"
                          onChange={(e) => {
                            changeCFSAddress(e);
                          }}
                        />
                        {isCFSAddressValid != null && (
                          <div className="p-1">
                            {isCFSAddressValid ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="green"
                                className="size-6"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="red"
                                className="size-6"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            )}
                          </div>
                        )}
                      </div>
                      {errors?.cfsaddress?.message && (
                        <small className="text-red-500  my-2">
                          {errors?.cfsaddress?.message.toString()}
                        </small>
                      )}
                    </div>
                  </div>

                  <div className="px-3 flex md:flex-row flex-col">
                    <div className="flex flex-col gap-2 my-2 p-2 w-1/3">
                      <label className="text-sm font-semibold">
                        CFS Open From<span className="text-red-500"> *</span>
                      </label>
                      <input
                        {...register("cfsopentimefrom", {
                          required: true,
                        })}
                        type="datetime-local"
                        min={new Date().toISOString().slice(0, 16)}
                        onChange={(e) => {
                          setCFSEndDate("");
                          setCFSFromDate(e.target.value);
                          clearErrors("cfsopentimefrom");
                        }}
                       className="text-gray-700 text-sm  border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-[#7270fc]"
              
                        placeholder=""
                      />
                      {errors.cfsopentimefrom && (
                        <small className="text-red-500">
                          From date required
                        </small>
                      )}
                    </div>

                    <div className="flex flex-col gap-2 my-2 p-2 w-1/3">
                      <label className="text-sm font-semibold">
                        CFS Open To<span className="text-red-500"> *</span>
                      </label>
                      <input
                        {...register("cfsopentimeto", { required: true })}
                        type="datetime-local"
                        min={cfsFromDate}
                        value={cfsEndDate}
                        onChange={(e) => {
                          setCFSEndDate(e.target.value);
                          clearErrors("cfsopentimeto");
                        }}
                       className="text-gray-700 text-sm  border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-[#7270fc]"
              
                        placeholder=""
                      />
                      {errors.cfsopentimeto && (
                        <small className="text-red-500">
                          {errors?.cfsopentimeto?.message.toString()}
                        </small>
                      )}
                    </div>
                    <div className="flex flex-col gap-2 my-2 p-2 w-1/3">
                      <label className="text-sm font-semibold">
                        Speaker Support Email
                        <span className="text-red-500"> *</span>
                      </label>
                      <input
                        {...register("email", {
                          required: {
                            value: true,
                            message: "Email is required",
                          },
                          pattern: {
                            value:
                              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/,
                            message: "Invalid Email",
                          },
                        })}
                        type="text"
                       className="text-gray-700 text-sm  border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-[#7270fc]"
              
                        placeholder="eg. abc@gmail.com"
                      />
                      {errors.email?.message ? (
                        <small className="text-red-600  my-2">
                          {errors?.email?.message.toString()}
                        </small>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  {/* <div className="px-3 flex md:flex-row flex-col">
                    <div className="flex flex-col gap-2 my-2 p-2 sm:w-3/4 md:w-1/4 lg:w-2/4 xl:w-1/3">
                      <label className="text-sm font-semibold">
                        Speaker Support Email
                        <span className="text-red-500"> *</span>
                      </label>
                      <input
                        {...register("email", {
                          required: {
                            value: true,
                            message: "Email is required",
                          },
                          pattern: {
                            value:
                              /^[a-zA-Z0-9]{0,61}@[a-zA-Z0-9]{0,255}.[a-zA-Z0-9]{0,24}$/,
                            message: "Invalid Email",
                          },
                        })}
                        type="text"
                        className="borde bg-[#f3f3f4] text-base placeholder:text-sm border-gray-300 rounded-md p-1 focus:outline-none focus:border-gray-400"
                        placeholder="eg. abc@gmail.com"
                      />
                      {errors.email?.message ? (
                        <small className="text-red-600  my-2">
                          {errors?.email?.message.toString()}
                        </small>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div> */}

                  <div className="flex flex-row gap-6 m-5">
                    <button
                      onClick={handleSubmit(onSubmit)}
                      type="submit"
                      className={`px-7 py-2  rounded-full bg-[#625ffb] text-white`}
                    >
                      Create Event
                    </button>
                    <button
                      onClick={handleCancel}
                      className={`px-7 py-2  rounded-full border border-[#625ffb] text-[#625ffb] `}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="absolute inset-0 bg-black bg-opacity-80 z-10">
          <div
            role="status"
            className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
          >
            <HashLoader color={"white"} loading={loading} size={50} />
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateEvent;
