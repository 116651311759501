import React, { useContext, useEffect, useState, useRef } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useConfirmationDialog } from "../../../utils/ConfirmationDialog/useConfirmationDialog";
import ConfirmationDialog from "../../../utils/ConfirmationDialog/ConfirmationDialog";
import { useNavigate } from "react-router-dom";
import { Role } from "../../../enums/Roles";

interface DashboardHeaderProps {
  onCloseSidebar: () => void;
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  onCloseSidebar,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const { dialog, showDialog, closeDialog } = useConfirmationDialog();
  const [userFirstLetter, setUserFirstLetter] = useState("P");
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const firstLetter = auth.userFirstName.charAt(0);
    setUserFirstLetter(firstLetter);
  }, [auth.userFirstName]);

  const handleSignout = () => {
    showDialog({
      title: "Sign Out",
      description: "Are you sure you want to sign out?",
      onConfirm: () => {
        localStorage.clear();
        navigate("/signin");
      },
    });
  };

  return (
    <div>
    <div
      className={`flex flex-row w-full bg-white relative shadow-md ${
        (Role.Admin === auth?.role || Role.Guest=== auth?.role)
          ? "justify-between items-center"
          : "justify-between items-end"
      }`}
    >
      <div
          className="text-4xl text-white py-5 flex flex-row items-center cursor-pointer"
          onClick={onCloseSidebar}
        >
          <img src="/event/hamburgur.svg" alt="Menu" />
        </div>
      <div className="pr-5" ref={dropdownRef}>
        <div
          id="dropdownInformationButton"
          data-dropdown-toggle="dropdownInformation"
          className="font-medium rounded-lg text-sm px-4 py-2 text-center gap-2 cursor-pointer inline-flex items-center dark:bg-white"
          onClick={toggleDropdown}
        >
          <div className="py-3 border-4 font-bold border-white px-4 rounded-[50%] bg-[#d9d9d9]">
            {userFirstLetter}
          </div>

          <div className="text-start font-bold text-base">
            {auth?.userFirstName + " " + (auth?.userlastName!=null?auth?.userlastName:"")}
            <div className="text-[#a3a3a3] font-normal text-xs">
              {auth?.role === Role.Admin ? "Event Organizer" : "Event Speaker"}
            </div>
          </div>
          <svg
            className="w-2.5 h-2.5 ml-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </div>

        {isDropdownOpen && (
          <div
            id="dropdownInformation"
            className="z-10 absolute right-5 mt-2 bg-white divide-y  divide-gray-100 rounded-lg shadow w-44"
          >
            <ul
              className="py-2 text-sm"
              aria-labelledby="dropdownInformationButton"
            >
              <a href="/speaker/user-profile">
                <li className="flex items-center px-4 hover:bg-[#d9d9d9]">
                  <div>
                    <img src="/home/profile.svg" className="h-4 w-4" />
                  </div>
                  <div className="block px-4 py-2 ">Profile</div>
                </li>
              </a>

              <a href="/speaker/my-events">
                <li className="flex items-center px-4 hover:bg-[#d9d9d9]">
                  <div>
                    <img src="/home/list.svg" className="h-4 w-4" />
                  </div>
                  <div className="block px-4 py-2 ">My Sessions</div>
                </li>
              </a>
            </ul>

            <a className="block cursor-pointer px-4 py-2 text-sm hover:bg-[#d9d9d9]">
              <div
                onClick={() => {
                  handleSignout();
                  setIsDropdownOpen(false);
                }}
                className="flex items-center  hover:bg-[#d9d9d9]"
              >
                <div>
                  <img src="/home/logout.svg" className="h-4 w-4" />
                </div>
                <div className="block px-4 py-2 "> Sign out</div>
              </div>
            </a>
          </div>
        )}
      </div>

      
    </div>
    <div style={{ zIndex: 2000 }}>
    <ConfirmationDialog
      open={dialog.open}
      title={dialog.title}
      description={dialog.description}
      onClose={closeDialog}
      onConfirm={dialog.onConfirm}
    />
  </div>
  </div>
  );
};

export default DashboardHeader;
