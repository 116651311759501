import axios from "axios";
import { URLConfig } from "../config/URL.Config";
import { EventConfig } from "../config/EventConfig";
import { BlobServiceClient} from '@azure/storage-blob';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL+process.env.REACT_APP_API_VERSION,
  headers: {
    "Content-Type": "application/json",
  },
});

const BlobService = {
   uploadFile : async (file, containername,folderName) => {
    const blobResponse = await axiosInstance.get(
        `${URLConfig.getSasToken}containerName=${containername}`
      ); 
    const blobSasToken=blobResponse.data;
    const blobService = new BlobServiceClient(
      `${process.env.REACT_APP_BASE_BLOB_ENDPOINT}${blobSasToken}`
    );
    const containerClient =await blobService.getContainerClient(`${containername}`);
    const blockBlobClient =await containerClient.getBlockBlobClient(`${folderName}/${file.name}`);
    const blobUploadResponse=await blockBlobClient.uploadData(file);
    console.log(blobUploadResponse);
    const blobUrl = `${process.env.REACT_APP_BASE_BLOB_ENDPOINT}${containername}/${folderName}/${file.name}`;
    console.log(blobUrl);
    return blobUrl;
  }
};

export default BlobService;
