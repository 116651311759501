import { useForm } from "react-hook-form";
import { useContext, useEffect, useRef, useState } from "react";
import "../../../layout/WebsiteLayout/Layout.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HashLoader } from "react-spinners";
import BlobService from "../../../services/blobService";
import UserService from "../../../services/userService";
import { setNestedValue } from "../../../helper/ValidateForm";
import { UserContext } from "./UserProfile";
export default function ProfileCard() {
  const { register, handleSubmit, watch, setValue } = useForm();
  const [profile, setProfile] = useState(null);
  const [profileBackgroundPhoto, setProfileBackgroundPhoto] = useState(null);
  const [loading, setLoading] = useState(false);
  const [curruntUser, setCurruntUser] = useState(null);
  const { user, setUser, errors, setErrors } = useContext(UserContext);
  const [isReadonly, setIsReadonly] = useState(true);
  const fileInputRef = useRef(null);
  // const handleImageChanges = async (e, setVariable) => {
  //   setLoading(true);
  //   const uploadedImageUri = await BlobService.uploadFile(
  //     e.target.files[0],
  //     "SpeakerPhotos"
  //   );
  //   if (uploadedImageUri) {
  //     setVariable(uploadedImageUri);
  //     setLoading(false);
  //   }
  // };

  const handleImageChanges = async (e) => {
    try {
      setLoading(true);
      var result = await BlobService.uploadFile(
        e.target.files[0],
        "users","profileimage"
      );
      setUser((prevEvent) => ({
        ...prevEvent,
        userProfile: {
          ...user?.userProfile,
          photoUrl: result,
        },
      }));
      console.log(result);
      setLoading(false);
    } catch (error) {
      toast.error("Image Upload Failed");
      setLoading(false);
    }
  };

  const fetchUserData = async (userId) => {
    setLoading(true);
    try {
      const user = await UserService.getUserbyUserId(userId);
      setCurruntUser(user);
      setLoading(false);
      setValue("firstName", user?.firstName || "");
      setValue("lastName", user?.lastName || "");
      setValue("email", user?.email || "");
      setValue("designation", user?.userProfile?.designation || "");
      setValue("tagline", user?.userProfile?.tagline || "");
      setValue("biography", user?.userProfile?.biography || "");
      setValue("areaofExpertise", user?.userProfile?.areaofExpertise || "");
      setValue("topics", user?.userProfile?.topics || "");
      setValue("location", user?.userProfile?.location || "");
      setValue("twitter", user?.userSocialMedia?.twitter || "");
      setValue("facebook", user?.userSocialMedia?.facebook || "");
      setValue("linkedIn", user?.userSocialMedia?.linkedIn || "");
      setValue("instagram", user?.userSocialMedia?.instagram || "");
      setValue("blog", user?.userSocialMedia?.blog || "");
      setValue("companyWebsite", user?.userSocialMedia?.companyWebsite || "");
    } catch (error) {
      setLoading(false);
      console.error("Error fetching user data:", error);
    }
  };

  const mandatoryFields = ["firstName", "lastName", "email"];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevEvent) => {
      const updatedEvent = setNestedValue(prevEvent, name, value);
      console.log(updatedEvent);
      return updatedEvent;
    });
    if (mandatoryFields.includes(name)) {
      if (value === null || value === undefined || value === "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Field is required",
        }));
      } else {
        setErrors((prevErrors) => {
          const { [name]: _, ...rest } = prevErrors;
          return rest;
        });
      }
    }
  };

  return (
    <div className="flex">
      <div className="flex-auto md:p-5 p-2">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block text-blueGray-600 text-xs font-semibold mb-2"
                htmlFor="firstName"
              >
                First Name
              </label>
              <input
                type="text"
                name="firstName"
                value={user?.firstName}
                onChange={handleChange}
                className="text-gray-700 text-sm  border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-[#7270fc]"
                placeholder="William"
              />
              {errors.firstName && (
                <small className="text-red-500">First Name is required</small>
              )}
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block text-blueGray-600 text-xs font-semibold mb-2"
                htmlFor="lastName"
              >
                Last Name
              </label>
              <input
                type="text"
                name="lastName"
                value={user?.lastName}
                onChange={handleChange}
                className="text-gray-700 text-sm  border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-[#7270fc]"
                placeholder="Arsenal"
              />
              {errors.lastName && (
                <small className="text-red-500">Last Name is required</small>
              )}
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block text-blueGray-600 text-xs font-semibold mb-2"
                htmlFor="email"
              >
                Email address
              </label>
              <input
                type="email"
                readOnly={true}
                value={user?.email}
                {...register("email")}
                className="text-gray-700 text-sm  border border-gray-300 rounded py-2 px-2 block w-full focus:outline-2 focus:outline-[#7270fc]"
                defaultValue="william@example.com"
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <label className="text-sm font-semibold">Profile Photo</label>
            <div className="p-2 bg-[#f3f3f4]">
              {user?.userProfile?.photoUrl != null && (
                <div className="flex flex-row justify-between items-start">
                  <div className="p-2 mb-2">
                    <img
                      src={user?.userProfile?.photoUrl}
                      alt="Preview"
                      style={{ maxWidth: "100%", maxHeight: "200px" }}
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      setUser((prevEvent) => ({
                        ...prevEvent,
                        userProfile: {
                          ...user?.userProfile,
                          photoUrl: null,
                        },
                      }));
                    }}
                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  >
                    <span className="sr-only">Close menu</span>
                    <svg
                      className="h-3 w-3"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="red"
                      viewBox="0 0 24 24"
                      stroke="black"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              )}
              <div>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={(e) => handleImageChanges(e)}
                  style={{ display: "none" }}
                />
                <div
                  className="my-4 mx-2 bg-white p-2 text-center cursor-pointer"
                  onClick={() => {
                    fileInputRef.current.click();
                  }}
                >
                  Choose file or Click here to Upload
                </div>
              </div>
            </div>
            {errors["userProfile.photoUrl"] && (
              <small className="text-red-500">
                Profile picture is required
              </small>
            )}
          </div>
        </div>
      </div>
      {loading && (
        <div className="absolute inset-0 bg-black bg-opacity-80 z-10">
          <div
            role="status"
            className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
          >
            <HashLoader color={"white"} loading={loading} size={50} />
          </div>
        </div>
      )}
    </div>
  );
}
