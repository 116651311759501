import React, { FC, useEffect, useState } from "react";
import "./SpeakerList.scss";
import ReactPaginate from "react-paginate";
import { useNavigate, useParams } from "react-router-dom";
import SpeakerService from "../../../../services/speakerService";
import { HashLoader } from "react-spinners";

interface SpeakerListProps {}

const SpeakerList: FC<SpeakerListProps> = () => {
  const [listSpeakers, setListSpeakers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const { eventId } = useParams<{ eventId: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    getSpeakersByEventId(eventId);
  }, [eventId]);

  const handlePageClick = (data: { selected: number }) => {
    setCurrentPage(data.selected);
  };

  const getSpeakersByEventId = (eventId: string) => {
    setLoading(true);
    SpeakerService.getSpeakersByEventId(eventId)
      .then((resData: any) => {
        setListSpeakers(resData || []);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error("Error fetching speakers:", err);
      });
  };

  const navigateToDetailSpeaker = (speakerId: string) => {
    navigate(`/organizer/speaker-list-details/${speakerId}`);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredSpeakers = listSpeakers.filter((speaker) => {
    const fullName =
      `${speaker?.users?.firstName} ${speaker?.users?.lastName}`.toLowerCase();
    const designation =
      speaker?.users?.userProfile?.designation?.toLowerCase() || "";
    const companyName = speaker?.companyName?.toLowerCase() || "";
    const query = searchQuery.toLowerCase();

    return (
      fullName.includes(query) ||
      designation.includes(query) ||
      companyName.includes(query)
    );
  });

  const pageCount = filteredSpeakers
    ? Math.ceil(filteredSpeakers.length / itemsPerPage)
    : 0;
  const currentSpeakers = filteredSpeakers
    ? filteredSpeakers.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
      )
    : [];

  const setDefault = (e) => {
    e.target.src = `/profile/default-profile.jpg`;
  };

  return (
    <div>
      {loading && (
        <div className="fixed inset-0 bg-black bg-opacity-80 z-10">
          <div
            role="status"
            className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
          >
            <HashLoader color={"white"} loading={loading} size={50} />
          </div>
        </div>
      )}
      <div className="bg-white min-h-screen p-4 text-sm">
        {listSpeakers && listSpeakers.length > 0 ? (
          <div className="row">
            <div className="col-lg-12 b grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="search-head mx-5 mt-4">
                    <input
                      type="text"
                      className="form-control search-bar rounded-full"
                      placeholder="Search speakers with any key of name, company name"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                    <div className="search-icon">
                      <img src="/session/searchIcon.svg" alt="search-icon" />
                    </div>
                  </div>

                  <div className="session-list bg-white p-2 rounded-md m-3">
                    <div className="font-bold text-white p-2 bg-[#625ffb] rounded-md py-2 session-header  my-6 flex items-center gap-4">
                      <div className="session-item w-[20%]">Speaker</div>
                      <div className="session-item w-[40%]">Sessions</div>
                      <div className="session-item w-[20%]">Speaker Type</div>
                      <div className="session-item w-[20%]">Company Name</div>
                    </div>
                    {currentSpeakers.map((speaker) => (
                      <div
                        key={speaker.id}
                        onClick={() => navigateToDetailSpeaker(speaker.id)}
                        className="session-row my-3 border-b-2 flex items-center gap-4 p-1 cursor-pointer"
                      >
                        <div className="session-speakers flex  w-[20%]">
                          <div className="speaker-item flex flex-col gap-3">
                            <div className="speaker-photo">
                              <img
                                src={speaker?.photoPath}
                                className="profile rounded-full"
                                alt="Profile Avatar"
                                onError={(e) => setDefault(e)}
                              />
                            </div>
                            <div className="flex flex-col">
                              <div className="speaker-name text-start">
                                {`${speaker?.users?.firstName} ${speaker?.users?.lastName}`}
                              </div>
                              <div className="session-item custom-ellipsis-speaker">
                                {speaker?.tagLine}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="session-speakers flex flex-col  w-[40%]">
                          {speaker?.sessions?.map((session, index) => (
                            <div
                              key={index}
                              className="speaker-item flex items-center"
                            >
                              <div className="speaker-name text-start line-clamp-1">
                                <li className="">{`${session?.title}`}</li>
                              </div>
                            </div>
                          ))}
                        </div>

                        <div className="session-item w-[20%] ml-4 line-clamp-1">
                          <div>{speaker?.speakersType || ""}</div>
                        </div>
                        <div className="session-speakers flex  w-[20%]">
                          <div className="speaker-item flex flex-col items-start justify-start gap-2">
                            <div className="speaker-photo w-">
                              <img
                                src={speaker?.companyLogo}
                                onError={(e) => setDefault(e)}
                                className="profile rounded-full"
                                alt="Profile Avatar"
                              />
                            </div>
                            <div className="speaker-name text-start line-clamp-1">
                              {`${speaker?.companyName || ""}`}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  {pageCount > 1 && (
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : !loading ? (
          <p className="no-session">No speakers available.</p>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default SpeakerList;
