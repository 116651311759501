import React, { createContext, useEffect, useState } from 'react';

interface AuthContextType {
    auth: AuthState | null;
    setAuth: React.Dispatch<React.SetStateAction<AuthState | null>>;
  }

interface AuthState {
    role: string;
    userId: string;
    userFirstName: string;
    userlastName : string;
    userEmail:string;
  }
const AuthContext = createContext<AuthContextType>(undefined);

function AuthProvider (props){
    
    const [auth, setAuth] = useState<AuthState | null>(() => {
        const savedAuth = localStorage.getItem('auth');
        return savedAuth ? JSON.parse(savedAuth) : null;
      });
      
      const [isLoggedIn, setIsLoggedIn] = useState<boolean>(!!auth);
    
      useEffect(() => {
        if (auth) {
          localStorage.setItem('auth', JSON.stringify(auth));
          setIsLoggedIn(true);
        } else {
          localStorage.removeItem('auth');
          setIsLoggedIn(false);
        }
      }, [auth]);
    const value ={
        auth,
        setAuth
    }

    return (
        <AuthContext.Provider value={value}>
            {props.children}
        </AuthContext.Provider>
    );
   
}

export { AuthProvider, AuthContext };
