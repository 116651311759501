import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import DashboardHeader from "../DashboardHeader/DashboardHeader";
import { HashLoader } from "react-spinners";
import EditEvent from "../EditEvent/EditEvent";
import Dashboard from "../EventDashboard/EventDashboard";
import "./Organizer.scss";
import SessionList from "../Session/SessionList/SessionList";
import DetailSession from "../Session/DetailSession/DetailSession";
import SpeakerList from "../Speaker/SpeakerList/SpeakerList";
import DetailSpeaker from "../Speaker/DetailSpeaker/DetailSpeaker";
import { SpeakerInvite } from "../SpeakerInvite/SpeakerInvite";
import ConfirmationDialog from "../../../utils/ConfirmationDialog/ConfirmationDialog";
import { useConfirmationDialog } from "../../../utils/ConfirmationDialog/useConfirmationDialog";
import Dnd from "../DragandDrop/Dnd";
import NominateAsOrganizer from "../Session/NominateAsOrganizer/NominateAsOrganizer";

const Organizer = () => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [activeTab, setActiveTab] = useState<number>(1);
  const navigate = useNavigate();
  const [eventId, setEventId] = useState(null);
  const [loading, setLoading] = useState(false);
  const { dialog, showDialog, closeDialog } = useConfirmationDialog();

  const getCurrentPathWithoutLastPart = () => {
    return location.pathname.slice(0, location.pathname.lastIndexOf("/"));
  };

  useEffect(() => {
    const url = location.pathname;
    const eventId = url.substring(url.lastIndexOf("/") + 1);
    if (eventId == null || eventId == undefined) {
      <Navigate to="/not-found" />;
    } else {
      setEventId(eventId);
    }
  }, [eventId]);

  const [expanded, setExpanded] = useState(false);
  const location = useLocation();
  const urlType = location.pathname.startsWith("/speaker")
    ? "speaker"
    : location.pathname.startsWith("/organizer")
    ? "organizer"
    : "";
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const handleTabClick = (tabNumber: number) => {
    setActiveTab(tabNumber);
  };

  interface TabProps {
    id: string;
    label: string;
    switchTab: number;
    isActive: boolean;
  }

  const Tab: React.FC<TabProps> = ({ id, label, switchTab, isActive }) => {
    return (
      <li className={`me-2 ${activeTab}`} role="presentation">
        <button
          className={`inline-block p-4 ${isActive && "bg-white"}`}
          id={`${id}-tab`}
          data-tabs-target={`#${id}`}
          type="button"
          role="tab"
          aria-controls={id}
          aria-selected="false"
          onClick={() => handleTabClick(switchTab)}
        >
          {label}
        </button>
      </li>
    );
  };

  const handleSignout = () => {
    showDialog({
      title: "Sign Out",
      description: "Are you sure you want to sign out?",
      onConfirm: () => {
        localStorage.clear();
        navigate("/signin");
      },
    });
  };

  return (
    <div>
      <div className="flex">
        <div className="column" style={{ width: showSidebar ? "15%" : "0%" }}>
          {showSidebar && (
            <div className="sidebar">
              {/* <SideBar onCloseSidebar={toggleSidebar} /> */}
              {/* <EventSideBar onCloseSidebar={toggleSidebar} /> */}
              <nav className="sidenav md:w-[15%] sm:w-full md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden md:shadow-xl md:bg-[#36364c] flex flex-wrap flex-col items-start justify-between relative z-10 bg-gray-50 dark:bg-[#303030]">
                <ul className="font-medium w-[100%]">
                  <button
                    // onClick={onCloseSidebar}
                    className="md:hidden absolute top-5 right-5 text-gray-500 hover:text-white"
                  >
                    &#x2715;
                  </button>
                  <li>
                    <div className="flex flex-column justify-between">
                      <Link
                        to="/"
                        className="text-blue-600 font-bold bg-[#303030] w-full text-lg hover:text-blue-800"
                      >
                        <img src="/login/logo_color.svg"></img>
                      </Link>
                    </div>
                  </li>
                  {/* <li className="p-6">
              <div className="flex flex-row border border-white border-gray-200">
                <button
                  type="button"
                  onClick={() => {
                    navigate("/organizer/event-list");
                  }}
                  className={`${baseClasses} ${
                    urlType === "organizer" ? activeClass : inactiveClass
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className={`w-4 h-4 me-2 ${
                      urlType === "organizer"
                        ? "text-black dark:text-black"
                        : "text-white"
                    }`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 1 1 0-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 0 1-1.44-4.282m3.102.069a18.03 18.03 0 0 1-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 0 1 8.835 2.535M10.34 6.66a23.847 23.847 0 0 0 8.835-2.535m0 0A23.74 23.74 0 0 0 18.795 3m.38 1.125a23.91 23.91 0 0 1 1.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 0 0 1.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 0 1 0 3.46"
                    />
                  </svg>
                  Organizer
                </button>
                <button
                  type="button"
                  onClick={() => {
                    navigate("/speaker/user-profile");
                  }}
                  className={`${baseClasses} ${
                    urlType === "speaker" ? activeClass : inactiveClass
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className={`w-4 h-4 me-2 ${
                      urlType === "speaker"
                        ? "text-black dark:text-black"
                        : "text-white"
                    }`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z"
                    />
                  </svg>
                  Speaker
                </button>
              </div>
            </li> */}
                  {urlType == "organizer" && (
                    <div>
                      <li>
                        <a
                          href={`/organizer/dashboard/${eventId}`}
                          className="flex items-center p-4 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-[#3f3f3f] group"
                        >
                          <svg
                            className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 18 18"
                          >
                            <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
                          </svg>
                          <span className="flex-1 ms-3 whitespace-nowrap">
                            Dashboard
                          </span>
                        </a>
                      </li>
                      <li>
                        <a
                          href={`/organizer/edit-event/${eventId}`}
                          className="flex items-center p-4 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-[#3f3f3f] group"
                        >
                          <svg
                            className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            strokeWidth={2.0}
                            stroke="currentColor"
                            viewBox="0 0 23 23"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                            />
                          </svg>
                          <span className="flex-1 ms-3 whitespace-nowrap">
                            Edit Event
                          </span>
                        </a>
                      </li>
                      <li>
                        <a
                          href={`/organizer/session-list/${eventId}`}
                          className="flex items-center p-4 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-[#3f3f3f] group"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z"
                            />
                          </svg>
                          <span className="flex-1 ms-3 whitespace-nowrap">
                            Sessions
                          </span>
                        </a>
                      </li>
                      <li>
                        <a
                          href={`/organizer/speaker-list/${eventId}`}
                          className="flex items-center p-4 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-[#3f3f3f] group"
                        >
                          <svg
                            className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />{" "}
                            <circle cx="12" cy="7" r="4" />
                          </svg>
                          <span className="flex-1 ms-3 whitespace-nowrap">
                            Speakers
                          </span>
                        </a>
                      </li>
                      <li>
                        <a 
                          href={`/organizer/agenda-builder/${eventId}`}
                          className="flex items-center p-4 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-[#3f3f3f] group"
                        >
                          <img className="w-5 h-5" src="/session/agenda.svg" />
                          <span className="flex-1 ms-3 whitespace-nowrap">
                            Agenda Builder
                          </span>
                        </a>
                      </li>
                    </div>
                  )}
                  {urlType == "speaker" && (
                    <div>
                      <li>
                        <a
                          href="/organizer/event-list"
                          className="flex items-center p-4 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-[#3f3f3f] group"
                        >
                          <svg
                            className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 18 18"
                          >
                            <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
                          </svg>
                          <span className="flex-1 ms-3 whitespace-nowrap">
                            Dashboard
                          </span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="/speaker/event-creation"
                          className="flex items-center p-4 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-[#3f3f3f] group"
                        >
                          <svg
                            className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            strokeWidth={2.0}
                            stroke="currentColor"
                            viewBox="0 0 23 23"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                            />
                          </svg>
                          <span className="flex-1 ms-3 whitespace-nowrap">
                            Edit Profile
                          </span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="/speaker/event-creation"
                          className="flex items-center p-4 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-[#3f3f3f] group"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 1 1 0-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 0 1-1.44-4.282m3.102.069a18.03 18.03 0 0 1-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 0 1 8.835 2.535M10.34 6.66a23.847 23.847 0 0 0 8.835-2.535m0 0A23.74 23.74 0 0 0 18.795 3m.38 1.125a23.91 23.91 0 0 1 1.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 0 0 1.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 0 1 0 3.46"
                            />
                          </svg>
                          <span className="flex-1 ms-3 whitespace-nowrap">
                            Events
                          </span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="/speaker/event-creation"
                          className="flex items-center p-4 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-[#3f3f3f] group"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z"
                            />
                          </svg>
                          <span className="flex-1 ms-3 whitespace-nowrap">
                            Sessions
                          </span>
                        </a>
                      </li>
                    </div>
                  )}
                  <li
                    onClick={() => {
                      handleSignout();
                    }}
                  >
                    <a className="flex cursor-pointer items-center p-4 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-[#3f3f3f] group">
                      <svg
                        className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 18 16"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3"
                        />
                      </svg>
                      <span className="flex-1 ms-3 whitespace-nowrap">
                        Sign out
                      </span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          )}
        </div>
        <div className="column" style={{ width: showSidebar ? "85%" : "100%" }}>
          <div className={`row`}>
            <DashboardHeader onCloseSidebar={toggleSidebar} />
            <Routes>
              <Route path="dashboard/:eventId" element={<Dashboard />} />
              <Route path="edit-event/:eventId" element={<EditEvent />} />
              <Route path=":eventId/*" element={<Dashboard />} />
              <Route path="session-list/:eventId" element={<SessionList />} />
              <Route path="agenda-builder/:eventId" element={<Dnd />} />
              <Route
                path="nominate-as-organizer/:eventId"
                element={<NominateAsOrganizer />}
              />
              <Route
                path="session-list-details/:sessionId"
                element={<DetailSession />}
              />
              <Route path="speaker-list/:eventId" element={<SpeakerList />} />
              <Route
                path="speaker-list-details/:speakerId"
                element={<DetailSpeaker />}
              />
            </Routes>
          </div>
        </div>
        {loading && (
          <div className="absolute inset-0 bg-black bg-opacity-80 z-10">
            <div
              role="status"
              className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
            >
              <HashLoader color={"white"} loading={loading} size={50} />
            </div>
          </div>
        )}
      </div>
      <div style={{ zIndex: 2000 }}>
        <ConfirmationDialog
          open={dialog.open}
          title={dialog.title}
          description={dialog.description}
          onClose={closeDialog}
          onConfirm={dialog.onConfirm}
        />
      </div>
    </div>
  );
};

export default Organizer;
