import axios from "axios";
import { EventConfig } from "../config/EventConfig";

const axiosInstance = axios.create({
  baseURL:
    process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_VERSION,
  headers: {
    "Content-Type": "application/json",
  },
});

const EventService = {
  createEvent: async (eventDetails: any) => {
    try {
      const response = await axiosInstance.post(
        EventConfig.createEvent,
        eventDetails
      );
      return response;
    } catch (error) {
      throw error.response.data.message;
    }
  },
  getEvents: async () => {
    try {
      const response = await axiosInstance.get(EventConfig.getEvents);
      return response;
    } catch (error) {
      throw error.response.data.message;
    }
  },
  getEventbyId: async (eventId: any) => {
    try {
      const response = await axiosInstance.get(
        EventConfig.getEventById + "id=" + eventId
      );
      return response;
    } catch (error) {
      throw error.response.data.message;
    }
  },
  getEventbyUserId: async (userId: any) => {
    try {
      const response = await axiosInstance.get(
        EventConfig.getEventByUserId + "userId=" + userId
      );
      return response;
    } catch (error) {
      throw error.response.data.message;
    }
  },
  suggestSlugUrl: async (eventName: string) => {
    try {
      const response = await axiosInstance.get(
        EventConfig.suggestSlugURL + eventName
      );
      return response.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
  checkSlugUrl: async (slugURL: string) => {
    try {
      const response = await axiosInstance.get(
        EventConfig.slugURLCheck + slugURL
      );
      return response.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
  deleteEvent: async (eventId: any) => {
    try {
      const response = await axiosInstance.delete(
        EventConfig.eventDelete + "?id=" + eventId
      );
      return response;
    } catch (error) {
      throw error.response.data.message;
    }
  },
  updateEvent: async (eventDetails: any) => {
    try {
      const response = await axiosInstance.put(
        EventConfig.updateEvent,
        eventDetails
      );
      return response;
    } catch (error) {
      throw error.response.data.message;
    }
  },
  getEventsBySlugUrl: async (slugURL: string, userId: string) => {
    try {
      const response = await axiosInstance.get(
        EventConfig.getEventBySlugUrl + slugURL + "&userId=" + userId
      );
      return response.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },

};

export default EventService;
