import axios from "axios";
import { UserURLConfig } from "../config/UserConfig";

const axiosInstance = axios.create({
  baseURL:
    process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_VERSION,
  headers: {
    "Content-Type": "application/json",
  },
});

const UserService = {
  userRegister: async (userDetails: any) => {
    try {
      console.log(userDetails);
      const response = await axiosInstance.post(
        UserURLConfig.registerUser,
        userDetails
      );
      if (response.status === 200) {
        return response.data;
      }
      return false;
    } catch (error) {
      throw error.response.data.message;
    }
  },

  updateUser: async (userDetails: any) => {
    try {
      const response = await axiosInstance.post(
        UserURLConfig.updateUser,
        userDetails
      );
      if (response.status === 200) {
        return response.data;
      }
      return false;
    } catch (error) {
      throw error.response.data.message;
    }
  },

  createAuthUser: async (userDetails: any, providerId: number) => {
    try {
      console.log(userDetails);
      const response = await axiosInstance.post(
        `${UserURLConfig.createAuthUser}?providerId=${providerId}`,
        userDetails
      );
      if (response.status === 200) {
        return response.data;
      }
      return false;
    } catch (error) {
      throw error.response.data.message;
    }
  },

  login: async (email: string, password: string) => {
    try {
      const response = await axiosInstance.get(
        `${UserURLConfig.classicLogin}email=${email}&password=${password}`
      );
      if (response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      throw error.response.data.message;
    }
  },

  checkAuthUserExist: async (email: string, providerId: number) => {
    try {
      const response = await axiosInstance.get(
        `${UserURLConfig.checkAuthUserExist}email=${email}&providerId=${providerId}`
      );
      if (response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      throw error.response.data.message;
    }
  },

  getUserbyUserId: async (userId: string) => {
    try {
      const response = await axiosInstance.get(
        `${UserURLConfig.GetUserByUserId}userId=${userId}`
      );
      if (response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      throw error.response.data.message;
    }
  },
};

export default UserService;
