import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EventService from "../../../services/eventService";
import { toast } from "react-toastify";
import CFSLineChart from "./DonutChart";
import CFSColumnChart from "./ColumnChart";
import AreaChart from "./AreaChart";

const Dashboard = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState<any>();

  useEffect(() => {
    setLoading(true);
    const fetchEvents = async () => {
      try {
        const response = await EventService.getEventbyId(eventId);
        if (response.data == null) {
          setLoading(false);
          navigate("/not-found");
        } else {
          setEvent(response.data);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        toast.error("Error fetching events");
        navigate("/not-found");
      }
      setLoading(false);
    };
    fetchEvents();
  }, [eventId]);

  return (
    <div>
      <div>
        <div className="flex flex-row justify-between mt-4 p-3 bg-white">
          <div className="p-2 text-2xl font">{event && event?.eventName}</div>
          <div className="flex">
            <button
              className="flex flex-row items-center justify-between py-2.5 px-5 me-2 mb-2 text-sm font-medium focus:outline-none bg-white border  hover:bg-gray-100 hover:text-[#625ffb] focus:z-10 dark:text-[#625ffb] dark:border-[#625ffb] dark:hover:text-white dark:hover:bg-[#625ffb]"
              type="button"
              role="tab"
              aria-selected="false"
              onClick={() =>
                navigate(`/cfs/${event?.eventCallForSpeakers?.slugURL}`)
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                />
              </svg>

              <span>CFS Page</span>
            </button>
          </div>
        </div>
        <div className="min-h-screen">
          <div className="flex m-6">
            <div className="flex justify-between gap-5 w-full">
              <div className="w-[50%]  bg-white  rounded-xl">
                <div>
                  <CFSLineChart />
                </div>
              </div>
              <div className="w-[50%] bg-white rounded-xl">
                <CFSColumnChart />
              </div>
            </div>
          </div>
          <div className="flex m-6">
            <div className="flex justify-between gap-5 w-full p-4">
              <div className="w-[50%] bg-white rounded-xl">
                <AreaChart />
              </div>
              <div className="w-[50%] bg-white rounded-xl text-sm">
                <div className=" flex-col">
                  <div className="flex flex-row justify-between p-2">
                    <div className="flex flex-row px-2 py-4">
                      <p className="font-semibold text-lg">Call for Speakers</p>
                    </div>
                  </div>
                  <hr className="h-px bg-gray-200 border-0 dark:bg-gray-400"></hr>
                  <div className="flex flex-row px-2 py-2 w-full break-words">
                    <div className="w-1/3 flex flex-col gap-4 p-2">
                      <p className="text-[#8D8D8D]">Sessions</p>
                      <p className="font-semibold text-lg text-blue-700">0</p>
                    </div>
                    <div className="w-1/3 flex flex-col gap-4 p-2">
                      <p className="text-[#8D8D8D]">Unique Speakers</p>
                      <p className="font-semibold text-lg text-blue-700">0</p>
                    </div>
                    <div className="w-1/3 flex flex-col gap-4 p-2">
                      <p className="text-[#8D8D8D]">Sessions per Speaker</p>
                      <p className="font-semibold text-lg text-blue-700">0</p>
                    </div>
                  </div>
                  <hr className="h-px bg-gray-200 border-0 dark:bg-gray-400"></hr>
                  <div className="flex flex-row px-2 py-2 w-full break-words">
                    <div className="w-1/3 flex flex-col gap-4 p-2">
                      <p className="text-[#8D8D8D]">Accepted Sessions</p>
                      <p className="font-semibold text-lg text-blue-700">0</p>
                    </div>
                    <div className="w-1/3 flex flex-col gap-4 p-2">
                      <p className="text-[#8D8D8D]">In Process sessions</p>
                      <p className="font-semibold text-lg text-blue-700">0</p>
                    </div>
                    <div className="w-1/3 flex flex-col gap-4 p-2">
                      <p className="text-[#8D8D8D]">Declined Sessions</p>
                      <p className="font-semibold text-lg text-blue-700">0</p>
                    </div>
                  </div>
                  <hr className="h-px bg-gray-200 border-0 dark:bg-gray-400"></hr>
                  <div className="flex flex-row px-2 py-2 w-full break-words">
                    <div className="w-1/3 flex flex-col gap-4 p-2">
                      <p className="text-[#8D8D8D]">Open</p>
                      <p className="font-semibold text-lg text-blue-700">0</p>
                    </div>
                    <div className="w-1/3 flex flex-col gap-4 p-2">
                      <p className="text-[#8D8D8D]">CFS Page</p>
                      <p className="font-semibold text-lg text-blue-700">0</p>
                    </div>
                    <div className="w-1/3 fDlex flex-col gap-4 p-2">
                      <p className="text-[#8D8D8D]">Speaker support Email</p>
                      <p className="font-semibold text-lg text-blue-700">
                        prasannavenkatesh9@gmail.com
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default Dashboard;
