import { useEffect, useState } from "react";
import SessionsService from "../../../services/sessionService";
import ReactApexChart from "react-apexcharts";
import { useParams } from "react-router-dom";
import dayjs from "dayjs"; 

interface ListEvents {
  data: {
    createdOn: string;
  }[];
}

const CFSColumnChart = () => {
  const [listEvents, setListEvents] = useState<ListEvents | null>(null);
  const { eventId } = useParams<{ eventId: string }>();

  useEffect(() => {
    getSessionsByEventId(eventId);
  }, [eventId]);

  const getSessionsByEventId = (eventId: string) => {
    SessionsService.getSessionByEventId(eventId)
      .then((resData) => {
        if (resData) {
          setListEvents(resData);
        }
      })
      .catch((err) => {
        console.error("Error fetching sessions:", err);
      });
  };

  const getDatesAndCounts = () => {
    if (!listEvents) return { dates: [], counts: [] };

    const today = dayjs();
    const dates = [];
    const counts = new Array(7).fill(0);

    for (let i = 0; i < 7; i++) {
      dates.push(today.subtract(i, "day").format("YYYY-MM-DD"));
    }
    dates.reverse();

    listEvents.data.forEach((event) => {
      const eventDate = dayjs(event.createdOn).format("YYYY-MM-DD");
      const index = dates.indexOf(eventDate);
      if (index !== -1) {
        counts[index]++;
      }
    });

    return { dates, counts };
  };

  const { dates, counts } = getDatesAndCounts();

  const state: {
    series: ApexAxisChartSeries;
    options: ApexCharts.ApexOptions;
  } = {
    series: [
      {
        name: "Sessions",
        data: counts,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 3,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val.toString();
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      xaxis: {
        categories: dates,
        position: "bottom",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
      title: {
        text: "New Sessions Nominated in the Last 7 Days",
        floating: true,
        offsetY: 1,
        align: "left",
        style: {
          color: "#444",
        },
      },
    },
  };

  return (
    <div className="p-4">
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default CFSColumnChart;
