import { useEffect, useState } from "react";
import SessionsService from "../../../services/sessionService";
import ReactApexChart from "react-apexcharts";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

interface ListEvents {
  data: {
    createdOn: string;
  }[];
}

const LineChart = () => {
  const [listEvents, setListEvents] = useState<ListEvents | null>(null);
  const { eventId } = useParams<{ eventId: string }>();

  useEffect(() => {
    getSessionsByEventId(eventId);
  }, [eventId]);

  const getSessionsByEventId = (eventId: string) => {
    SessionsService.getSessionByEventId(eventId)
      .then((resData) => {
        if (resData) {
          setListEvents(resData);
        }
      })
      .catch((err) => {
        console.error("Error fetching sessions:", err);
      });
  };

  const getDatesAndCounts = () => {
    if (!listEvents) return { dates: [], counts: [] };

    const today = dayjs();
    const dates = [];
    const counts = new Array(7).fill(0);

    for (let i = 0; i < 7; i++) {
      dates.push(today.subtract(i, "day").format("YYYY-MM-DD"));
    }
    dates.reverse();

    listEvents.data.forEach((event) => {
      const eventDate = dayjs(event.createdOn).format("YYYY-MM-DD");
      const index = dates.indexOf(eventDate);
      if (index !== -1) {
        counts[index]++;
      }
    });

    return { dates, counts };
  };

  const { dates, counts } = getDatesAndCounts();

  const state = {
    series: [
      {
        name: "Sessions",
        data: counts,
      },
    ],
    options: {
      chart: {
        type: "line" as "line",
        height: 450,
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight" as "straight",
      },
      title: {
        text: "Session Creation Trend Last 7 Days",
        align: "left" as "left",
      },
      xaxis: {
        type: "datetime" as "datetime",
        categories: dates,
        labels: {
          format: 'yyyy-MM-dd'
        }
      },
      yaxis: {
        opposite: true,
      },
      legend: {
        horizontalAlign: "left" as "left",
      },
    },
  };

  return (
    <div className="p-4">
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="line"
        height={350}
      />
    </div>
  );
};

export default LineChart;
