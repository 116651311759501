import React, { useCallback, useEffect, useState } from "react";
import "tailwindcss/tailwind.css";
import { Calendar as BigCalendar, stringOrDate } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { EventItem } from "./DndHelper"; // Update as per your EventItem structure
import AppointmentEvent from "./Appointment";
import EventItemComponent from "./EventItemComponent";
import SessionsService from "../../../services/sessionService";
import moment from "moment"; // Import moment for date handling
import { props } from "./Props";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

const DndList = () => {
  const [events, setEvents] = useState<EventItem[]>([]);
  const [appointments, setAppointments] = useState<any[]>([]);
  const [listEvents, setListEvents] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [draggedEvent, setDraggedEvent] = useState<any>();
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const { eventId } = useParams<{ eventId: string }>();

  useEffect(() => {
    getSessionsByEventId(eventId);
  }, [eventId]);

  const getSessionsByEventId = (eventId: string) => {
    SessionsService.getSessionByEventId(eventId)
      .then((resData) => {
        if (resData && resData.data) {
          setListEvents(resData.data);

          // Process the fetched data and update events and appointments
          const updatedEvents: EventItem[] = [];
          const updatedAppointments: any[] = [];

          resData.data.forEach((session) => {
            if (session.startsAt && session.endsAt) {
              // Convert startsAt and endsAt to Date objects using moment
              const start = moment(session.startsAt).toDate();
              const end = moment(session.endsAt).toDate();

              // Determine resourceId dynamically based on session data (example)
              const resourceId = parseInt(session.room, 10) || 1; // Default to 1 if room is not a number

              // Create new event object
              const newEvent: EventItem = {
                id: session.id,
                start,
                end,
                data: {
                  appointment: {
                    id: session.id,
                    status: session.sessionStatus === 0 ? "P" : "CI",
                    location: session.room || "",
                    resource: session.title,
                    address: session.room ? `Room: ${session.room}` : "",
                    description: session.description,
                    sessionType: 1,
                  },
                },
                isDraggable: true,
                isResizable: true,
                resourceId,
                room: session.room, // Save room as room number
              };

              updatedEvents.push(newEvent);
            } else {
              // If startsAt or endsAt is empty, add to appointments
              updatedAppointments.push({
                id: session.id,
                status: session.sessionStatus === 0 ? "P" : "CI",
                location: session.room || "",
                resource: session.title,
                address: session.room ? `Room: ${session.room}` : "",
              });
            }
          });

          // Update state with new events and appointments
          setEvents(updatedEvents);
          setAppointments(updatedAppointments);
        }
      })
      .catch((err) => {
        console.error("Error fetching sessions:", err);
      });
  };

  const onUpdateSession = async (data: any) => {
    try {
      const sessionDetails = {
        id: data.id,
        startsAt: moment(data.start).format("YYYY-MM-DDTHH:mm"),
        endsAt: moment(data.end).format("YYYY-MM-DDTHH:mm"),
        room: data.room, // Use room number as resourceId
        title: data.resource,
        description: data.description,
        sessionType: 1,
      };
      setLoading(true);
      const sessionUpdated = await SessionsService.sessionTimeUpdate(
        sessionDetails
      );
      if (sessionUpdated) {
        setLoading(false);
        toast.success("Session Updated Successfully", { autoClose: 2000 });
        getSessionsByEventId("d0aa0c7a-91f6-495b-a4b4-f3bdd5cac7d1");
      } else {
        setLoading(false);
        toast.error("Something went wrong!", { autoClose: 10000 });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating session:", error);
    }
  };

  const onChangeEventTime = useCallback(
    ({
      event,
      start,
      end,
      resourceId,
    }: {
      event: EventItem;
      start: stringOrDate;
      end: stringOrDate;
      resourceId: number;
    }) => {
      setEvents((prevEvents) =>
        prevEvents.map((prevEvent) =>
          prevEvent?.data?.appointment?.id === event?.data?.appointment?.id
            ? {
                ...event,
                start,
                end,
                resourceId,
                room: resourceId.toString(), // Update room with resourceId
              }
            : prevEvent
        )
      );

      const updatedEvent = {
        ...event,
        start,
        end,
        resourceId,
        room: resourceId.toString(), // Update room with resourceId
        id: event.id,
        location: event.data.appointment.location,
        resource: event.data.appointment.resource,
        description: event.data.appointment.description,
        sessionType: event.data.appointment.sessionType,
      };

      onUpdateSession(updatedEvent);
    },
    []
  );

  const onDroppedFromOutside = useCallback(
    async ({ start, end, resource }) => {
      if (!draggedEvent || draggedEvent === "undroppable") return;

      console.log(resource, "resource");
      const newEvent = {
        id: draggedEvent.id,
        start,
        end,
        room: resource ? resource.toString() : "",
        data: { appointment: draggedEvent },
        isDraggable: true,
        isResizable: true,
        resourceId: resource,
      };

      setEvents((prevEvents) => [...prevEvents, newEvent]);

      // Update session time via API call
      const updatedEvent = {
        id: draggedEvent.id,
        start,
        end,
        resourceId: resource || 1, // Default to 1 if resourceId is undefined
        location: draggedEvent.location,
        resource: draggedEvent.resource,
        description: draggedEvent.description,
        sessionType: 1,
        room: resource ? resource.toString() : "",
      };

      await onUpdateSession(updatedEvent);
      handleDrop();
    },
    [draggedEvent]
  );

  const handleDragStart = (event: EventItem) => {
    setDraggedEvent(event?.data?.appointment);
    setIsDragging(true);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    setDraggedEvent(undefined);
  };

  const handleDropOnAppointmentArea = (
    e: React.DragEvent<HTMLUListElement>
  ) => {
    e.preventDefault();
    if (isDragging && draggedEvent !== "undroppable") {
      setEvents((prevEvents) =>
        prevEvents.filter(
          (event) => event.data.appointment.id !== draggedEvent.id
        )
      );
      setAppointments((prevAppointments) => [
        ...prevAppointments,
        draggedEvent,
      ]);
    }
    setIsDragging(false);
    setDraggedEvent(undefined);
  };

  const handleDrop = () => {
    setAppointments((prevAppointments) =>
      prevAppointments.filter((appointment) => appointment !== draggedEvent)
    );
    setDraggedEvent(null);
  };

  const handleDeleteEvent = async (event: EventItem) => {
    try {
      // Update session with null dates via API call
      const nullDates = {
        id: event.id,
        startsAt: null,
        endsAt: null,
        room: event.data.appointment.location,
        title: event.data.appointment.resource,
        description: event.data.appointment.description,
        sessionType: event.data.appointment.sessionType,
      };

      setLoading(true);
      const sessionDeleted = await SessionsService.sessionTimeUpdate(nullDates);
      if (sessionDeleted) {
        setLoading(false);
        toast.success("Session Deleted Successfully", { autoClose: 2000 });

        // Create appointment object with null dates
        const appointment = {
          ...event.data.appointment,
          startsAt: null,
          endsAt: null,
        };

        // Move the deleted event to appointments list
        setAppointments((prevAppointments) => [
          ...prevAppointments,
          appointment,
        ]);

        // Remove the event from events list
        setEvents((prevEvents) =>
          prevEvents.filter((prevEvent) => prevEvent !== event)
        );
      } else {
        setLoading(false);
        toast.error("Something went wrong!", { autoClose: 10000 });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error deleting session:", error);
      toast.error("Error deleting session. Please try again later.");
    }
  };

  const resources = [
    { id: 1, title: "Track 1" },
    { id: 2, title: "Track 2" },
    { id: 3, title: "Track 3" },
    { id: 4, title: "Track 4" },
    { id: 5, title: "Track 5" },
  ];

  const DnDCalendar = withDragAndDrop<EventItem>(BigCalendar);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 space-y-4 w-full">
      <div className="flex p-2 gap-4 h-full w-full flex-row">
        <div className="p-4 bg-white w-[20%] overflow-y-scroll">
          <h2 className="text-lg font-semibold mb-4">Session List</h2>
          <ul onDrop={handleDropOnAppointmentArea} className="text-left">
            {appointments.map((appointment, index) => (
              <li
                key={index}
                className="p-2 mb-2 border rounded shadow text-left"
                onDragStart={() => setDraggedEvent(appointment)}
                draggable
              >
                <AppointmentEvent appointment={appointment} />
              </li>
            ))}
          </ul>
        </div>

        <div className="flex-1 overflow-auto w-full">
          <DnDCalendar
            events={events}
            {...props}
            resources={resources}
            onEventDrop={onChangeEventTime}
            onEventResize={onChangeEventTime}
            onDropFromOutside={onDroppedFromOutside}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            draggableAccessor={(event) => event.isDraggable}
            resizableAccessor={(event) => event.isResizable}
            components={{
              event: ({ event }) => (
                <EventItemComponent
                  event={event}
                  onDelete={handleDeleteEvent}
                />
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DndList;
