import { UUIDGenerator } from "../../../helper/Guid";

export const validateForm = (user, errors) => {
  console.log("Validating");
  const updatedErrors = { ...errors };

  if (
    user?.firstName === null ||
    user?.firstName === undefined ||
    user?.firstName === ""
  ) {
    updatedErrors["firstName"] = "Field is required";
  } else {
    delete updatedErrors["firstName"];
  }

  if (
    user?.lastName === null ||
    user?.lastName === undefined ||
    user?.lastName === ""
  ) {
    updatedErrors["lastName"] = "Field is required";
  } else {
    delete updatedErrors["lastName"];
  }

  if (user?.userProfile?.designation === null || user?.userProfile?.designation === undefined || user?.userProfile?.designation === '' ) {
    updatedErrors["userProfile.designation"] = "Field is required";
  } else {
    delete updatedErrors["userProfile.designation"];
  }

  if (user?.userProfile?.tagLine===null || user?.userProfile?.tagLine===undefined || user?.userProfile?.tagLine==='') {
    updatedErrors["userProfile.tagLine"] = "Field is required";
  } else {
    delete updatedErrors["userProfile.tagLine"];
  }

  if (user?.userProfile?.biography===null || user?.userProfile?.biography===undefined || user?.userProfile?.biography==='') {
    updatedErrors["userProfile.biography"] = "Field is required";
  } else {
    delete updatedErrors["userProfile.biography"];
  }

  if (user?.userProfile?.photoUrl===null || user?.userProfile?.photoUrl===undefined || user?.userProfile?.photoUrl==='') {
    updatedErrors["userProfile.photoUrl"] = "Field is required";
  } else {
    delete updatedErrors["userProfile.photoUrl"];
  }

  console.log("Updated Errors: ", updatedErrors);
  return updatedErrors;
};

export const converUserModel = (currentUser, data) => {
  const makeUserDetails: any = {
    id: currentUser.id,
    firstName: data.firstName,
    lastName: data.lastName,
    isProfileComplete: true,
    email: data.email,
    password: currentUser.password, 
    createdBy: currentUser.createdBy ?? currentUser.id,
    createdOn: currentUser.createdOn ?? new Date().toISOString(),
    modifiedBy: currentUser.id,
    modifiedOn: new Date().toISOString(),
    isActive: true,
    roleId: currentUser.roleId,
    userProfile: {
      id: currentUser?.userProfile?.id ?? UUIDGenerator.generateUUID(),
      userId: currentUser.id,
      designation: data?.userProfile?.designation,
      photoUrl: data?.userProfile?.photoUrl,
      tagLine: data?.userProfile?.tagLine,
      biography: data?.userProfile?.biography,
      areaofExpertise: data?.userProfile?.areaofExpertise,
      topics: data?.userProfile?.topics,
      location: data?.userProfile?.location,
      isShowBio: true,
      isPublicProfile: true,
      createdBy: currentUser?.userProfile?.createdBy ??currentUser.id,
      createdOn: currentUser?.userProfile?.createdOn ?? new Date().toISOString(),
      modifiedBy: currentUser.id,
      modifiedOn: new Date().toISOString(),
    },
    userSocialMedia: {
      id: currentUser?.userSocialMedia?.id ?? UUIDGenerator.generateUUID(),
      userId: currentUser.id,
      twitter: data?.userSocialMedia?.twitter,
      facebook: data?.userSocialMedia?.facebook,
      linkedIn: data?.userSocialMedia?.linkedIn,
      instagram: data?.userSocialMedia?.instagram,
      blog: data?.userSocialMedia?.blog,
      companyWebsite: data?.userSocialMedia?.companyWebsite,
      createdBy: currentUser?.userSocialMedia?.createdBy ?? currentUser.id,
      createdOn:
        currentUser?.userSocialMedia?.createdOn??  new Date().toISOString(),
      modifiedBy: currentUser.id,
      modifiedOn: new Date().toISOString(),
    },
  };
  return makeUserDetails;
};
