import React, { FC } from "react";
import "./NotFound.scss";
import Footer from "../Website/Footer/Footer";
import Header from "../Website/Header/Header";

interface NotFoundProps {}

const NotFound: FC<NotFoundProps> = () => (
  <div className="NotFound font-open_sans mt-20" data-testid="NotFound">
    <Header bgColor={"bg-[#000]"} textColor={"text-white"} />

    <div className="my-6 flex flex-col justify-center items-center">
      <div className=" text-6xl md:text-9xl font-bold my-5">
        4<span className="text-[#FFB545]">0</span>4
      </div>
      <div className="text-3xl md:text-5xl font-bold my-5 text-center">Page Not Found</div>

      <div className="mt-5">This Page Doesn't or was removed! We </div>
      <div className="mb-5">suggest you back to home </div>

      <button className="bg-[#303030] text-xs md:text-base cursor-pointer font-semibold p-2 md:p-4 rounded-full text-white">
        BACK TO HOME
      </button>
    </div>
    <Footer />
  </div>
);

export default NotFound;
